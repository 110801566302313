import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';

// Material Kit 2 React components
import MKBox from 'components/box';
import MKTypography from 'components/typography';
//import MKSocialButton from 'components/socialButton';

// Material Kit 2 React examples
import DefaultNavbar from 'examples/Navbars/DefaultNavbar';
import DefaultFooter from 'examples/Footers/DefaultFooter';

// Routes
import routes from 'routes';
import footerRoutes from 'footer.routes';

// Images
import landingPageGif from 'assets/images/presentation/data_science.gif';
import ServiceBand from '../LandingPages/Services/serviceBand/ServiceBand';
import Partners from './sections/Partners';
import TargetTechnologies from './sections/TargetTechnologies';
import Clients from './sections/Clients';
import AugmentedProficiencies from './sections/AugmentedProficiencies';
import Certifications from './sections/Certifications';
import websiteNameByBrand from '../../utils/websiteNameByBrand';
import MKSocialButton from "../../components/socialButton";

function Presentation() {
    const brand = websiteNameByBrand();

  return (
    <>
      <DefaultNavbar
        routes={routes.map((r) => r.type === 'nav' && r)}
        action={{
          type: 'internal',
          route: '/pages/authentication/sign-in',
          label: 'Sign IN',
          color: 'info',
        }}
        transparent
        light
      />
      <MKBox
        minHeight="100vh"
        width="100%"
        sx={{
          backgroundImage: `url(${landingPageGif})`,
          backgroundSize: 'cover',
          backgroundPosition: 'top',
          display: 'grid',
          placeItems: 'center',
        }}
      >
        <Container>
          <Grid item xs={12} lg={7} justifyContent="center" mx="auto">
            <MKTypography
              variant="h1"
              color="white"
              textAlign="center"
              mt={-6}
              mb={6}
              sx={brand.styling}
            >
                {brand.name}

            </MKTypography>
            <MKTypography
              variant="h4"
              color="white"
              textAlign="center"
              mt={-6}
              mb={5}
              sx={({ breakpoints }) => ({
                  fontSize: '1.5rem',
                  maxWidth: '100%',
                  [breakpoints.down('md')]: {
                      fontSize: '1.25rem',
                      maxWidth: '100%',
                  },
                  [breakpoints.down('sm')]: {
                      fontSize: '0.85rem',
                      maxWidth: '100%',
                  },
              })}
            >
                {brand.tagline}

            </MKTypography>
          </Grid>
        </Container>
      </MKBox>
      <Card
        sx={{
          p: 2,
          mx: { xs: 2, lg: 3 },
          mt: -8,
          mb: 4,
          backgroundColor: ({ palette: { white }, functions: { rgba } }) => rgba(white.main, 0.8),
          backdropFilter: 'saturate(200%) blur(30px)',
          boxShadow: ({ boxShadows: { xxl } }) => xxl,
        }}
      >
        <ServiceBand displayType="page" />
        <Certifications />
        <TargetTechnologies />
        <AugmentedProficiencies />
        <Clients />
        <Partners />
        <MKBox pt={18} pb={6}>
          <Container>
            <Grid container spacing={3}>
              <Grid item xs={12} lg={5} ml="auto" sx={{ textAlign: { xs: 'center', lg: 'left' } }}>
                <MKTypography variant="h4" fontWeight="bold" mb={0.5}>
                  Thank you for your support!
                </MKTypography>
                <MKTypography variant="body1" color="text">
                  We deliver the best web products
                </MKTypography>
              </Grid>
              <Grid
                item
                xs={12}
                lg={5}
                my={{ xs: 5, lg: 'auto' }}
                mr={{ xs: 0, lg: 'auto' }}
                sx={{ textAlign: { xs: 'center', lg: 'right' } }}
              >
                <MKSocialButton
                  component="a"
                  href="https://www.instagram.com/id_maps/?hl=en"
                  target="_blank"
                  color="instagram"
                  sx={{ mr: 1 }}
                >
                  <i className="fab fa-instagram" />
                  &nbsp;Instagram
                </MKSocialButton>
                <MKSocialButton
                  component="a"
                  href="https://www.facebook.com/pages/category/Information-Technology-Company/Idmaps-102405328308455/"
                  target="_blank"
                  color="facebook"
                  sx={{ mr: 1 }}
                >
                  <i className="fab fa-facebook" />
                  &nbsp;Facebook
                </MKSocialButton>
                <MKSocialButton
                  component="a"
                  href="https://www.linkedin.com/company/idmaps-intl/?viewAsMember=true"
                  target="_blank"
                  color="linkedin"
                >
                  <i className="fab fa-linkedin" />
                  &nbsp;Linkedin
                </MKSocialButton>
              </Grid>
            </Grid>
          </Container>
        </MKBox>
      </Card>
      <MKBox pt={6} px={1} mt={6}>
        <DefaultFooter content={footerRoutes} />
      </MKBox>
    </>
  );
}

export default Presentation;
