/*
=========================================================
* Material Kit 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';

// Material Kit 2 React components
import MKTypography from '../../../components/typography';
import MKBox from '../../../components/box';

//Image
import top_technologies_1 from '../../../assets/images/software-development/top_technologies_1.png';
import top_technologies_2 from '../../../assets/images/software-development/top_technologies_2.png';
import top_technologies_3 from '../../../assets/images/software-development/top_technologies_3.png';
import top_technologies_4 from '../../../assets/images/software-development/top_technologies_4.png';
import software_development_5 from '../../../assets/images/software-development/software_development_5.png';
import software_development_7 from '../../../assets/images/software-development/software_development_7.png';
import software_development_14 from '../../../assets/images/software-development/software_development_14.png';
import software_development_19 from '../../../assets/images/software-development/software_development_19.png';
import software_development_20 from '../../../assets/images/software-development/software_development_20.png';
import software_development_21 from '../../../assets/images/software-development/software_development_21.jpg';
import software_development_22 from '../../../assets/images/software-development/software_development_22.png';
import software_development_23 from '../../../assets/images/software-development/software_development_23.png';
import software_development_24 from '../../../assets/images/software-development/software_development_24.png';
import software_development_25 from '../../../assets/images/software-development/software_development_25.png';
import software_development_26 from '../../../assets/images/software-development/software_development_26.png';
import software_development_27 from '../../../assets/images/software-development/software_development_27.png';

// CSS
import './TargetTechnologies.css';
function TargetTechnologies() {
  return (
    <MKBox component="section" pt={5}>
      <Container>
        <Grid
          container
          item
          xs={12}
          lg={8}
          justifyContent="center"
          alignItems="center"
          flexDirection="column"
          sx={{ mx: 'auto', textAlign: 'center', pt: '100px', pb: '50px' }}
        >
          <MKTypography
            variant="h1"
              //color="#344667"
            color="dark"
            sx={({ breakpoints, typography: { size } }) => ({
              [breakpoints.down('md')]: {
                fontSize: size['3xl'],
              },
            })}
          >
            Top Technologies We Target
          </MKTypography>
          <MKTypography variant="body1"
              //color="#344667"
                        color="dark" opacity={0.8} mt={1} mb={1}>
            We specialize in delivering cutting-edge solutions powered by top technologies like
            Python, React, Java, and more
          </MKTypography>
        </Grid>
        <Grid container spacing={5} alignItems="center">
          <Grid item xs={12} lg={12}>
            <Grid container justifyContent="flex-start">
              <Grid item xs={12} md={12}>
                <MKBox>
                  <div className="container-target-tech">
                    <a
                      className="box-target-tech"
                      href="https://www.ibm.com/topics/data-modeling"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <div>
                        <img src={software_development_27} alt="Data Modelling" />
                        <p className="box-text-target-tech">Azure</p>
                      </div>
                    </a>
                    <a
                      className="box-target-tech"
                      href="https://cassandra.apache.org/_/index.html"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <div>
                        <img src={software_development_23} alt="Cassandra" />
                        <p className="box-text-target-tech">Cassandra</p>
                      </div>
                    </a>
                    <a
                      className="box-target-tech"
                      href="https://clouddb.solutions/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <div>
                        <img src={software_development_26} alt="CloudDB" />
                        <p className="box-text-target-tech">Cloud Database</p>
                      </div>
                    </a>
                    <a
                      className="box-target-tech"
                      href="https://www.ibm.com/products/informix"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <div>
                        <img src={software_development_24} alt="Informix" />
                        <p className="box-text-target-tech">Informix</p>
                      </div>
                    </a>
                    <a
                      className="box-target-tech"
                      href="https://www.java.com/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <div>
                        <img src={software_development_7} alt="Java" />
                        <p className="box-text-target-tech">Java</p>
                      </div>
                    </a>
                    <a
                      className="box-target-tech"
                      href="https://www.javascript.com/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <div>
                        <img src={top_technologies_4} alt="Javascript" />
                        <p className="box-text-target-tech">JavaScript</p>
                      </div>
                    </a>
                    <a
                      className="box-target-tech"
                      href="https://www.microsoft.com/en-us/sql-server"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <div>
                        <img src={software_development_22} alt="MicrosoftSQLServer" />
                        <p className="box-text-target-tech">Microsoft SQL Server</p>
                      </div>
                    </a>
                    <a
                      className="box-target-tech"
                      href="https://www.mongodb.com/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <div>
                        <img src={software_development_21} alt="MongoDB" />
                        <p className="box-text-target-tech">MongoDB</p>
                      </div>
                    </a>
                    <a
                      className="box-target-tech"
                      href="https://www.mysql.com/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <div>
                        <img src={software_development_19} alt="MySQL" />
                        <p className="box-text-target-tech">MySQL</p>
                      </div>
                    </a>
                    <a
                      className="box-target-tech"
                      href="https://nodejs.org/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <div>
                        <img src={top_technologies_2} alt="NodeJs" />
                        <p className="box-text-target-tech">NodeJS</p>
                      </div>
                    </a>
                    <a
                      className="box-target-tech"
                      href="https://www.oracle.com/database/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <div>
                        <img src={software_development_25} alt="Oracle" />
                        <p className="box-text-target-tech">Oracle</p>
                      </div>
                    </a>
                    <a
                      className="box-target-tech"
                      href="https://www.php.net/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <div>
                        <img src={top_technologies_3} alt="Php" />
                        <p className="box-text-target-tech">PHP</p>
                      </div>
                    </a>
                    <a
                      className="box-target-tech"
                      href="https://www.postgresql.org/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <div>
                        <img src={software_development_20} alt="Postgres" />
                        <p className="box-text-target-tech">Postgres</p>
                      </div>
                    </a>
                    <a
                      className="box-target-tech"
                      href="https://www.python.org/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <div>
                        <img src={top_technologies_1} alt="Python" />
                        <p className="box-text-target-tech">Python</p>
                      </div>
                    </a>
                    <a
                      className="box-target-tech"
                      href="https://react.dev/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <div>
                        <img src={software_development_5} alt="React" />
                        <p className="box-text-target-tech">React & React Js</p>
                      </div>
                    </a>
                    <a
                      className="box-target-tech"
                      href="https://wordpress.com/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <div>
                        <img src={software_development_14} alt="WordPress" />
                        <p className="box-text-target-tech">WordPress</p>
                      </div>
                    </a>
                  </div>
                </MKBox>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default TargetTechnologies;
