/*
=========================================================
* Material Kit 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import React from 'react';

// @mui material components
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';

// Material Kit 2 React components
import MKBox from 'components/box';

// Material Kit 2 React examples
import DefaultInfoCard from 'examples/Cards/InfoCards/DefaultInfoCard';
import CenteredBlogCard from 'examples/Cards/BlogCards/CenteredBlogCard';

// Image
import services_jamaica_1 from '../../../../assets/images/services-page/services_jamaica_1.jpeg';
// import services_canada_1 from '../../../../assets/images/services-page/services_canada_1.jpeg';

// eslint-disable-next-line react/prop-types
function TrainingBand({ disType }) {
  const dataPage = (
    <MKBox component="section" py={12}>
      <Container>
        <Grid container spacing={3} alignItems="center">
          <Grid item xs={12} lg={6} sx={{ mr: 'auto', mt: { xs: 3, lg: 0 } }}>
            <CenteredBlogCard
              image={services_jamaica_1}
              title="Training Service"
              description="We empower your teams with skills to manage data assets, systems, and perform analytics using cutting-edge technologies. Our comprehensive training ensures they understand customized data systems, and we provide resources for certifications."
              action={{
                type: 'internal',
                route: '/pages/service/training',
                color: 'info',
                label: 'find out more',
              }}
            />
          </Grid>
          <Grid item xs={12} lg={5}>
            <Grid container justifyContent="flex-start">
              <Grid item xs={12} md={12}>
                <MKBox mb={5}>
                  <DefaultInfoCard
                    type=""
                    icon="public"
                    title="Training"
                    description="Training services encompass a range of initiatives designed to enhance the capabilities of individuals or teams within an organization. These programs are meticulously crafted to address specific learning needs and align with organizational goals. By targeting skill gaps, training services promote continuous professional development and equip participants with the knowledge and competencies required to excel in their roles. Through interactive and engaging learning experiences, participants gain practical skills, expand their expertise, and enhance their overall performance, contributing to the growth and success of the organization."
                  />
                </MKBox>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
  const servicePageInfo = (
    <MKBox component="section" pt={6}>
      <Container>
        <Grid container spacing={3} alignItems="center">
          <Grid item>
            <Grid container justifyContent="flex-start">
              <Grid item xs={12} md={12}>
                <MKBox>
                  <CenteredBlogCard
                    image={services_jamaica_1}
                    title="Training"
                    description=""
                    // We empower your teams with skills to manage data assets, systems, and perform analytics using cutting-edge technologies. Our comprehensive training ensures they understand customized data systems, and we provide resources for certifications."
                    action={{
                      type: 'internal',
                      route: '/pages/service/training',
                      color: 'info',
                      label: 'find out more',
                    }}
                  />
                </MKBox>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );

  return <div>{disType !== '' && disType === 'band' ? servicePageInfo : dataPage}</div>;
}

export default TrainingBand;
