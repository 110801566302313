/*
=========================================================
* Material Kit 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';

// Material Kit 2 React components
import MKBox from 'components/box';

//Images
import health_industry_5 from '../../../../../assets/images/industry/health_industry_5.jpeg';
import health_industry_6 from '../../../../../assets/images/industry/health_industry_6.jpeg';
import portfolio_details_4 from '../../../../../assets/images/software-development/portfolio_details_4.png';
import portfolio_details_3 from '../../../../../assets/images/software-development/portfolio_details_3.png';
import portfolio_details_1 from '../../../../../assets/images/software-development/portfolio_details_1.png';
import portfolio_details_5 from '../../../../../assets/images/software-development/portfolio_details_2.png';

// Css
import './ConnectingPatientsandHealthcareProvidersCaseStudyDetails.css';
import DefaultInfoCard from '../../../../../examples/Cards/InfoCards/DefaultInfoCard';

function ConnectingPatientsandHealthcareProvidersCaseStudyDetails() {
    return (
        <>
            <MKBox component="section" pt={12} pb={5}>
                <Container>
                    <Grid container spacing={5} alignItems="center">
                        <Grid item xs={12} lg={5} sx={{ml: '', mt: {xs: 3, lg: 0}}}>
                            <img src={health_industry_5} className="image-portfolio-details"/>
                        </Grid>
                        <Grid item xs={12} lg={7}>
                            <Grid container justifyContent="flex-start">
                                <Grid item xs={12} md={12}>
                                    <MKBox>
                                        <DefaultInfoCard
                                            type="text"
                                            title="Connecting Patients and Healthcare Providers"
                                            description="In this use case, we delve into an innovative application that not only enhances access to quality healthcare services but also streamlines the entire healthcare experience. By leveraging advanced technology and intuitive features, the application empowers patients to easily find and book appointments with healthcare providers, access their medical records, and even engage in remote consultations when needed. Ultimately, this transformative application aims to bridge the gap between patients and healthcare providers, ensuring timely and efficient healthcare delivery."
                                        />
                                        <div className="container">
                                            <div className="box-portfolio-details">
                                                <img src={portfolio_details_4} alt="Country"/>
                                                <p>
                                                    <b>Country:</b> Jamaica
                                                </p>
                                            </div>
                                            <div className="box-portfolio-details">
                                                <img src={portfolio_details_3} alt="platform"/>
                                                <p>
                                                    <b>Platform:</b> iOS , Web
                                                </p>
                                            </div>
                                            <div className="box-portfolio-details">
                                                <img src={portfolio_details_1} alt="industry"/>
                                                <p>
                                                    <b>Industry:</b> Finance
                                                </p>
                                            </div>
                                            <div className="box-portfolio-details">
                                                <img src={portfolio_details_5} alt="duration"/>
                                                <p>
                                                    <b>Duration:</b> 2019-Present
                                                </p>
                                            </div>
                                        </div>
                                    </MKBox>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Container>
            </MKBox>
            <MKBox component="section" pb={5} style={{background: '#f0f2f5'}}>
                <Container>
                    <Grid container spacing={5} alignItems="center">
                        <Grid item xs={12} lg={12} sx={{ml: '', mt: {xs: 3, lg: 10}, mb: {xs: 3, lg: 5}}}>
                            <DefaultInfoCard
                                type="text" title="Lorem" description="Lorem ipsom"/>
                        </Grid>
                    </Grid>

                    <Grid container spacing={5} alignItems="center">
                        <Grid item xs={12} lg={3} md={6} sx={{ml: '', mb: {xs: 0, lg: 3}}}>
                            <div className="card-portfolio-details">
                                {/*<h2 className="card-portfolio-details-header">Team</h2>*/}
                                {/*<p className="card-detail">Backend Developer</p>*/}
                                {/*<p className="card-detail">Database Administrator</p>*/}
                                {/*<p className="card-detail">Frontend Developer</p>*/}
                                {/*<p className="card-detail">Quality Assurance Engineer</p>*/}
                                {/*<p className="card-detail">Project Manager</p>*/}
                                {/*<p className="card-detail">UI/UX Designer</p>*/}
                            </div>
                        </Grid>
                        <Grid item xs={12} lg={3} md={6} sx={{ml: '', mb: {xs: 0, lg: 3}}}>
                            <div className="card-portfolio-details">
                                {/*<h2 className="card-portfolio-details-header">Tech Stack:</h2>*/}
                                {/*<p className="card-detail">Angular</p>*/}
                                {/*<p className="card-detail">Java (Spring Boot)</p>*/}
                                {/*<p className="card-detail"> PostgreSQL</p>*/}
                            </div>
                        </Grid>
                        <Grid item xs={12} lg={3} md={6} sx={{ml: '', mb: {xs: 0, lg: 3}}}>
                            <div className="card-portfolio-details">
                                {/*<h2 className="card-portfolio-details-header">Target Audience</h2>*/}
                                {/*<p className="card-detail">Existing Patients</p>*/}
                                {/*<p className="card-detail">Potential Patients</p>*/}
                            </div>
                        </Grid>
                        <Grid item xs={12} lg={3} md={6} sx={{ml: '', mb: {xs: 0, lg: 3}}}>
                            <div className="card-portfolio-details">
                                {/*<h2 className="card-portfolio-details-header">Services</h2>*/}
                                {/*<p className="card-detail">Appointment Booking</p>*/}
                                {/*<p className="card-detail">Electronic Medical Records (EMR)</p>*/}
                                {/*<p className="card-detail">Telehealth Consultations</p>*/}
                                {/*<p className="card-detail">Prescription Refill Requests</p>*/}
                                {/*<p className="card-detail">Reminders and Notifications</p>*/}
                            </div>
                        </Grid>
                    </Grid>
                </Container>
            </MKBox>
            <MKBox component="section" pb={5}>
                <Container>
                    <Grid container spacing={5} alignItems="center">
                        <Grid item xs={12} lg={5} sx={{ml: '', mt: {xs: 10, lg: 10}}}>
                            <img src={health_industry_6} className="image-portfolio-details"/>
                        </Grid>
                        <Grid item xs={12} lg={7}>
                            <Grid container justifyContent="flex-start">
                                <Grid item xs={12} md={12}>
                                    <MKBox>
                                        <DefaultInfoCard
                                            type="text"
                                            title="Opportunities"
                                            description="A healthcare technology startup, aimed to develop an application that would bridge the gap between patients and healthcare providers, improving access to quality healthcare services. They wanted to create a user-friendly platform that allowed patients to easily find and book appointments with healthcare professionals, access their medical records, and receive personalized health information. The application needed to be secure, scalable, and capable of handling a large user base."
                                        />
                                    </MKBox>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Container>
            </MKBox>
            <MKBox component="section" pb={5} style={{background: '#f0f2f5'}}>
                <Container>
                    <Grid container spacing={5} alignItems="center">
                        <Grid item xs={12} lg={12} sx={{ml: '', mt: {xs: 3, lg: 10}}}>
                            <DefaultInfoCard
                                type="text"
                                title="Our Solution"
                                description="By partnering with us, together we developed a robust healthcare application, HealthConnect. Our team conducted extensive research and collaborated closely with the client to understand their vision and requirements."
                            />
                        </Grid>
                    </Grid>
                    <Grid container spacing={5} alignItems="center">
                        <Grid item xs={12} lg={12} md={12} sx={{ml: '', mb: {xs: 0, lg: 3}}}>
                            <h1 className="portfolio-details-text-header">
                                The key outcomes of the project included
                            </h1>
                            <ul className="portfolio-details-text">
                                <li className="card-detail">
                  <span>
                    <b>Enhanced Patient Experience: </b>
                  </span>
                                    The application provided a user-friendly platform that simplified the process of
                                    finding and booking appointments with healthcare providers, accessing medical
                                    records, and receiving personalized health information.
                                </li>
                                <li className="card-detail">
                  <span>
                    <b>Improved Healthcare Access: </b>
                  </span>
                                    The application improved access to healthcare services, especially for individuals
                                    seeking convenient and timely healthcare consultations through telehealth options.
                                </li>
                                <li className="card-detail">
                  <span>
                    <b>Streamlined Communication: </b>
                  </span>
                                    The application has secure messaging feature facilitated seamless communication
                                    between patients and healthcare providers, enabling efficient and timely exchange
                                    of information.
                                </li>
                                <li className="card-detail">
                  <span>
                    <b>Increased Efficiency for Healthcare Providers: </b>
                  </span>
                                    The application streamlined appointment scheduling, reduced administrative tasks,
                                    and improved communication, allowing healthcare providers to focus more on
                                    delivering quality care.
                                </li>
                                <li className="card-detail">
                  <span>
                    <b>Empowered Patients: </b>
                  </span>
                                    The application empowered patients to take an active role in their healthcare
                                    journey by providing them with access to their electronic medical records,
                                    educational resources, and personalized health information.
                                </li>
                            </ul>
                        </Grid>
                    </Grid>

                    <Grid container spacing={5} alignItems="center">
                        <Grid item xs={12} lg={12} sx={{ml: '', mt: {xs: 3, lg: 3}}}>
                            <DefaultInfoCard
                                type="text"
                                title="Conclusion"
                                description="The collaboration resulted in the successful development of a comprehensive healthcare application. By leveraging a customized tech stack and incorporating user-centric features, it connected patients with healthcare providers, improving access to quality healthcare services. The platform's user-friendly interface, appointment booking, telehealth consultations, electronic medical records, and secure messaging capabilities transformed the patient experience and facilitated efficient healthcare delivery. The application positioned itself as a reliable and convenient healthcare companion, enhancing access, communication, and patient empowerment in the healthcare industry."
                            />
                        </Grid>
                    </Grid>
                </Container>
            </MKBox>
        </>
    );
}

export default ConnectingPatientsandHealthcareProvidersCaseStudyDetails;
