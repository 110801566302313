/*
=========================================================
* Material Kit 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';

// Material Kit 2 React components
import MKBox from '../../../components/box';

//Image
import managed_services_and_staff_augmentation_2 from '../../../assets/images/managed-staff-and-augmentation/managed_services_and_staff_augmentation_2.jpeg';

//CSS
import './DataProtection.css';

function WhyUs() {
  return (
    <MKBox component="section" pt={10} pb={5} style={{ background: '#f0f2f5' }}>
      <Container>
        <Grid container spacing={3} alignItems="center">
          <Grid item xs={12} lg={6} sx={{ ml: '', mt: { xs: 3, lg: 0 } }}>
            <div className="training-benefit">
              <span>
                <i className="fas fa-check-circle managed-services-SA-icon-small"></i> Why Choose
                Us?
              </span>
            </div>
            <div className="training-description">
              <p>
                Choose us for expert data protection services that ensure your data&apos;s privacy, security, and compliance with regulations like GDPR and CCPA. Our robust security measures safeguard your data from breaches and unauthorized access. With a proven track record, we guarantee the integrity and availability of your data. We offer tailored solutions to meet your specific needs and provide ongoing support and monitoring to keep your data secure at all times. Trust us to protect what matters most to you.
              </p>
            </div>
          </Grid>
          <Grid item xs={12} lg={6}>
            <Grid container justifyContent="flex-start">
              <Grid item xs={12} md={12} sx={{ ml: { lg: '100px' } }}>
                <MKBox>
                  <img src={managed_services_and_staff_augmentation_2} className="training-img" alt="Data Protection" />
                </MKBox>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default WhyUs;
