/*
=========================================================
* Material Kit 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';

// Material Kit 2 React components
import MKBox from 'components/box';

// Material Kit 2 React examples
import DefaultInfoCard from 'examples/Cards/InfoCards/DefaultInfoCard';
import websiteNameByBrand from './../../../../utils/websiteNameByBrand';

function Terms() {

  const brand = websiteNameByBrand();

  return (
    <MKBox component="section" py={12}>
      <Container>
        <Grid container spacing={3} alignItems="center">
          <Grid item xs={12} lg={12}>
            <Grid container justifyContent="flex-start">
              <Grid item>
                <MKBox mb={5}>
                  <DefaultInfoCard
                      type="text"
                    // title="Our Mission"
                    description="These Terms of Service ('Terms') govern your use of our website and any related services, features, or content (collectively referred to as the 'Services'). By accessing or using our website, you agree to be bound by these Terms. Please read them carefully before proceeding."
                  />
                </MKBox>
              </Grid>
              <Grid item>
                <MKBox mb={5}>
                  <DefaultInfoCard
                      type="text"
                    title="Acceptance of Terms"
                    description={`By accessing or using the ${brand.title} website, you acknowledge that you have read, understood, and agree to be bound by these Terms. If you do not agree to these Terms, please do not access or use our website.`}
                  />
                </MKBox>
              </Grid>
              <Grid item>
                <MKBox mb={5}>
                  <DefaultInfoCard
                      type="text"
                    title="Use of Services"
                    description="(a) Eligibility: You must be at least 18 years old and have the legal capacity to enter into a binding agreement to use our website and services. By accessing or using our website, you represent and warrant that you meet these requirements."
                  />
                  <DefaultInfoCard type="text" description="(b) User Account: Some features or services on our website may require you to create a user account. You are responsible for maintaining the confidentiality of your account information, and you agree to accept responsibility for all activities that occur under your account." />
                  <DefaultInfoCard type="text" description="(c) Prohibited Activities: You agree not to engage in any of the following activities while using our website:" />
                  <DefaultInfoCard type="text" description="- Violating any applicable laws or regulations." />
                  <DefaultInfoCard type="text" description="- Impersonating another person or entity." />
                  <DefaultInfoCard type="text"
                    description="- Using our website to transmit any unauthorized or unsolicited advertising,
                      promotional materials, or spam."
                  />
                  <DefaultInfoCard type="text"
                    description="- Disrupting or interfering with the security or integrity of our website or
                      Services."
                  />
                  <DefaultInfoCard type="text"
                    description="- Collecting or harvesting any personally identifiable information from our
                      website without proper authorization."
                  />
                </MKBox>
              </Grid>
              <Grid item>
                <MKBox mb={5}>
                  <DefaultInfoCard type="text"
                    title="Intellectual Property Rights"
                    description={`(a) Ownership: The ${brand.title} website and all content, features, and functionality are owned by ${brand.title} and are protected by intellectual property laws. You acknowledge and agree that ${brand.title} retains all rights, title, and interest in the website and its content.`}
                  />
                </MKBox>
              </Grid>
              <Grid item>
                <MKBox mb={5}>
                  <DefaultInfoCard type="text"
                    title="Intellectual Property Rights"
                    description={`(b) Limited License: ${brand.title} grants you a limited, revocable, non-exclusive, and non-transferable license to access and use our website solely for personal and non-commercial purposes. You may not modify, reproduce, distribute, or create derivative works based on the ${brand.title} website without our prior written consent.`}
                  />
                </MKBox>
              </Grid>
              <Grid item>
                <MKBox mb={5}>
                  <DefaultInfoCard type="text"
                    title="Privacy"
                    description="Your privacy is important to us. Please review our Privacy Policy, which explains how we collect, use, and disclose information from our users. By using our website, you consent to the collection and use of your information as described in our Privacy Policy."
                  />
                </MKBox>
              </Grid>
              <Grid item>
                <MKBox mb={5}>
                  <DefaultInfoCard type="text"
                    title="Disclaimer of Warranties"
                    description="(a) ${brand.title} makes no representations or warranties about the accuracy, reliability, completeness, or timeliness of the information or content provided on our website. The use of our website is at your own risk."
                  />
                  <DefaultInfoCard type="text" description="(b) ${brand.title} disclaims all warranties, express or implied, including but not limited to warranties of merchantability, fitness for a particular purpose, and non-infringement. We do not warrant that our website will be uninterrupted or error-free, or that any defects will be corrected." />
                </MKBox>
              </Grid>
              <Grid item>
                <MKBox mb={5}>
                  <DefaultInfoCard type="text"
                    title="Limitation of Liability"
                    description="To the maximum extent permitted by law, ${brand.title} and its affiliates, officers, directors, employees, agents, or licensors shall not be liable for any direct, indirect, incidental, consequential, or special damages arising out of or in any way connected with the use or inability to use our website, even if advised of the possibility of such damages."
                  />
                </MKBox>
              </Grid>
              <Grid item>
                <MKBox mb={5}>
                  <DefaultInfoCard type="text"
                    title="Indemnification"
                    description="You agree to defend, indemnify, and hold ${brand.title} and its affiliates, officers, directors, employees, agents, or licensors harmless from and against any claims, liabilities, damages, losses, and expenses, including reasonable attorneys' fees, arising out of or in any way connected with your use of our website or violation of these Terms."
                  />
                </MKBox>
              </Grid>
              <Grid item>
                <MKBox mb={5}>
                  <DefaultInfoCard type="text"
                    title="Modifications to Terms"
                    description={`${brand.title} reserves the right to modify or update these Terms at any time, without prior notice. We encourage you to review these Terms periodically to stay informed of any changes. Your continued use of our website after any modifications indicates your acceptance of the revised Terms.`}
                  />
                </MKBox>
              </Grid>
              <Grid item>
                <MKBox mb={5}>
                  <DefaultInfoCard type="text"
                    title="Termination"
                    description={`${brand.title} may, in its sole discretion, suspend or terminate your access to all or any part of our website at any time, for any reason, without prior notice.`}
                  />
                </MKBox>
              </Grid>
              <Grid item>
                <MKBox mb={5}>
                  <DefaultInfoCard type="text"
                    title="Governing Law and Jurisdiction"
                    description="These Terms shall be governed by and construed in accordance with the laws of [Jurisdiction]. Any legal action or proceeding arising out of or related to these Terms or your use of our website shall be exclusively brought in the courts located within [Jurisdiction]."
                  />
                </MKBox>
              </Grid>
              <Grid item>
                <MKBox mb={5}>
                  <DefaultInfoCard type="text"
                    title="Entire Agreement"
                    description={`These Terms constitute the entire agreement between you and ${brand.title} regarding your use of our website and supersede any prior or contemporaneous agreements, communications, and proposals, whether oral or written, between you and ${brand.title}.`}
                  />
                </MKBox>
              </Grid>
              <Grid item>
                <MKBox mb={5}>
                  <DefaultInfoCard type="text" description="Effective date: Sept 1, 2024" />
                </MKBox>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default Terms;
