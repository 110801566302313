import React from 'react';
import PropTypes from 'prop-types';  // Import PropTypes
import { CardContent, CardHeader } from '@mui/material';
import TemplateTable from '../sections/TemplateTable';
import MKButton from '../../../components/button';
import MKBox from '../../../components/box';
import Container from '@mui/material/Container';
import {Add} from "@mui/icons-material";
import {Link} from "react-router-dom";

function TemplatePage({ title, fetchData, buttonLabel, uploadButton }) {

    const getFileType = (fileName) => {
        if (!fileName) return 'Unknown';
        const extension = fileName.split('.').pop();
        return extension.toLowerCase();
    };

    return (
        <MKBox component="section" py={{ xs: 0, lg: 6 }}>
            <Container>
                <CardHeader
                    title={title}
                    titleTypographyProps={{ style: { fontWeight: 'bold', fontSize: '24px' } }}
                    action={

                        uploadButton !== "true" &&
                        <MKButton
                            variant="contained"
                            color="success"
                            component={Link}
                            to="/pages/user/create-gap-analysis"
                            startIcon={<i className="fas fa-cloud-upload-alt"></i>}
                        >
                            Upload Template
                        </MKButton>
                    }
                />
                <CardContent>
                    {
                        buttonLabel !== "" &&
                        <MKButton
                            variant="gradient"
                            color="info"
                            className="pulsing-btn"
                            startIcon={<Add />}
                            sx={{ mb: 2 }}
                        >
                            {buttonLabel}
                        </MKButton>
                    }
                    <TemplateTable
                        data={fetchData}
                        columns={
                                title === "Gap Analysis Records" ?
                                    [
                                        { Header: 'ID', accessor: 'id' },
                                        { Header: 'Title', accessor: 'title' },
                                        { Header: 'Status', accessor: 'status' },
                                        {
                                            Header: 'User',
                                            accessor: (row) => row.user?.displayName || 'N/A',
                                        },
                                        {
                                            Header: 'Sections',
                                            accessor: (row) => (Array.isArray(row.sections) ? row.sections.length : 0),
                                        },
                                        {
                                            Header: 'Actions',
                                            accessor: 'actions',
                                            sortable: false,
                                            filterable: false,
                                        },
                                    ] :
                                    [
                                        { Header: 'Name', accessor: 'name' },
                                        { Header: 'Author', accessor: row => row.createdBy?.user?.displayName || 'N/A' },
                                        { Header: 'Modified By', accessor: row => row.lastModifiedBy?.user?.displayName || 'N/A' },
                                        { Header: 'Created Date', accessor: row => new Date(row.createdDateTime).toLocaleDateString() },
                                        { Header: 'Type', accessor: row => getFileType(row?.name)},
                                        { Header: 'Modified Date', accessor: row => new Date(row.lastModifiedDateTime).toLocaleDateString() },
                                        { Header: 'Actions', accessor: 'actions', sortable: false, filterable: false },
                                    ]
                                }
                    />
                </CardContent>
            </Container>
        </MKBox>
    );
}

// Define PropTypes for TemplatePage
TemplatePage.propTypes = {
    title: PropTypes.string.isRequired,
    fetchData: PropTypes.array.isRequired,
    buttonLabel: PropTypes.string.isRequired,
    uploadButton: PropTypes.string.isRequired,
};

export default TemplatePage;
