import React from 'react';
import TemplatePage from "../../PMTemplate/sections/TemplatePage";
import PropTypes from "prop-types";

export default function BATemp({fetchData, profile}) {

    return (
        <TemplatePage
            title="BA Templates"
            fetchData={fetchData}
            profile={profile}
            buttonLabel="Create work document"
        />
    );
};

BATemp.propTypes = {
    fetchData: PropTypes.array.isRequired,
    profile: PropTypes.object
};

BATemp.defaultProps = {
    profile: null,
    oneDriveFiles: []
};