/*
=========================================================
* Material Kit 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';

// Material Kit 2 React components
import MKBox from 'components/box';


//Images
import ecommerce_industry_1 from '../../../../../assets/images/industry/ecommerce_industry_1.jpg';
import ecommerce_industry_2 from '../../../../../assets/images/industry/ecommerce_industry_2.jpeg';
import portfolio_details_4 from '../../../../../assets/images/software-development/portfolio_details_4.png';
import portfolio_details_3 from '../../../../../assets/images/software-development/portfolio_details_3.png';
import portfolio_details_1 from '../../../../../assets/images/software-development/portfolio_details_1.png';
import portfolio_details_5 from '../../../../../assets/images/software-development/portfolio_details_2.png';

// Css
import './RetailSolutionCaseStudyDetails.css';
import DefaultInfoCard from '../../../../../examples/Cards/InfoCards/DefaultInfoCard';

function RetailSolutionCaseStudyDetails() {
    return (
        <>
            <MKBox component="section" pt={12} pb={5}>
                <Container>
                    <Grid container spacing={5} alignItems="center">
                        <Grid item xs={12} lg={5} sx={{ml: '', mt: {xs: 3, lg: 0}}}>
                            <img src={ecommerce_industry_1} className="image-portfolio-details"/>
                        </Grid>
                        <Grid item xs={12} lg={7}>
                            <Grid container justifyContent="flex-start">
                                <Grid item xs={12} md={12}>
                                    <MKBox>
                                        <DefaultInfoCard
                                            type="text"
                                            title="Retail Solution"
                                            description="This use case delves into a cutting-edge application aimed at transforming the online shopping experience. The focus is on developing a user-friendly and intuitive platform that goes beyond simply offering a diverse product selection. By incorporating personalized recommendations, secure payment options, and efficient order management, this solution strives to cater to the distinct needs and preferences of contemporary consumers."
                                        />
                                        <div className="container">
                                            <div className="box-portfolio-details">
                                                <img src={portfolio_details_4} alt="Country"/>
                                                <p>
                                                    <b>Country:</b> Jamaica
                                                </p>
                                            </div>
                                            <div className="box-portfolio-details">
                                                <img src={portfolio_details_3} alt="platform"/>
                                                <p>
                                                    <b>Platform:</b> iOS , Web
                                                </p>
                                            </div>
                                            <div className="box-portfolio-details">
                                                <img src={portfolio_details_1} alt="industry"/>
                                                <p>
                                                    <b>Industry:</b> E-Commerce
                                                </p>
                                            </div>
                                            <div className="box-portfolio-details">
                                                <img src={portfolio_details_5} alt="duration"/>
                                                <p>
                                                    <b>Duration:</b> 2003-Present
                                                </p>
                                            </div>
                                        </div>
                                    </MKBox>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Container>
            </MKBox>
            <MKBox component="section" pb={5} style={{background: '#f0f2f5'}}>
                <Container>
                    <Grid container spacing={5} alignItems="center">
                        <Grid item xs={12} lg={12} sx={{ml: '', mt: {xs: 3, lg: 10}, mb: {xs: 3, lg: 5}}}>
                            <DefaultInfoCard
                                type="text" title="Lorem" description="Lorem ipsom"/>
                        </Grid>
                    </Grid>

                    <Grid container spacing={5} alignItems="center">
                        <Grid item xs={12} lg={3} md={6} sx={{ml: '', mb: {xs: 0, lg: 3}}}>
                            <div className="card-portfolio-details">
                                {/*<h2 className="card-portfolio-details-header">Team</h2>*/}
                                {/*<p className="card-detail">Project Manager</p>*/}
                                {/*<p className="card-detail">UI/UX Designer</p>*/}
                                {/*<p className="card-detail">Frontend Developer</p>*/}
                                {/*<p className="card-detail">Backend Developer</p>*/}
                                {/*<p className="card-detail">Quality Assurance Engineer</p>*/}
                            </div>
                        </Grid>
                        <Grid item xs={12} lg={3} md={6} sx={{ml: '', mb: {xs: 0, lg: 3}}}>
                            <div className="card-portfolio-details">
                                {/*<h2 className="card-portfolio-details-header">Tech Stack:</h2>*/}
                                {/*<p className="card-detail">React</p>*/}
                                {/*<p className="card-detail">Node.js</p>*/}
                                {/*<p className="card-detail">PostgreSQL</p>*/}
                            </div>
                        </Grid>
                        <Grid item xs={12} lg={3} md={6} sx={{ml: '', mb: {xs: 0, lg: 3}}}>
                            <div className="card-portfolio-details">
                                {/*<h2 className="card-portfolio-details-header">Target Audience</h2>*/}
                                {/*<p className="card-detail">Wide range of online shoppers</p>*/}
                            </div>
                        </Grid>
                        <Grid item xs={12} lg={3} md={6} sx={{ml: '', mb: {xs: 0, lg: 3}}}>
                            <div className="card-portfolio-details">
                                {/*<h2 className="card-portfolio-details-header">Services</h2>*/}
                                {/*<p className="card-detail">User-Friendly Interface</p>*/}
                                {/*<p className="card-detail">Personalized Recommendations</p>*/}
                                {/*<p className="card-detail">Secure Payment Options</p>*/}
                                {/*<p className="card-detail">Efficient Order Management</p>*/}
                                {/*<p className="card-detail">Scalability and Performance</p>*/}
                            </div>
                        </Grid>
                    </Grid>
                </Container>
            </MKBox>
            <MKBox component="section" pb={5}>
                <Container>
                    <Grid container spacing={5} alignItems="center">
                        <Grid item xs={12} lg={5} sx={{ml: '', mt: {xs: 10, lg: 10}}}>
                            <img src={ecommerce_industry_2} className="image-portfolio-details"/>
                        </Grid>
                        <Grid item xs={12} lg={7}>
                            <Grid container justifyContent="flex-start">
                                <Grid item xs={12} md={12}>
                                    <MKBox>
                                        <DefaultInfoCard
                                            type="text"
                                            title="Opportunities"
                                            description="A leading e-commerce company, recognized the need for a robust and user-friendly application to enhance their online shopping platform. They sought to provide a seamless and personalized shopping experience to their customers, improve conversion rates, and streamline their inventory management and order fulfillment processes."
                                        />
                                    </MKBox>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Container>
            </MKBox>
            <MKBox component="section" pb={5} style={{background: '#f0f2f5'}}>
                <Container>
                    <Grid container spacing={5} alignItems="center">
                        <Grid item xs={12} lg={12} sx={{ml: '', mt: {xs: 3, lg: 10}}}>
                            <DefaultInfoCard
                                type="text"
                                title="Our Solution"
                                description="By partnering with us, together we developed an innovative application designed to transform e-commerce experiences. Our team collaborated closely with the client to understand their specific requirements and create a tailored solution. Leveraging cutting-edge technologies such as React and Node.js, we built a scalable and feature-rich application that catered to the diverse needs of customers and optimized backend operations."
                            />
                        </Grid>
                    </Grid>

                    <Grid container spacing={5} alignItems="center">
                        <Grid item xs={12} lg={12} md={12} sx={{ml: '', mb: {xs: 0, lg: 3}}}>
                            <h1 className="portfolio-details-text-header">
                                The key outcomes of the project included
                            </h1>
                            <ul className="portfolio-details-text">
                                <li className="card-detail">
                  <span>
                    <b>Enhanced User Experience:</b>
                  </span>
                                    The application provided a seamless and enjoyable shopping experience with its
                                    user-friendly interface, personalized recommendations, and hassle-free checkout
                                    process.
                                </li>
                                <li className="card-detail">
                  <span>
                    <b>Increased Sales and Customer Engagement: </b>
                  </span>
                                    The personalized recommendations and intuitive interface led to improved customer
                                    engagement, higher conversion rates, and increased sales.
                                </li>
                                <li className="card-detail">
                  <span>
                    <b>Improved Order Management: </b>
                  </span>
                                    The application streamlined the order management process, enabling customers to
                                    track their orders, manage returns, and access order history effortlessly.
                                </li>
                                <li className="card-detail">
                  <span>
                    <b>Secure Transactions: </b>
                  </span>
                                    The integration of secure payment gateways ensured safe and secure transactions,
                                    fostering trust and confidence among customers.
                                </li>
                            </ul>
                        </Grid>
                    </Grid>

                    <Grid container spacing={5} alignItems="center">
                        <Grid item xs={12} lg={12} sx={{ml: '', mt: {xs: 3, lg: 3}}}>
                            <DefaultInfoCard
                                type="text"
                                title="Conclusion"
                                description="The collaboration resulted in the successful development of an innovative e-commerce application that revolutionized online shopping. By leveraging the latest technologies and implementing user-centric design principles, the application's delivered an exceptional user experience, personalized recommendations, and secure transactions. The application's seamless navigation, efficient order management, and personalized features contributed to increased customer engagement, sales growth, and improved customer satisfaction. The application empowered our client to stay ahead in the competitive e-commerce industry and deliver exceptional value to their customers."
                            />
                        </Grid>
                    </Grid>
                </Container>
            </MKBox>
        </>
    );
}

export default RetailSolutionCaseStudyDetails;
