import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Card, Grid, Icon, Menu, MenuItem } from '@mui/material';
import MuiLink from '@mui/material/Link';
import MKBox from 'components/box';
import MKTypography from 'components/typography';
import MKButton from 'components/button';

function CenteredBlogCard({ image, title, description, action, menuItems }) {
    const [dropdown, setDropdown] = useState(null);
    const openDropdown = ({ currentTarget }) => setDropdown(currentTarget);
    const closeDropdown = () => setDropdown(null);

    // Styles
    const iconStyles = {
        ml: 1,
        fontWeight: 'bold',
        transition: 'transform 200ms ease-in-out',
    };

    const dropdownIconStyles = {
        transform: dropdown ? 'rotate(180deg)' : 'rotate(0)',
        ...iconStyles,
    };

    // Determine the wrapper element based on action type to avoid nesting issues
    const Wrapper = action.type === 'internal' || action.type === 'premBtn' ? Link : 'div'; // Use Link only for internal actions
    const wrapperProps = action.type === 'internal' || action.type === 'premBtn' ? { to: action.route, style: { textDecoration: 'none', color: 'inherit' } } : {};

    return (
        <Wrapper {...wrapperProps}>
            <Card>
                <MKBox position="relative" borderRadius="lg" mx={2} mt={-3}>
                    {image && image !== 'none' ? (
                        <MKBox
                            component="img"
                            src={image}
                            alt={title}
                            borderRadius="lg"
                            width="100%"
                            position="relative"
                            zIndex={1}
                        />
                    ) : null}
                    <MKBox
                        borderRadius="lg"
                        shadow="md"
                        width="100%"
                        height="100%"
                        position="absolute"
                        left={0}
                        top={0}
                        sx={{
                            backgroundImage: `url(${image})`,
                            transform: 'scale(0.94)',
                            filter: 'blur(12px)',
                            backgroundSize: 'cover',
                        }}
                    />
                </MKBox>
                <MKBox p={3} mt={-1} textAlign="center">
                    <MKTypography display="inline" variant="h5" textTransform="capitalize" fontWeight="regular">
                        {title && title !== 'none' ? <>{title}</> : ''}
                    </MKTypography>
                    <MKBox mt={1} mb={3}>
                        <MKTypography variant="body2" component="p" color="text">
                            {description}
                        </MKTypography>
                    </MKBox>
                    {action.type === 'external' ? (
                        <MKButton
                            component={MuiLink}
                            href={action.route}
                            target="_blank"
                            rel="noreferrer"
                            variant="gradient"
                            size="small"
                            color={action.color ? action.color : 'dark'}
                        >
                            {action.label}
                        </MKButton>
                    ) : action.type === 'dropdown' ? (
                        <Grid item xs={12} lg={12}>
                            <MKButton variant="gradient" color="info" size="small" onClick={openDropdown}>
                                {action.label} <Icon sx={dropdownIconStyles}>expand_more</Icon>
                            </MKButton>
                            <Menu anchorEl={dropdown} open={Boolean(dropdown)} onClose={closeDropdown}>
                                {Object.keys(menuItems.route).map((key) => (
                                    <MenuItem key={key} component={MuiLink} href={menuItems.route[key]}>
                                        {menuItems.menuItemLabels[key]}
                                    </MenuItem>
                                ))}
                            </Menu>
                        </Grid>
                    ) : (
                        // Change start: Conditional check to avoid nested Link components
                        <MKButton
                            component={action.type === 'internal' || action.type === 'premBtn' ? 'button' : Link}
                            to={action.type === 'internal' || action.type === 'premBtn' ? undefined : action.route}
                            onClick={action.type === 'internal' || action.type === 'premBtn' ? () => window.location.href = action.route : undefined}
                            variant="gradient"
                            size="small"
                            color={action.color ? action.color : 'dark'}
                        >
                            {action.btnIcon !== 'none' && action.btnIcon !== '' ? (
                                <Icon sx={{ fontSize: 24, marginRight: 1 }}>{action.btnIcon}</Icon>
                            ) : (
                                ''
                            )}
                            {action.label}
                        </MKButton>
                        // Change end
                    )}
                </MKBox>
            </Card>
        </Wrapper>
    );
}

// Typechecking props for the CenteredBlogCard
CenteredBlogCard.propTypes = {
    image: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    menuItems: PropTypes.arrayOf(
        PropTypes.shape({
            education: PropTypes.shape({
                route: PropTypes.string.isRequired,
                menuItemLabels: PropTypes.string.isRequired,
            }).isRequired,
            ecommerce: PropTypes.shape({
                route: PropTypes.string.isRequired,
                buttonLabel: PropTypes.string.isRequired,
                menuItemLabels: PropTypes.string.isRequired,
            }).isRequired,
            health: PropTypes.shape({
                route: PropTypes.string.isRequired,
                buttonLabel: PropTypes.string.isRequired,
                menuItemLabels: PropTypes.string.isRequired,
            }).isRequired,
            finance: PropTypes.shape({
                route: PropTypes.string.isRequired,
                buttonLabel: PropTypes.string.isRequired,
                menuItemLabels: PropTypes.string.isRequired,
            }),
        })
    ),
    action: PropTypes.shape({
        type: PropTypes.oneOf(['external', 'dropdown', 'internal', 'premBtn']).isRequired,
        route: PropTypes.string.isRequired,
        color: PropTypes.oneOf([
            'primary',
            'secondary',
            'info',
            'success',
            'warning',
            'error',
            'dark',
            'light',
        ]),
        label: PropTypes.string.isRequired,
        btnIcon: PropTypes.string,
    }).isRequired,
};

export default CenteredBlogCard;
