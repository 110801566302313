
import React from "react";
import MKBox from '../../components/box';

// Material Kit 2 React examples
import DefaultNavbar from '../../examples/Navbars/DefaultNavbar';
import DefaultFooter from '../../examples/Footers/DefaultFooter';

// Routes
import {premRoutes} from '../../routes';
import footerRoutes from '../../footer.routes';

//  User Profile Sections
import BATemp from './sections/BATemp';
import {Card} from '@mui/material';
import {useIsAuthenticated} from "@azure/msal-react";
import {useUser} from "../../utils/UserContext";
import ProfileImage from "../../utils/navBarProfileImage";

function BATemplate() {
    const isAuthenticated = useIsAuthenticated();
    const { profile, BAFiles, profileImage } = useUser();

    if (!isAuthenticated) {
        return <div>You are not authorized to view this page. Please log in.</div>;
    }

    return (
        <>
            <DefaultNavbar
                routes={premRoutes}
                action={{
                    type: 'internal',
                    route: '/pages/authentication/sign-in',
                    label: 'Sign In',
                    color: 'info',
                }}
                profileImage={profileImage && (
                    <ProfileImage imageUrl={profileImage} name={profile?.name} />
                )}
            />
            <MKBox
                minHeight="20vh"
                width="100%"
                sx={{
                    backgroundSize: 'cover',
                    backgroundPosition: 'center',
                    display: 'grid',
                    placeItems: 'center',
                    marginBottom: '30px'
                }}
            ></MKBox>
            <Card
                sx={{
                    p: 2,
                    mt: -8,
                    mb: 4,
                    mx: {xs: 2, lg: 3},
                    boxShadow: ({boxShadows: {xxl}}) => xxl,
                }}
            >
                {BAFiles.length > 0 ? (
                    <BATemp
                        fetchData={BAFiles}
                        profile={profile}
                    />
                ) : <div>An error occurred while retrieving data</div> }

            </Card>

            <MKBox pt={6} px={1} mt={6}>
                <DefaultFooter content={footerRoutes}/>
            </MKBox>
        </>
    );
}

export default BATemplate;
