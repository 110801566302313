import React, { useState } from 'react';
import Card from '@mui/material/Card';
import MKBox from '../../components/box';
import DefaultNavbar from '../../examples/Navbars/DefaultNavbar';
import DefaultFooter from '../../examples/Footers/DefaultFooter';
import {premRoutes} from '../../routes';
import footerRoutes from '../../footer.routes';
import { useIsAuthenticated } from '@azure/msal-react';
import SplashScreenModal from './sections/SplashScreenModal';
import UProfile from './sections/UProfile';
import {useUser} from "../../utils/UserContext";
import UserTemp from "./sections/UserTemp";
import ProfileImage from "../../utils/navBarProfileImage";


// eslint-disable-next-line react/prop-types
function UserProfile() {
    const [showSplashScreen, setShowSplashScreen] = useState(true);
    const isAuthenticated = useIsAuthenticated();
    const { profile, profileImage, oneDriveFiles, manager, managerPhoto, loading } = useUser();

    if (loading) {
        return <div>Loading...</div>;
    }

    if (!isAuthenticated) {
        return <div>You are not authorized to view this page. Please log in.</div>;
    }

    return (
        <>
            <DefaultNavbar
                routes={premRoutes}
                action={{
                    type: 'internal',
                    route: '/pages/user/ba-template',
                    label: 'Sign In',
                    color: 'info',
                }}
                profileImage={profileImage && (
                    <ProfileImage imageUrl={profileImage} name={profile?.name} />
                )}
            />
            <MKBox
                minHeight="20vh"
                width="100%"
                sx={{
                    backgroundSize: 'cover',
                    backgroundPosition: 'center',
                    display: 'grid',
                    placeItems: 'center',
                    marginBottom: '30px'
                }}
            />
            <Card
                sx={{
                    backgroundColor: '#fafafa',
                    p: 2,
                    mx: { xs: 2, lg: 3 },
                    mt: -8,
                    mb: 4,
                    boxShadow: ({ boxShadows: { xxl } }) => xxl,
                }}
            >
            <UProfile
                profileImage={profileImage}
                profileInfo={profile}
                manager={manager}
                managerPhoto={managerPhoto}
            />
            <UserTemp
                fetchData={oneDriveFiles}
                profile={profile}
                oneDriveFiles={oneDriveFiles}
                />
            </Card>
            <MKBox pt={6} px={1} mt={6}>
                <DefaultFooter content={footerRoutes} />
            </MKBox>
            <SplashScreenModal open={showSplashScreen} onClose={() => setShowSplashScreen(false)} />
        </>
    );
}

export default UserProfile;
