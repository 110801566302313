/*
=========================================================
* Material Kit 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';

// Material Kit 2 React components
import MKBox from 'components/box';

//Image
import image1 from '../../../assets/images/enterprise-data-management-page/enterprise_data_management_6.jpeg';
import image2 from '../../../assets/images/enterprise-data-management-page/enterprise_data_management_7.jpeg';
import image3 from '../../../assets/images/enterprise-data-management-page/enterprise_data_management_8.jpeg';
import image4 from '../../../assets/images/enterprise-data-management-page/enterprise_data_management_9.jpeg';

// CSS
// import './EnterpriseDataManagement.css';
import '../../Training/sections/Training.css';
import websiteNameByBrand from './../../../utils/websiteNameByBrand';

function UncoveringInsights() {

  const brand = websiteNameByBrand();

  return (
    <MKBox component="section" pt={5}>
      <Container>
        <Grid container spacing={3} alignItems="center">
          <Grid item xs={12} lg={12}>
            <Grid container justifyContent="flex-start">
              <Grid item xs={12} md={12}>
                <MKBox>
                  <div className="training-benefit">
                    <span>Analytics: Uncovering Insights for Informed Decision-Making</span>
                  </div>
                  <div className="training-description">
                    <p>
                      Analytics is the practice of examining data to derive insights, patterns, and
                      trends that inform decision-making and drive business value. At {brand.title}, we
                      employ advanced analytics techniques, such as statistical analysis, data
                      mining, machine learning, and visualization tools, to analyze large volumes of
                      data and extract meaningful information.
                    </p>
                    <p className="training-benefit-list">
                      By leveraging our analytics solutions, you can expect the following
                      advantages:
                    </p>
                  </div>
                  <div className="card-container">
                    <div className="card">
                      <div className="card-content">
                        <img src={image1} alt="Image 1" className="card-image" />
                        <div className="card-details">
                          <h3 className="card-title">Informed Decision-Making</h3>
                          <p className="card-description">
                            Our analytics capabilities empower you to make data-driven decisions. By
                            uncovering patterns, trends, and correlations within your data, we
                            provide you with valuable insights that support strategic
                            decision-making, optimize operations, and identify new opportunities.
                          </p>
                        </div>
                      </div>
                    </div>

                    <div className="card">
                      <div className="card-content">
                        <img src={image2} alt="Image 2" className="card-image" />
                        <div className="card-details">
                          <h3 className="card-title">Customer Behavior Understanding</h3>
                          <p className="card-description">
                            Analytics helps you gain a deeper understanding of your customers&apos;
                            behavior, preferences, and needs. By analyzing customer data, you can
                            tailor your products, services, and marketing strategies to enhance
                            customer experiences, increase customer satisfaction, and drive loyalty.
                          </p>
                        </div>
                      </div>
                    </div>

                    <div className="card">
                      <div className="card-content">
                        <img src={image3} alt="Image 3" className="card-image" />
                        <div className="card-details">
                          <h3 className="card-title">Process Optimization</h3>
                          <p className="card-description">
                            Through analytics, we identify inefficiencies, bottlenecks, and areas of
                            improvement in your business processes. By optimizing these processes,
                            you can enhance operational efficiency, reduce costs, and improve
                            overall performance.
                          </p>
                        </div>
                      </div>
                    </div>

                    <div className="card">
                      <div className="card-content">
                        <img src={image4} alt="Image 4" className="card-image" />
                        <div className="card-details">
                          <h3 className="card-title">Predictive and Prescriptive Analytics</h3>
                          <p className="card-description">
                            Our advanced analytics techniques enable predictive and prescriptive
                            analytics. By leveraging historical data, we can forecast future events,
                            identify trends, and simulate different scenarios to help you
                            proactively plan and optimize your strategies.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </MKBox>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default UncoveringInsights;
