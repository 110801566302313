/*
=========================================================
* Material Kit 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';

// Material Kit 2 React components
import MKBox from '../../../components/box';

// Material Kit 2 React examples
// Image
import data_analytics_1 from '../../../assets/images/data-analytics-page/data_analytics_1.jpeg';
import training_1 from '../../../assets/images/training/training_1.jpeg';

// CSS
import '../../Training/sections/Training.css';
import DefaultInfoCard from '../../../examples/Cards/InfoCards/DefaultInfoCard';
import websiteNameByBrand from 'utils/websiteNameByBrand';

function DataAnalytics() {

    const brand = websiteNameByBrand();

    return (
        <MKBox component="section" pt={6}>
            <Container>
                <Grid container spacing={3} alignItems="center">
                    <Grid item xs={12} lg={6} sx={{ml: '', mt: {xs: 3, lg: 0}, }}>
                        <img className="data-analytics-img" src={data_analytics_1} alt="data analysis image"/>
                    </Grid>
                    <Grid item xs={12} lg={6}>
                        <Grid container justifyContent="flex-start">
                            <Grid item xs={12} md={12}>
                                <MKBox mb={5} mt={12}>
                                    <DefaultInfoCard
                                        type="text"
                                        title="What is Data Analytics?"
                                        description="Data analytics is the process of examining and interpreting raw data to uncover
                    valuable insights and patterns. It involves collecting, organizing, and analyzing data from
                    various sources such as customer interactions, sales transactions, social media, and more.
                    Our team of skilled data analysts and scientists utilizes advanced tools and techniques to extract
                    meaningful information from your data."
                                    />
                                    <DefaultInfoCard
                                        type="text"
                                        title="Our Process"
                                        description="Our data analytics process begins with meticulous data collection and cleansing to ensure accuracy and reliability. Through interactive dashboards and reports, we provide clear and concise representations of your data, making it easier to identify trends, spot opportunities, and address challenges."
                                    />
                                </MKBox>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                { brand.title === "SHPRIVACY" ?
                <Grid container spacing={3} alignItems="center" mb={10}>
                    <Grid item xs={12} lg={6} sx={{ml: '', mt: {xs: 3, lg: 0}}}>
                        <img className="data-analytics-img" src={training_1} alt="data analysis image"/>
                    </Grid>
                    <Grid item xs={12} lg={6}>
                        <Grid container justifyContent="flex-start">
                            <Grid item xs={12} md={12}>
                                <MKBox mb={12}>
                                    <DefaultInfoCard
                                        type="text"
                                        title="Data Analysis"
                                        description="Data analysis is the process of inspecting, cleaning, transforming, and modeling data to discover useful information, draw conclusions, and support decision-making. It involves various techniques and methodologies to identify patterns, trends, and relationships within data sets. Effective data analysis helps organizations make informed decisions, optimize processes, and achieve strategic goals."
                                    />
                                </MKBox>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                    : ""
                }

            </Container>
        </MKBox>
    );
}

export default DataAnalytics;
