import websiteNameByBrand from './websiteNameByBrand';

export function setFavicon() {
    const brand = websiteNameByBrand(); // Get the brand object
    const faviconUrl = brand.favicon; // Access the 'favicon' property for the favicon URL
    const pageTitle = brand.title;

    // Check if the favicon link already exists
    let link = document.querySelector("link[rel~='icon']");

    if (link) {
        link.href = faviconUrl;
    } else {
        // Create a new favicon link if it doesn't exist
        link = document.createElement('link');
        link.rel = 'icon';
        link.href = faviconUrl;
        document.head.appendChild(link);
    }
    document.title = pageTitle;
}

