/*
=========================================================
* Material Kit 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';

// Material Kit 2 React components
import MKBox from '../../../components/box';

// Material Kit 2 React examples
import DefaultInfoCard from '../../../examples/Cards/InfoCards/DefaultInfoCard';


//Image
import managed_services_and_staff_augmentation_1 from '../../../assets/images/data-protection/data_protection_1.jpeg';

// CSS
import '../../DataProtection/sections/DataProtection.css';

function DataProtection() {
  return (
    <MKBox component="section" pt={2} pb={5}>
      <Container>
        <Grid container spacing={5} alignItems="center">
          <Grid item xs={12} lg={5} sx={{ ml: '', mt: { xs: 3, lg: 16 } }}>
            <img src={managed_services_and_staff_augmentation_1} className="training-img" alt="data protection" />
          </Grid>
          <Grid item xs={12} lg={7}>
            <Grid container justifyContent="flex-start">
              <Grid item xs={12} md={12}>
                <MKBox>
                  <DefaultInfoCard
                                        type="text"
                    title="What is Data Protection?"
                    description="Data protection involves the safeguarding of personal and sensitive information from unauthorized access, use, or disclosure. It encompasses practices and technologies to ensure data privacy, security, integrity, and availability. Key elements include encrypting data, securing access controls, complying with regulations like GDPR and CCPA, and maintaining robust breach response plans. Effective data protection fosters trust, secures sensitive information, and ensures compliance with legal standards."
                  />
                </MKBox>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default DataProtection;
