/*
=========================================================
* Material Kit 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';

// Material Kit 2 React components
import MKBox from 'components/box';

//Images
import health_industry_1 from '../../../../../assets/images/industry/health_industry_1.jpeg';
import health_industry_2 from '../../../../../assets/images/industry/health_industry_2.jpeg';
import portfolio_details_4 from '../../../../../assets/images/software-development/portfolio_details_4.png';
import software_development_3 from '../../../../../assets/images/software-development/portfolio_details_3.png';
import software_development_4 from '../../../../../assets/images/software-development/portfolio_details_1.png';
import software_development_5 from '../../../../../assets/images/software-development/portfolio_details_2.png';

// Css
import './AppointmentBookingCaseStudyDetails.css';
import DefaultInfoCard from '../../../../../examples/Cards/InfoCards/DefaultInfoCard';

function AppointmentBookingCaseStudyDetails() {
    return (
        <>
            <MKBox component="section" pt={12} pb={5}>
                <Container>
                    <Grid container spacing={5} alignItems="center">
                        <Grid item xs={12} lg={5} sx={{ml: '', mt: {xs: 3, lg: 0}}}>
                            <img src={health_industry_2} className="image-portfolio-details"/>
                        </Grid>
                        <Grid item xs={12} lg={7}>
                            <Grid container justifyContent="flex-start">
                                <Grid item xs={12} md={12}>
                                    <MKBox>
                                        <DefaultInfoCard
                                            type="text"
                                            title="Appointment Booking"
                                            description="This use case delves into an advanced loan service application designed to enhance online services and deliver a seamless loan application and management experience to customers. The focus is on expanding the range of services offered through a state-of-the-art application, ultimately providing a convenient and user-friendly platform for customers to apply for and manage their loans."
                                        />
                                        <div className="container">
                                            <div className="box-portfolio-details">
                                                <img src={portfolio_details_4} alt="Country"/>
                                                <p>
                                                    <b>Country:</b> Jamaica
                                                </p>
                                            </div>
                                            <div className="box-portfolio-details">
                                                <img src={software_development_3} alt="Android"/>
                                                <p>
                                                    <b>Platform:</b> iOS , Web
                                                </p>
                                            </div>
                                            <div className="box-portfolio-details">
                                                <img src={software_development_4} alt="Windows"/>
                                                <p>
                                                    <b>Industry:</b> Finance
                                                </p>
                                            </div>
                                            <div className="box-portfolio-details">
                                                <img src={software_development_5} alt="React"/>
                                                <p>
                                                    <b>Duration:</b> 2019-Present
                                                </p>
                                            </div>
                                        </div>
                                    </MKBox>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Container>
            </MKBox>
            <MKBox component="section" pb={5} style={{background: '#f0f2f5'}}>
                <Container>
                    <Grid container spacing={5} alignItems="center">
                        <Grid item xs={12} lg={12} sx={{ml: '', mt: {xs: 3, lg: 10}, mb: {xs: 3, lg: 5}}}>
                            <DefaultInfoCard
                                type="text" title="Lorem" description="Lorem ipsom"/>
                        </Grid>
                    </Grid>

                    <Grid container spacing={5} alignItems="center">
                        <Grid item xs={12} lg={3} md={6} sx={{ml: '', mb: {xs: 0, lg: 3}}}>
                            <div className="card-portfolio-details">
                                {/*<h2 className="card-portfolio-details-header">Team</h2>*/}
                                {/*<p className="card-detail">Backend Developer</p>*/}
                                {/*<p className="card-detail">Database Administrator</p>*/}
                                {/*<p className="card-detail">Frontend Developer</p>*/}
                                {/*<p className="card-detail">Quality Assurance Engineer</p>*/}
                                {/*<p className="card-detail">Project Manager</p>*/}
                                {/*<p className="card-detail">UI/UX Designer</p>*/}
                            </div>
                        </Grid>
                        <Grid item xs={12} lg={3} md={6} sx={{ml: '', mb: {xs: 0, lg: 3}}}>
                            <div className="card-portfolio-details">
                                {/*<h2 className="card-portfolio-details-header">Tech Stack:</h2>*/}
                                {/*<p className="card-detail">Angular</p>*/}
                                {/*<p className="card-detail">Java (Spring Boot)</p>*/}
                                {/*<p className="card-detail"> PostgreSQL</p>*/}
                            </div>
                        </Grid>
                        <Grid item xs={12} lg={3} md={6} sx={{ml: '', mb: {xs: 0, lg: 3}}}>
                            <div className="card-portfolio-details">
                                {/*<h2 className="card-portfolio-details-header">Target Audience</h2>*/}
                                {/*<p className="card-detail">Existing Patients</p>*/}
                                {/*<p className="card-detail">Potential Patients</p>*/}
                            </div>
                        </Grid>
                        <Grid item xs={12} lg={3} md={6} sx={{ml: '', mb: {xs: 0, lg: 3}}}>
                            <div className="card-portfolio-details">
                                {/*<h2 className="card-portfolio-details-header">Services</h2>*/}
                                {/*<p className="card-detail">Appointment Management</p>*/}
                                {/*<p className="card-detail">Patient Management</p>*/}
                                {/*<p className="card-detail">Reminders and Notifications</p>*/}
                                {/*<p className="card-detail">Doctor Availability</p>*/}
                                {/*<p className="card-detail">Seamless Integration </p>*/}
                            </div>
                        </Grid>
                    </Grid>
                </Container>
            </MKBox>
            <MKBox component="section" pb={5}>
                <Container>
                    <Grid container spacing={5} alignItems="center">
                        <Grid item xs={12} lg={5} sx={{ml: '', mt: {xs: 10, lg: 10}}}>
                            <img src={health_industry_1} className="image-portfolio-details"/>
                        </Grid>
                        <Grid item xs={12} lg={7}>
                            <Grid container justifyContent="flex-start">
                                <Grid item xs={12} md={12}>
                                    <MKBox>
                                        <DefaultInfoCard
                                            type="text"
                                            title="Opportunities"
                                            description="A small doctors' office aimed to improve the patient experience and simplify the appointment booking process by implementing an online platform. Their objective was to develop an application that enables patients to easily schedule doctor visits, view available time slots, and receive appointment confirmations. It was crucial for the application to be user-friendly, secure, and seamlessly integrate with their existing patient management system, ensuring efficient and effective operations."
                                        />
                                    </MKBox>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Container>
            </MKBox>
            <MKBox component="section" pb={5} style={{background: '#f0f2f5'}}>
                <Container>
                    <Grid container spacing={5} alignItems="center">
                        <Grid item xs={12} lg={12} sx={{ml: '', mt: {xs: 3, lg: 10}}}>
                            <DefaultInfoCard
                                type="text"
                                title="Our Solution"
                                description="By partnering with us, together we developed  a user-friendly application designed to empower patients and streamline the appointment booking process. Our team conducted thorough consultations with the client to understand their specific requirements and goals."
                            />
                        </Grid>
                    </Grid>
                    <Grid container spacing={5} alignItems="center">
                        <Grid item xs={12} lg={12} md={12} sx={{ml: '', mb: {xs: 0, lg: 3}}}>
                            <h1 className="portfolio-details-text-header">
                                The key outcomes of the project included
                            </h1>
                            <ul className="portfolio-details-text">
                                <li className="card-detail">
                  <span>
                    <b>Online Appointment Booking: </b>
                  </span>
                                    The application allowed patients to browse available time slots, select their
                                    preferred doctor, and book appointments conveniently from their preferred device.
                                </li>
                                <li className="card-detail">
                  <span>
                    <b>Patient Registration and Profile Management: </b>
                  </span>
                                    The application enabled new patients to register and create their profiles,
                                    capturing essential information such as medical history, allergies, and contact
                                    details.
                                </li>
                                <li className="card-detail">
                  <span>
                    <b>Appointment Reminders and Notifications:: </b>
                  </span>
                                    The application sent automated reminders and notifications to patients, ensuring
                                    they were informed about upcoming appointments, changes, or cancellations.
                                </li>
                                <li className="card-detail">
                  <span>
                    <b>Doctor Availability and Schedule Management: </b>
                  </span>
                                    The application provided doctors with an intuitive interface to manage their
                                    availability, update schedules, and block off time slots for specific purposes.
                                </li>
                                <li className="card-detail">
                  <span>
                    <b>Seamless Integration with Existing Patient Management System: </b>
                  </span>
                                    The application seamlessly integrated with the small doctors&apos; office&apos;s
                                    existing patient management system, allowing for efficient synchronization of
                                    appointments and patient data.
                                </li>
                                <li className="card-detail">
                  <span>
                    <b>Secure Data Handling and Privacy: </b>
                  </span>
                                    The application implemented strict security measures to ensure patient data
                                    privacy and compliance with healthcare regulations. Data encryption, access
                                    controls, and secure communication protocols were implemented to protect sensitive
                                    information.
                                </li>
                            </ul>
                        </Grid>
                    </Grid>

                    <Grid container spacing={5} alignItems="center">
                        <Grid item xs={12} lg={12} sx={{ml: '', mt: {xs: 3, lg: 3}}}>
                            <DefaultInfoCard
                                type="text"
                                title="Conclusion"
                                description="The collaboration resulted in the successful development of an intuitive
                                and secure application that transformed the appointment booking process for patients.
                                By leveraging a customized tech stack and incorporating user-friendly features, the application
                                empowered patients to conveniently schedule doctor visits, improved doctor-patient collaboration,
                                and enhanced overall patient experience. The seamless integration with the existing patient
                                management system streamlined administrative tasks and ensured accurate and up-to-date patient records.
                                The application positioned the small doctors' office as a modern and patient-centric
                                healthcare provider, offering a reliable and efficient platform to meet the evolving needs of their patients. "
                            />
                        </Grid>
                    </Grid>
                </Container>
            </MKBox>
        </>
    );
}

export default AppointmentBookingCaseStudyDetails;
