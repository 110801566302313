/*
=========================================================
* Material Kit 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';

// Material Kit 2 React components
import MKBox from 'components/box';
import MKTypography from 'components/typography';

// Material Kit 2 React examples
// Images
import about_us_2 from '../../../../assets/images/about-page/about_us_2.jpeg';

// CSS
import './OurTeam.css';

import websiteNameByBrand from './../../../../utils/websiteNameByBrand';

function OurStory() {

  const brand = websiteNameByBrand();
  const story = {
    IDMAPS: 'At Innovative Data Mining and Analytics Partners (IDMAPs), we transcend the\n' +
        'traditional role of a data analytics company. We pride ourselves on being your\n' +
        'dedicated partner, empowering your organization to make informed decisions swiftly.\n' +
        'With our expertise in extracting actionable insights from data, streamlining and\n' +
        'consolidating diverse systems and information assets, and developing intelligent\n' +
        'application systems and platforms, we deliver comprehensive solutions tailored to your\n' +
        'needs. Our exceptional team comprises highly skilled professionals including Data\n' +
        'Engineers, Data Analysts, Data Scientists, Business Analysts, System Developers, and\n' +
        'Project Managers, ensuring top-notch execution at every step.',
    SHPRIVACY: 'At Safe Harbour Privacy Professionals Inc.(SHPRIVACY), we go beyond the conventional role of a data protection company. We are\n' +
        'committed to being your trusted partner in safeguarding your organization\'s data, enabling you to make\n' +
        'secure and informed decisions swiftly. With our expertise in protecting sensitive information, streamlining\n' +
        'compliance processes, and developing robust privacy management systems, we offer comprehensive solutions tailored\n' +
        'to your needs. Our exceptional team of Data Privacy Officers, Compliance Experts, Cybersecurity Analysts, System\n' +
        'Developers, and Project Managers ensures that your data is protected with the highest standards of security\n' +
        'and integrity.'
  }
  return (
    <MKBox
      component="section"
      variant="gradient"
      bgColor="dark"
      position="relative"
      py={6}
      px={{ xs: 2, lg: 0 }}
      mx={-2}
    >
      <Container>
        <Grid container>
          <Grid item xs={12} md={12} lg={6} sx={{ mb: 6 }}>
            <MKTypography
              variant="h3"
              color="white"
              style={{ marginBottom: '20px', marginTop: '30px', fontSize: '25px' }}
            >
              Our Story
            </MKTypography>
            <MKTypography variant="body2" color="white" opacity={0.8} style={{ fontSize: '18px' }}>
              { story[brand.title] }
            </MKTypography>
          </Grid>
          <Grid item xs={4} lg={6} sx={4}>
            <div className="our-story">
              <img src={about_us_2} alt="about us" />
            </div>
          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default OurStory;
