/*
=========================================================
* Material Kit 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
// @mui material components
import Card from '@mui/material/Card';
// Material Kit 2 React components
import MKBox from '../../components/box';

// Material Kit 2 React examples
import DefaultNavbar from '../../examples/Navbars/DefaultNavbar';
import DefaultFooter from '../../examples/Footers/DefaultFooter';

// Routes
import {premRoutes} from '../../routes';
import footerRoutes from '../../footer.routes';

//  User Profile Sections
import PMTemp from './sections/PMTemp';
import {useIsAuthenticated} from "@azure/msal-react";
import {useUser} from "../../utils/UserContext";
import React from "react";
import ProfileImage from "../../utils/navBarProfileImage";

function PMTemplate() {
    const isAuthenticated = useIsAuthenticated();
    const { profile, profileImage, oneDriveFiles, sharePointTemplateFiles } = useUser();

    if (!isAuthenticated) {
        return <div>You are not authorized to view this page. Please log in.</div>;
    }

    return (
        <>
            <DefaultNavbar
                routes={premRoutes}
                action={{
                    type: 'internal',
                    route: '/pages/authentication/sign-in',
                    label: 'Sign In',
                    color: 'info',
                }}
                profileImage={profileImage && (
                    <ProfileImage imageUrl={profileImage} name={profile?.name} />
                )}
            />
            <MKBox
                minHeight="20vh"
                width="100%"
                sx={{
                    backgroundSize: 'cover',
                    backgroundPosition: 'center',
                    display: 'grid',
                    placeItems: 'center',
                    marginBottom: '30px'
                }}
            ></MKBox>
            <Card
                sx={{
                    p:2,
                    mt: -8,
                    mb: 4,
                    mx: {xs:2, lg:3},
                    boxShadow: ({ boxShadows: { xxl }}) => xxl,
                }}
            >
                <PMTemp
                    fetchData={sharePointTemplateFiles}
                    profile={profile}
                    oneDriveFiles={oneDriveFiles}
                />
            </Card>
            <MKBox pt={6} px={1} mt={6}>
                <DefaultFooter content={footerRoutes}/>
            </MKBox>
        </>
    );
}

export default PMTemplate;
