/*
=========================================================
* Material Kit 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';

// Material Kit 2 React components
import MKBox from 'components/box';
import DefaultInfoCard from '../../../../../examples/Cards/InfoCards/DefaultInfoCard';

//Images
import finance_industry_1 from '../../../../../assets/images/industry/finance_industry_1.jpeg';
import finance_industry_2 from '../../../../../assets/images/industry/finance_industry_2.jpeg';
import portfolio_details_4 from '../../../../../assets/images/software-development/portfolio_details_4.png';
import portfolio_details_3 from '../../../../../assets/images/software-development/portfolio_details_3.png';
import portfolio_details_1 from '../../../../../assets/images/software-development/portfolio_details_1.png';
import portfolio_details_5 from '../../../../../assets/images/software-development/portfolio_details_2.png';

// Css
import './LoanServiceCaseStudyDetails.css';

function LoanServiceCaseStudyDetails() {
    return (
        <>
            <MKBox component="section" pt={12} pb={5}>
                <Container>
                    <Grid container spacing={5} alignItems="center">
                        <Grid item xs={12} lg={5} sx={{ml: '', mt: {xs: 3, lg: 0}}}>
                            <img src={finance_industry_1} className="image-portfolio-details"/>
                        </Grid>
                        <Grid item xs={12} lg={7}>
                            <Grid container justifyContent="flex-start">
                                <Grid item xs={12} md={12}>
                                    <MKBox>
                                        <DefaultInfoCard
                                            type="text"
                                            title="Online Loan Services"
                                            description="This use case delves into an advanced loan service application designed to enhance online services and deliver a seamless loan application and management experience to customers. The focus is on expanding the range of services offered through a state-of-the-art application, ultimately providing a convenient and user-friendly platform for customers to apply for and manage their loans."
                                        />
                                        <div className="container">
                                            <div className="box-portfolio-details">
                                                <img src={portfolio_details_4} alt="Country"/>
                                                <p>
                                                    <b>Country:</b> Jamaica
                                                </p>
                                            </div>
                                            <div className="box-portfolio-details">
                                                <img src={portfolio_details_3} alt="platform"/>
                                                <p>
                                                    <b>Platform:</b> iOS , Web
                                                </p>
                                            </div>
                                            <div className="box-portfolio-details">
                                                <img src={portfolio_details_1} alt="industry"/>
                                                <p>
                                                    <b>Industry:</b> E-Commerce
                                                </p>
                                            </div>
                                            <div className="box-portfolio-details">
                                                <img src={portfolio_details_5} alt="duration"/>
                                                <p>
                                                    <b>Duration:</b> 2003-Present
                                                </p>
                                            </div>
                                        </div>
                                    </MKBox>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Container>
            </MKBox>
            <MKBox component="section" pb={5} style={{background: '#f0f2f5'}}>
                <Container>
                    <Grid container spacing={5} alignItems="center">
                        <Grid item xs={12} lg={12} sx={{ml: '', mt: {xs: 3, lg: 10}, mb: {xs: 3, lg: 5}}}>
                            <DefaultInfoCard title="Lorem" type="text" description="Lorem ipsom"/>
                        </Grid>
                    </Grid>

                    <Grid container spacing={5} alignItems="center">
                        <Grid item xs={12} lg={3} md={6} sx={{ml: '', mb: {xs: 0, lg: 3}}}>
                            <div className="card-portfolio-details">
                                {/*<h2 className="card-portfolio-details-header">Team</h2>*/}
                                {/*<p className="card-detail">Backend Developer</p>*/}
                                {/*<p className="card-detail">Database Administrator</p>*/}
                                {/*<p className="card-detail">Frontend Developer</p>*/}
                                {/*<p className="card-detail">Quality Assurance Engineer</p>*/}
                                {/*<p className="card-detail">Project Manager</p>*/}
                                {/*<p className="card-detail">UI/UX Designer</p>*/}
                            </div>
                        </Grid>
                        <Grid item xs={12} lg={3} md={6} sx={{ml: '', mb: {xs: 0, lg: 3}}}>
                            <div className="card-portfolio-details">
                                {/*<h2 className="card-portfolio-details-header">Tech Stack:</h2>*/}
                                {/*<p className="card-detail">React</p>*/}
                                {/*<p className="card-detail">Node.js</p>*/}
                                {/*<p className="card-detail">MongoDB</p>*/}
                            </div>
                        </Grid>
                        <Grid item xs={12} lg={3} md={6} sx={{ml: '', mb: {xs: 0, lg: 3}}}>
                            <div className="card-portfolio-details">
                                {/*<h2 className="card-portfolio-details-header">Target Audience</h2>*/}
                                {/*<p className="card-detail">Businesses </p>*/}
                                {/*<p className="card-detail">Individuals</p>*/}
                            </div>
                        </Grid>
                        <Grid item xs={12} lg={3} md={6} sx={{ml: '', mb: {xs: 0, lg: 3}}}>
                            <div className="card-portfolio-details">
                                {/*<h2 className="card-portfolio-details-header">Services</h2>*/}
                                {/*<p className="card-detail">Advanced Reporting and Analytics</p>*/}
                                {/*<p className="card-detail">Integration with External Systems</p>*/}
                                {/*<p className="card-detail">Loan Management Portal</p>*/}
                                {/*<p className="card-detail">Online Loan Applications</p>*/}
                                {/*<p className="card-detail">Security and Compliance</p>*/}
                                {/*<p className="card-detail">Workflow Automation</p>*/}
                            </div>
                        </Grid>
                    </Grid>
                </Container>
            </MKBox>
            <MKBox component="section" pb={5}>
                <Container>
                    <Grid container spacing={5} alignItems="center">
                        <Grid item xs={12} lg={5} sx={{ml: '', mt: {xs: 10, lg: 10}}}>
                            <img src={finance_industry_2} className="image-portfolio-details"/>
                        </Grid>
                        <Grid item xs={12} lg={7}>
                            <Grid container justifyContent="flex-start">
                                <Grid item xs={12} md={12}>
                                    <MKBox>
                                        <DefaultInfoCard
                                            title="Opportunities"
                                            type="text"
                                            description="A leading loan business, aimed to expand their services online and provide a seamless loan application and management experience to their customers. They sought a robust and user-friendly online loan application platform that would streamline the loan application process, automate workflows, and ensure efficient loan management. The application needed to have secure data handling, integration with external systems, and advanced reporting capabilities."
                                        />
                                    </MKBox>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Container>
            </MKBox>
            <MKBox component="section" pb={5} style={{background: '#f0f2f5'}}>
                <Container>
                    <Grid container spacing={5} alignItems="center">
                        <Grid item xs={12} lg={12} sx={{ml: '', mt: {xs: 3, lg: 10}}}>
                            <DefaultInfoCard
                                type="text"
                                title="Our Solution"
                                description="By partnering with us, together we developed an innovative online loan application and management platform designed specifically for the finance industry. Our team conducted in-depth discussions with the client to understand their specific requirements and goals."
                            />
                        </Grid>
                    </Grid>
                    <Grid container spacing={5} alignItems="center">
                        <Grid item xs={12} lg={12} md={12} sx={{ml: '', mb: {xs: 0, lg: 3}}}>
                            <h1 className="portfolio-details-text-header">
                                The key outcomes of the project included
                            </h1>
                            <ul className="portfolio-details-text">
                                <li className="card-detail">
                  <span>
                    <b>Streamlined Loan Application Process: </b>
                  </span>
                                    The application reduced manual paperwork and streamlined the loan application
                                    process, enabling borrowers to submit applications online easily.
                                </li>
                                <li className="card-detail">
                  <span>
                    <b>Improved Operational Efficiency: </b>
                  </span>
                                    The automation of loan application workflows and integration with external systems
                                    reduced manual efforts, minimized errors, and improved operational efficiency.
                                </li>
                                <li className="card-detail">
                  <span>
                    <b>Enhanced Borrower Experience: </b>
                  </span>
                                    The application provides a user-friendly borrower portal, allowing customers to
                                    track their loan applications, access loan details, make payments, and receive
                                    real-time updates, enhancing the overall borrower experience.
                                </li>
                                <li className="card-detail">
                  <span>
                    <b>Data-Driven Decision-Making: </b>
                  </span>
                                    The advanced reporting and analytics capabilities of the application empowered
                                    loan officers and administrators to gain insights into loan portfolios, monitor
                                    loan performance, and make informed decisions to optimize loan management.
                                </li>
                                <li className="card-detail">
                  <span>
                    <b>Secure Data Handling: </b>
                  </span>
                                    The application implemented has robust security measures to ensure the
                                    confidentiality and integrity of borrower data, building trust and credibility
                                    among borrowers.
                                </li>
                            </ul>
                        </Grid>
                    </Grid>

                    <Grid container spacing={5} alignItems="center">
                        <Grid item xs={12} lg={12} sx={{ml: '', mt: {xs: 3, lg: 3}}}>
                            <DefaultInfoCard
                                type="text"
                                title="Conclusion"
                                description="The collaboration resulted in the successful development of the application, an advanced online loan application and management platform. Through its seamless loan application process, automated workflows, borrower portal, integration with external systems, and advanced reporting capabilities, the application revolutionized the client's online loan services. By leveraging the power of technology and industry expertise, we empowered our client to expand their loan business online, improve operational efficiency, enhance borrower experience, and make data-driven decisions. The application positioned our client as a leader in the finance industry, enabling them to effectively serve their customers' loan needs in the digital era."
                            />
                        </Grid>
                    </Grid>
                </Container>
            </MKBox>
        </>
    );
}

export default LoanServiceCaseStudyDetails;
