import { LogLevel } from '@azure/msal-browser';

export const msalConfig = {
  auth: {
    clientId: "c4ff5f7b-19a3-42ea-b63e-90bfcc97eb4d",
    authority: "https://login.microsoftonline.com/35095520-fa73-4135-92b9-7fa61b54616d",
    redirectUri: window.location.origin,
  },
  cache: {
    cacheLocation: 'sessionStorage',
    storeAuthStateInCookie: false,
  },
  system: {
    loggerOptions: {
      loggerCallback: (level, message, containsPii) => {
        if (containsPii) {
          return;
        }
        switch (level) {
          case LogLevel.Error:
            console.error(message);
            return;
          case LogLevel.Info:
            console.info(message);
            return;
          case LogLevel.Verbose:
            console.debug(message);
            return;
          case LogLevel.Warning:
            console.warn(message);
            return;
          default:
            return;
        }
      },
    },
  },
};

export const loginRequest = {
  scopes: ["User.Read", "Files.Read.All", "Sites.Read.All", "Sites.ReadWrite.All", "User.ReadBasic.All", "User.Read.All"]
};

export const graphScope = {
  scopes: ["User.Read", "Files.Read.All", "Sites.Read.All"]
};

// Microsoft Graph Config for fetching user profile, manager, and SharePoint site data
export const graphConfig = {
  graphMeEndpoint: "https://graph.microsoft.com/v1.0/me",
  graphManagerEndpoint: "https://graph.microsoft.com/v1.0/me/manager", // To get the manager info
  graphManagerPhotoEndpoint: "https://graph.microsoft.com/v1.0/users/", // To get manager photo
};

export const siteConfig = {
  siteId: "idmaps.sharepoint.com,ebdc7cd0-dc94-427e-a668-3c59f82992e9,4fb0cd76-59fb-4340-b744-4e1cf1cdaabc", // Retrieved from Microsoft Graph and remains constant
  hostname: "idmaps.sharepoint.com", // Your SharePoint tenant hostname
};

export const apiConfig = {
  graphBaseUrl: "https://graph.microsoft.com/v1.0", // Microsoft Graph API Base URL
  profilePhotoUrl: "https://graph.microsoft.com/v1.0/me/photo/$value",
  oneDriveUrl: "https://graph.microsoft.com/v1.0/me/drive/root/children",
  BATemplatesUrl: "https://graph.microsoft.com/v1.0/sites/idmaps.sharepoint.com,ebdc7cd0-dc94-427e-a668-3c59f82992e9,4fb0cd76-59fb-4340-b744-4e1cf1cdaabc/drive/root:/PReM_API_Files/BA_Templates_API:/children",
  PMTemplatesUrl: "https://graph.microsoft.com/v1.0/sites/idmaps.sharepoint.com,ebdc7cd0-dc94-427e-a668-3c59f82992e9,4fb0cd76-59fb-4340-b744-4e1cf1cdaabc/drive/root:/PReM_API_Files/PM_Templates_API:/children",
  templateFolders: "https://graph.microsoft.com/v1.0/sites/idmaps.sharepoint.com,ebdc7cd0-dc94-427e-a668-3c59f82992e9,4fb0cd76-59fb-4340-b744-4e1cf1cdaabc/drive/root:/PReM_API_Files:/children",
  CMNTemplatesUrl: "https://graph.microsoft.com/v1.0/sites/idmaps.sharepoint.com,ebdc7cd0-dc94-427e-a668-3c59f82992e9,4fb0cd76-59fb-4340-b744-4e1cf1cdaabc/drive/root:/PReM_API_Files/Common_Templates_API:/children"
};

export const authScopes = {
  scopes: ["User.Read", "Files.Read.All", "Sites.Read.All", "Sites.ReadWrite.All"] // Scopes for Microsoft Graph
};

export const driveConfig = {
  driveId: "/drive/root:/PReM_API_Files", // If the drive (document library) doesn't change
};

export const handleDownloadURL = {
  downloadURL: "@microsoft.graph.downloadUrl"
};
