/*
=========================================================
* Material Kit 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';

// Material Kit 2 React components
import MKBox from '../../../components/box';

import '../../DataProtection/sections/DataProtection.css';

function Benefits() {
  return (
    <MKBox component="section" pt={5}>
      <Container>
        <Grid container spacing={5} alignItems="center">
          <Grid item xs={12} lg={12}>
            <Grid container justifyContent="flex-start">
              <Grid item xs={12} md={12}>
                <MKBox>
                  <div className="training-benefit">
                    <span className="number">4</span>
                    <span>Major Benefits of Data Protection</span>
                  </div>
                </MKBox>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} lg={6}>
            <Grid container justifyContent="flex-start">
              <Grid item xs={12} md={12}>
                <MKBox>
                  <div>
                    <span className="training-benefit-list">
                      <i className="fas fa-chart-line icon"></i> Enhanced Security
                    </span>
                  </div>
                  <div className="training-description">
                    <p>
                      Our data protection services implement cutting-edge security measures, including advanced encryption, firewalls, intrusion detection systems, and multi-factor authentication. These measures protect your data from breaches, cyberattacks, malware, and unauthorized access, ensuring that sensitive information remains secure and confidential.
                    </p>
                  </div>
                </MKBox>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} lg={6}>
            <Grid container justifyContent="flex-start">
              <Grid item xs={12} md={12}>
                <MKBox>
                  <div className="benefit">
                    <span className="training-benefit-list">
                      <i className="fas fa-bullseye icon"></i> Regulatory Compliance
                    </span>
                  </div>
                  <div className="training-description">
                    <p>
                      We ensure your organization adheres to stringent data protection regulations such as GDPR, CCPA, HIPAA, and more. Our services help you navigate complex compliance requirements, avoiding legal penalties and demonstrating your commitment to data privacy and security, which enhances your reputation and fosters customer trust.
                    </p>
                  </div>
                </MKBox>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid container spacing={3} alignItems="center" style={{ marginTop: '30px' }}>
          <Grid item xs={12} lg={6}>
            <Grid container justifyContent="flex-start">
              <Grid item xs={12} md={12}>
                <MKBox>
                  <div className="benefit">
                    <span className="training-benefit-list">
                      <i className="fas fa-brain icon"></i> Data Integrity
                    </span>
                  </div>
                  <div className="training-description">
                    <p>
                      Our services maintain the accuracy, consistency, and reliability of your data throughout its lifecycle. We use techniques like checksums, hashing, and regular backups to prevent data corruption and loss, ensuring that your data remains intact and reliable for decision-making and operations.
                    </p>
                  </div>
                </MKBox>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} lg={6}>
            <Grid container justifyContent="flex-start">
              <Grid item xs={12} md={12}>
                <MKBox>
                  <div className="benefit">
                    <span className="training-benefit-list">
                      <i className="fas fa-cogs icon"></i> Cost Savings
                    </span>
                  </div>
                  <div className="training-description">
                    <p>
                      Preventing data breaches and minimizing downtime can result in significant cost savings. Our services reduce the financial impact associated with data recovery, legal fees, and potential regulatory fines, providing a cost-effective solution for comprehensive data protection.
                    </p>
                  </div>
                </MKBox>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default Benefits;
