import React, { useEffect } from 'react';
import Modal from '@mui/material/Modal';
import MKBox from '../../../components/box';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import MKTypography from 'components/typography';
import premLandingPageGif from 'assets/images/user-profile/data_science.gif';
import PropTypes from 'prop-types';

function SplashScreenModal({ open, onClose }) {
    useEffect(() => {
        const timer = setTimeout(() => {
            onClose();
        }, 3000); // Close the modal after 3 seconds

        return () => clearTimeout(timer);
    }, [onClose]);

    return (
        <Modal
            open={open}
            onClose={onClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
            }}
        >
            <MKBox
                width="75%"
                height="75%" // Or you can use a fixed height like '80vh' if you want
                sx={{
                    backgroundImage: `url(${premLandingPageGif})`,
                    backgroundSize: 'cover',
                    backgroundPosition: 'center',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                    boxShadow: 24, // Elevation effect
                    borderRadius: 2, // Optional: to give rounded corners
                    backgroundColor: 'rgba(0,0,0,0.5)',
                }}
            >
                <Container>
                    <Grid container item xs={12} lg={7} justifyContent="center" mx="auto">
                        <MKTypography
                            variant="h1"
                            color="white"
                            textAlign="center"
                            mt={-6}
                            mb={6}
                            sx={({ breakpoints, typography: { size } }) => ({
                                [breakpoints.down('md')]: {
                                    fontSize: size['4xl'],
                                },
                                [breakpoints.down('sm')]: {
                                    fontSize: size['3xl'],
                                },
                            })}
                        >
                            IDMAPS Internal Tools
                        </MKTypography>
                        <MKTypography
                            variant="h4"
                            color="white"
                            textAlign="center"
                            mt={-6}
                            mb={5}
                            sx={({ breakpoints, typography: { size } }) => ({
                                [breakpoints.down('md')]: {
                                    fontSize: size['2xl'],
                                },
                                [breakpoints.down('sm')]: {
                                    fontSize: size['xl'],
                                },
                            })}
                        >
                            Empowering our team with the tools they need to thrive
                        </MKTypography>
                    </Grid>
                </Container>
            </MKBox>
        </Modal>
    );
}

SplashScreenModal.propTypes = {
    open: PropTypes.bool.isRequired, // The 'open' prop is required and should be a boolean
    onClose: PropTypes.func.isRequired, // The 'onClose' prop is required and should be a function
};

export default SplashScreenModal;
