import React from 'react';
import * as ReactDOMClient from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import App from 'App';
//import { PublicClientApplication } from '@azure/msal-browser';
//import { msalConfig } from './auth-config';
import 'assets/scss/paper-dashboard.scss?v=1.3.1';
//import { MsalProvider } from "@azure/msal-react";
import reportWebVitals from './reportWebVitals';
import {setFavicon} from './utils/setFavicon'; // Adjust path as needed

setFavicon();

document.addEventListener('DOMContentLoaded', setFavicon);


// // Initialize MSAL instance
// const msalInstance = new PublicClientApplication(msalConfig);
//
// // Check and set active account
// if (!msalInstance.getActiveAccount() && msalInstance.getAllAccounts().length > 0) {
//     console.log("Index:", "Check and set active account");
//     msalInstance.setActiveAccount(msalInstance.getAllAccounts()[0]);
// }

// Create root element
const container = document.getElementById('root');
const root = ReactDOMClient.createRoot(container);

// Render the application
root.render(
    // <React.StrictMode>
    //     <MsalProvider instance={msalInstance}>
            <BrowserRouter>
                <App />
            </BrowserRouter>
    //     </MsalProvider>
    // </React.StrictMode>
);

// Measure performance
reportWebVitals();
