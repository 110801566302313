/*
=========================================================
* Material Kit 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
// @mui material components
import Card from '@mui/material/Card';
// Material Kit 2 React components
import MKBox from '../../components/box';

// Material Kit 2 React examples
import DefaultNavbar from '../../examples/Navbars/DefaultNavbar';
import DefaultFooter from '../../examples/Footers/DefaultFooter';

// Routes
import routes from '../../routes';
import footerRoutes from '../../footer.routes';

//  User Profile Sections
import CreateTemplateBand from './sections/CreateTemplateBand';

function CreateTemplate() {
    return (
        <>
            <DefaultNavbar
                routes={routes.map((r) => r.type === 'nav' && r)}
                action={{
                    type: 'internal',
                    route: '/pages/authentication/sign-in',
                    label: 'Sign In',
                    color: 'info',
                }}
            />
            <MKBox
                minHeight="20vh"
                width="100%"
                sx={{
                    backgroundSize: 'cover',
                    backgroundPosition: 'center',
                    display: 'grid',
                    placeItems: 'center',
                }}
            ></MKBox>
            <Card
                sx={{
                    backgroundColor: '#fafafa',
                    p: 2,
                    mx: {xs: 2, lg: 3},
                    mt: -8,
                    mb: 4,
                    boxShadow: ({boxShadows: {xxl}}) => xxl,
                }}
            >
                <CreateTemplateBand/>
            </Card>
            <MKBox pt={6} px={1} mt={6}>
                <DefaultFooter content={footerRoutes}/>
            </MKBox>
        </>
    );
}

export default CreateTemplate;
