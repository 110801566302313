/*
=========================================================
* Material Kit 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';

// Material Kit 2 React components
import MKBox from 'components/box';

// Material Kit 2 React examples
import DefaultInfoCard from 'examples/Cards/InfoCards/DefaultInfoCard';
import CenteredBlogCard from 'examples/Cards/BlogCards/CenteredBlogCard';

//Image
import services_2 from '../../../../assets/images/services-page/services_2.jpeg';

// eslint-disable-next-line react/prop-types
export default function EnterpriseDataManagementBand({ disType }) {
  const dataPage = (
    <MKBox component="section" py={12}>
      <Container>
        <Grid container spacing={3} alignItems="center">
          <Grid item xs={12} lg={6} sx={{ mr: 'auto', mt: { xs: 3, lg: 0 } }}>
            <CenteredBlogCard
              image={services_2}
              title="EDM & Analytics Service"
              description="Unlock your business potential with IDMAPS's Enterprise Data Management (EDM)
              service. Leverage data-driven insights to make smarter decisions, drive growth, and stay ahead of the
              competition. Elevate your business to new heights."
              action={{
                type: 'internal',
                route: '/pages/service/enterprise-data-management-more',
                color: 'info',
                label: 'find out more',
              }}
            />
          </Grid>
          <Grid item xs={12} lg={5}>
            <Grid container justifyContent="flex-start">
              <Grid item xs={12} md={12}>
                <MKBox mb={5}>
                  <DefaultInfoCard
                      type=""
                    icon="public"
                    title="Enterprise Data Management (EDM)"
                    description="Enterprise Data Management (EDM) refer to the processes, technologies,
                    and strategies used by organizations to effectively manage and analyze their data assets.
                    It involves the collection, storage, integration, quality assurance, governance, and utilization
                    of data to derive insights, make informed decisions, and drive business growth."
                  />
                </MKBox>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );

  const servicePageInfo = (
    <MKBox component="section" pt={6}>
      <Container>
        <Grid container spacing={3} alignItems="center">
          <Grid item>
            <CenteredBlogCard
              image={services_2}
              title="Enterprise Data Management"
              description=""
              // Leverage data-driven insights, make smarter decisions, and stay ahead of the competition.
              //  We offer a wide range of services that span the entire data lifecycle, encompassing data acquisition,
              //  integration, storage, governance, and analytics."
              action={{
                type: 'internal',
                route: '/pages/service/enterprise-data-management-more',
                color: 'info',
                label: 'find out more',
              }}
            />
          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );

  return <div>{disType !== '' && disType === 'band' ? servicePageInfo : dataPage}</div>;
}
