/*
=========================================================
* Material Kit 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from '@mui/material/Container';
// import Grid from '@mui/material/Grid';
// Material Kit 2 React components
import MKBox from '../../../components/box';

// CSS
import '../../Training/sections/Training.css';
import Grid from '@mui/material/Grid';
import websiteNameByBrand from '../../../utils/websiteNameByBrand';

function AdvantagesAndDisadvantages() {

    const brand = websiteNameByBrand();

    return (
        <MKBox component="section" pb={5} style={{background: '#f0f2f5'}}>
            <Container>
                <Grid lg={12} md={12} container spacing={2} pt={12}>
                    <Grid
                        item
                        xs={12}
                        md={12}
                        lg={brand.title === "IDMAPS" ? 12 : 6}
                        alignItems="center">
                        <Grid justifyContent="flex-start">
                            <Grid lg={brand.title === "IDMAPS" ? 12 : ""} container>
                                <Grid lg={brand.title === "IDMAPS" ? 6 : ""}>
                            <MKBox>
                                <div className="training-benefit">
                                    <span>Advantages of Data Analytics</span>
                                </div>
                            </MKBox>
                                    <MKBox>
                                        <div className="training-description">
                                            <p><i className="fas fa-check data-analytics-icon-small"></i>Informed
                                                Decision-Making </p>
                                            <p><i className="fas fa-check data-analytics-icon-small"></i> Improved
                                                Efficiency
                                                and Productivity </p>
                                            <p><i className="fas fa-check data-analytics-icon-small"></i> Enhanced
                                                Customer
                                                Experience </p>
                                            <p><i className="fas fa-check data-analytics-icon-small"></i> Competitive
                                                Advantage
                                            </p>
                                        </div>
                                    </MKBox>
                                </Grid>
                                <Grid lg={brand.title === "IDMAPS" ? 6 : ""}>
                                    <MKBox>
                                            <div className="training-benefit">
                                            <span>Disadvantages of Data Analytics</span>
                                        </div>
                                    </MKBox>
                                    <MKBox>
                                        <div className="training-description">
                                            <p><i className="fas fa-times data-analytics-icon-red-small"></i> Missed
                                                Opportunities </p>
                                            <p><i className="fas fa-times data-analytics-icon-red-small"></i> Inaccurate
                                                Decision-Making </p>
                                            <p><i className="fas fa-times data-analytics-icon-red-small"></i> Lack of
                                                Adaptability </p>
                                            <p><i
                                                className="fas fa-times data-analytics-icon-red-small"></i> Inefficient
                                                Resource Allocation </p>
                                        </div>
                                    </MKBox>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    {brand.title === "SHPRIVACY" ?
                        <Grid item xs={12} md={12} lg={6}>
                            <Grid justifyContent="flex-start">
                                <MKBox>
                                    <div className="training-benefit">
                                        <span>Advantages of Data Analysis</span>
                                    </div>
                                </MKBox>
                                <MKBox>
                                    <div className="training-description">
                                        <p><i className="fas fa-check data-analytics-icon-small"></i>Informed
                                            Decision-Making </p>
                                        <p><i className="fas fa-check data-analytics-icon-small"></i> Identifies and
                                            optimizes inefficiencies </p>
                                        <p><i className="fas fa-check data-analytics-icon-small"></i> Enhance
                                            understanding of customer behavior </p>
                                        <p><i className="fas fa-check data-analytics-icon-small"></i> Predict and
                                            mitigate potential risks. </p>
                                    </div>
                                </MKBox>
                                <MKBox>
                                    <div className="training-benefit">
                                        <span>Disadvantages of Data Analysis</span>
                                    </div>
                                </MKBox>
                                <MKBox>
                                    <div className="training-description">
                                        <p><i className="fas fa-times data-analytics-icon-red-small"></i> Time-Consuming
                                        </p>
                                        <p><i className="fas fa-times data-analytics-icon-red-small"></i> Complexity
                                        </p>
                                        <p><i className="fas fa-times data-analytics-icon-red-small"></i> Lack of
                                            Adaptability </p>
                                        <p><i className="fas fa-times data-analytics-icon-red-small"></i> Data Overload
                                        </p>
                                    </div>
                                </MKBox>
                            </Grid>
                        </Grid>
                        : ""
                    }
                </Grid>
            </Container>
        </MKBox>
    );
}

export default AdvantagesAndDisadvantages;
