/*
=========================================================
* Material Kit 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';

// Material Kit 2 React components
import MKBox from 'components/box';

//Images
import finance_industry_3 from '../../../../../assets/images/industry/finance_industry_3.jpeg';
import finance_industry_4 from '../../../../../assets/images/industry/finance_industry_4.jpeg';
import portfolio_details_4 from '../../../../../assets/images/software-development/portfolio_details_4.png';
import portfolio_details_3 from '../../../../../assets/images/software-development/portfolio_details_3.png';
import portfolio_details_1 from '../../../../../assets/images/software-development/portfolio_details_1.png';
import portfolio_details_5 from '../../../../../assets/images/software-development/portfolio_details_2.png';

// Css
import './FinancialManagementCaseStudyDetails.css';
import DefaultInfoCard from '../../../../../examples/Cards/InfoCards/DefaultInfoCard';

function FinancialManagementCaseStudyDetails() {
    return (
        <>
            <MKBox component="section" pt={12} pb={5}>
                <Container>
                    <Grid container spacing={5} alignItems="center">
                        <Grid item xs={12} lg={5} sx={{ml: '', mt: {xs: 3, lg: 0}}}>
                            <img src={finance_industry_3} className="image-portfolio-details"/>
                        </Grid>
                        <Grid item xs={12} lg={7}>
                            <Grid container justifyContent="flex-start">
                                <Grid item xs={12} md={12}>
                                    <MKBox>
                                        <DefaultInfoCard
                                            type="text"
                                            title="Financial Management"
                                            description="This use case delves into an advanced loan service application designed to enhance online services and deliver a seamless loan application and management experience to customers. The focus is on expanding the range of services offered through a state-of-the-art application, ultimately providing a convenient and user-friendly platform for customers to apply for and manage their loans."
                                        />
                                        <div className="container">
                                            <div className="box-portfolio-details">
                                                <img src={portfolio_details_4} alt="Country"/>
                                                <p>
                                                    <b>Country:</b> Jamaica
                                                </p>
                                            </div>
                                            <div className="box-portfolio-details">
                                                <img src={portfolio_details_3} alt="platform"/>
                                                <p>
                                                    <b>Platform:</b> iOS , Web
                                                </p>
                                            </div>
                                            <div className="box-portfolio-details">
                                                <img src={portfolio_details_1} alt="industry"/>
                                                <p>
                                                    <b>Industry:</b> E-Commerce
                                                </p>
                                            </div>
                                            <div className="box-portfolio-details">
                                                <img src={portfolio_details_5} alt="duration"/>
                                                <p>
                                                    <b>Duration:</b> 2003-Present
                                                </p>
                                            </div>
                                        </div>
                                    </MKBox>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Container>
            </MKBox>
            <MKBox component="section" pb={5} style={{background: '#f0f2f5'}}>
                <Container>
                    <Grid container spacing={5} alignItems="center">
                        <Grid item xs={12} lg={12} sx={{ml: '', mt: {xs: 3, lg: 10}, mb: {xs: 3, lg: 5}}}>
                            <DefaultInfoCard
                                type="text" title="Lorem" description="Lorem ipsom"/>
                        </Grid>
                    </Grid>

                    <Grid container spacing={5} alignItems="center">
                        <Grid item xs={12} lg={3} md={6} sx={{ml: '', mb: {xs: 0, lg: 3}}}>
                            <div className="card-portfolio-details">
                                {/*<h2 className="card-portfolio-details-header">Team</h2>*/}
                                {/*<p className="card-detail">Backend Developer</p>*/}
                                {/*<p className="card-detail">Database Administrator</p>*/}
                                {/*<p className="card-detail">Frontend Developer</p>*/}
                                {/*<p className="card-detail">Quality Assurance Engineer</p>*/}
                                {/*<p className="card-detail">Project Manager</p>*/}
                                {/*<p className="card-detail">UI/UX Designer</p>*/}
                            </div>
                        </Grid>
                        <Grid item xs={12} lg={3} md={6} sx={{ml: '', mb: {xs: 0, lg: 3}}}>
                            <div className="card-portfolio-details">
                                {/*<h2 className="card-portfolio-details-header">Tech Stack:</h2>*/}
                                {/*<p className="card-detail">Angular</p>*/}
                                {/*<p className="card-detail">Java (Spring Boot)</p>*/}
                                {/*<p className="card-detail"> PostgreSQL</p>*/}
                            </div>
                        </Grid>
                        <Grid item xs={12} lg={3} md={6} sx={{ml: '', mb: {xs: 0, lg: 3}}}>
                            <div className="card-portfolio-details">
                                {/*<h2 className="card-portfolio-details-header">Target Audience</h2>*/}
                                {/*<p className="card-detail">Businesses </p>*/}
                                {/*<p className="card-detail">Individuals</p>*/}
                            </div>
                        </Grid>
                        <Grid item xs={12} lg={3} md={6} sx={{ml: '', mb: {xs: 0, lg: 3}}}>
                            <div className="card-portfolio-details">
                                {/*<h2 className="card-portfolio-details-header">Services</h2>*/}
                                {/*<p className="card-detail">Account Management</p>*/}
                                {/*<p className="card-detail">Seamless Transactions</p>*/}
                                {/*<p className="card-detail">Investment Tracking</p>*/}
                                {/*<p className="card-detail">Budgeting and Expense Management</p>*/}
                                {/*<p className="card-detail">Secure Authentication </p>*/}
                            </div>
                        </Grid>
                    </Grid>
                </Container>
            </MKBox>
            <MKBox component="section" pb={5}>
                <Container>
                    <Grid container spacing={5} alignItems="center">
                        <Grid item xs={12} lg={5} sx={{ml: '', mt: {xs: 10, lg: 10}}}>
                            <img src={finance_industry_4} className="image-portfolio-details"/>
                        </Grid>
                        <Grid item xs={12} lg={7}>
                            <Grid container justifyContent="flex-start">
                                <Grid item xs={12} md={12}>
                                    <MKBox>
                                        <DefaultInfoCard
                                            type="text"
                                            title="Opportunities"
                                            description="A leading financial institution, aimed to modernize their financial management processes and provide a comprehensive digital platform for their clients. They sought an application that could streamline account management, enable seamless transactions, and provide real-time access to financial information. The application needed to ensure data security, integrate with existing banking systems, and offer a user-friendly interface for both individual and corporate clients."
                                        />
                                    </MKBox>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Container>
            </MKBox>
            <MKBox component="section" pb={5} style={{background: '#f0f2f5'}}>
                <Container>
                    <Grid container spacing={5} alignItems="center">
                        <Grid item xs={12} lg={12} sx={{ml: '', mt: {xs: 3, lg: 10}}}>
                            <DefaultInfoCard
                                type="text"
                                title="Our Solution"
                                description="By partnering with us, together we developed an innovative financial management application tailored for the finance industry. Our team conducted extensive consultations with the client to understand their specific requirements and goals. "
                            />
                        </Grid>
                    </Grid>
                    <Grid container spacing={5} alignItems="center">
                        <Grid item xs={12} lg={12} md={12} sx={{ml: '', mb: {xs: 0, lg: 3}}}>
                            <h1 className="portfolio-details-text-header">
                                The key outcomes of the project included
                            </h1>
                            <ul className="portfolio-details-text">
                                <li className="card-detail">
                  <span>
                    <b>Streamlined Financial Management: </b>
                  </span>
                                    The application streamlines account management and transaction processes,
                                    providing clients with a user-friendly and efficient platform to manage their
                                    finances..
                                </li>
                                <li className="card-detail">
                  <span>
                    <b>Improved Accessibility and Convenience: </b>
                  </span>
                                    The application&apos;s real-time access to financial information, seamless
                                    transactions, and investment tracking features offered clients greater
                                    accessibility and convenience in managing their financial portfolios.
                                </li>
                                <li className="card-detail">
                  <span>
                    <b>Enhanced Data Security: </b>
                  </span>
                                    The application robust security measures ensured the privacy and integrity of
                                    client data, building trust and confidence in the application.
                                </li>
                                <li className="card-detail">
                  <span>
                    <b>Personalized Financial Insights: </b>
                  </span>
                                    The budgeting tools, expense management features, and investment tracking
                                    capabilities of FinTrack empowered clients to make informed financial decisions
                                    based on personalized insights and reports.
                                </li>
                                <li className="card-detail">
                  <span>
                    <b>Seamless Integration with Banking Systems: </b>
                  </span>
                                    The integration with existing banking systems enabled clients to link their
                                    accounts, perform transactions, and access consolidated financial information
                                    seamlessly.
                                </li>
                            </ul>
                        </Grid>
                    </Grid>

                    <Grid container spacing={5} alignItems="center">
                        <Grid item xs={12} lg={12} sx={{ml: '', mt: {xs: 3, lg: 3}}}>
                            <DefaultInfoCard
                                type="text"
                                title="Conclusion"
                                description="The collaboration resulted in the successful development of a cutting-edge financial management application. Through its user-friendly interface, real-time financial information, seamless transactions, investment tracking, and robust security measures, it transformed financial management for our clients and their customers. The application positioned Global Finance Solutions as a leader in digital financial management, offering a modern and efficient platform to meet the evolving needs of their clients in the digital era."
                            />
                        </Grid>
                    </Grid>
                </Container>
            </MKBox>
        </>
    );
}

export default FinancialManagementCaseStudyDetails;
