// utils/ProfileImage.js

import React from 'react';
import PropTypes from 'prop-types';

const ProfileImage = ({ imageUrl, name, size = 40 }) => (
    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        <img
            src={imageUrl}
            alt="Profile"
            style={{ width: `${size}px`, height: `${size}px`, borderRadius: '50%' }}
        />
        <p style={{ margin: '5px 0 0', fontSize: '12px', textAlign: 'center' }}>
            {name || 'N/A'}
        </p>
    </div>
);

ProfileImage.propTypes = {
    imageUrl: PropTypes.string.isRequired,
    name: PropTypes.string,
    size: PropTypes.number, // Allows customization of image size
};

export default ProfileImage;
