import React from 'react';

// @mui material components
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';

// Material Kit 2 React components
import MKBox from 'components/box';

// Material Kit 2 React examples
import DefaultInfoCard from 'examples/Cards/InfoCards/DefaultInfoCard';
import CenteredBlogCard from 'examples/Cards/BlogCards/CenteredBlogCard';

// Image
import services_jamaica_6 from '../../../../assets/images/services-page/services_jamaica_6.jpg';

// eslint-disable-next-line react/prop-types
export default function DataProtectionBand({ disType }) {
  const dataPage = (
    <MKBox component="section" py={12}>
      <Container>
        <Grid container spacing={3} alignItems="center">
          <Grid item xs={12} lg={6}>
            <Grid container justifyContent="flex-start">
              <Grid item xs={12} md={12}>
                <MKBox mb={5}>
                  <DefaultInfoCard
                    type=""
                    icon="public"
                    title="Data Protection"
                    description="Data Protection is a critical process that enables organizations to unlock valuable insights from their data. By meticulously inspecting, cleaning, transforming, and modeling data, organizations can derive meaningful information that supports informed decision-making. Through the application of statistical and analytical techniques, data analysis reveals patterns, trends, and relationships within datasets, providing a deeper understanding of business dynamics and customer behavior. These insights empower organizations to optimize operations, identify opportunities, mitigate risks, and make data-driven strategic choices. Data analysis plays a vital role in uncovering hidden opportunities, improving efficiency, and gaining a competitive edge in today's data-driven world."
                  />
                </MKBox>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} lg={6} sx={{ ml: 'auto', mt: { xs: 3, lg: 0 } }}>
            <CenteredBlogCard
              image={services_jamaica_6}
              title="Data Analytics Service"
              description="
As your trusted partner in Data Protection Services, we empower your business to unlock the full potential of your data. Our expert team of data scientists and analysts helps you gain valuable insights that drive growth and keep you ahead of the competition."
              action={{
                type: 'internal',
                route: '/pages/service/data-protection-more',
                color: 'info',
                label: 'find out more',
              }}
            />
          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );

  const servicePageInfo = (
    <MKBox component="section" pt={6}>
      <Container>
        <Grid container spacing={3} alignItems="center">
          <Grid item>
            <CenteredBlogCard
              image={services_jamaica_6}
              title="Data Protection"
              description=""
              // As your trusted partner in Data Analytics Services, we empower your business to unlock the full potential of your data. Our expert team of data scientists and analysts helps you gain valuable insights that drive growth and keep you ahead of the competition."
              action={{
                type: 'internal',
                route: '/pages/service/data-protection',
                color: 'info',
                label: 'find out more',
              }}
            />
          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );

  return <div>{disType !== '' && disType === 'band' ? servicePageInfo : dataPage}</div>;
}
