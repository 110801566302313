/*
=========================================================
* Material Kit 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';

// Material Kit 2 React components
import MKBox from 'components/box';

//Images
import ecommerce_industry_5 from '../../../../../assets/images/industry/ecommerce_industry_5.jpeg';
import ecommerce_industry_6 from '../../../../../assets/images/industry/ecommerce_industry_6.jpeg';
import portfolio_details_4 from '../../../../../assets/images/software-development/portfolio_details_4.png';
import portfolio_details_3 from '../../../../../assets/images/software-development/portfolio_details_3.png';
import portfolio_details_1 from '../../../../../assets/images/software-development/portfolio_details_1.png';
import portfolio_details_5 from '../../../../../assets/images/software-development/portfolio_details_2.png';

// Css
import './BeautySupplyCaseStudyDetails.css';
import DefaultInfoCard from '../../../../../examples/Cards/InfoCards/DefaultInfoCard';

function BeautySupplyCaseStudyDetails() {
    return (
        <>
            <MKBox component="section" pt={12} pb={5}>
                <Container>
                    <Grid container spacing={5} alignItems="center">
                        <Grid item xs={12} lg={5} sx={{ml: '', mt: {xs: 3, lg: 0}}}>
                            <img src={ecommerce_industry_5} className="image-portfolio-details"/>
                        </Grid>
                        <Grid item xs={12} lg={7}>
                            <Grid container justifyContent="flex-start">
                                <Grid item xs={12} md={12}>
                                    <MKBox>
                                        <DefaultInfoCard
                                            type="text"
                                            title="Beauty Supply"
                                            description="This use case delves into a cutting-edge application aimed at transforming the online shopping experience. The focus is on developing a user-friendly and intuitive platform that goes beyond simply offering a diverse product selection. By incorporating personalized recommendations, secure payment options, and efficient order management, this solution strives to cater to the distinct needs and preferences of contemporary consumers."
                                        />
                                        <div className="container">
                                            <div className="box-portfolio-details">
                                                <img src={portfolio_details_4} alt="Country"/>
                                                <p>
                                                    <b>Country:</b> Jamaica
                                                </p>
                                            </div>
                                            <div className="box-portfolio-details">
                                                <img src={portfolio_details_3} alt="platform"/>
                                                <p>
                                                    <b>Platform:</b> iOS , Web
                                                </p>
                                            </div>
                                            <div className="box-portfolio-details">
                                                <img src={portfolio_details_1} alt="industry"/>
                                                <p>
                                                    <b>Industry:</b> E-Commerce
                                                </p>
                                            </div>
                                            <div className="box-portfolio-details">
                                                <img src={portfolio_details_5} alt="duration"/>
                                                <p>
                                                    <b>Duration:</b> 2003-Present
                                                </p>
                                            </div>
                                        </div>
                                    </MKBox>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Container>
            </MKBox>
            <MKBox component="section" pb={5} style={{background: '#f0f2f5'}}>
                <Container>
                    <Grid container spacing={5} alignItems="center">
                        <Grid item xs={12} lg={12} sx={{ml: '', mt: {xs: 3, lg: 10}, mb: {xs: 3, lg: 5}}}>
                            <DefaultInfoCard
                                type="text" title="Lorem" description="Lorem ipsom"/>
                        </Grid>
                    </Grid>

                    <Grid container spacing={5} alignItems="center">
                        <Grid item xs={12} lg={3} md={6} sx={{ml: '', mb: {xs: 0, lg: 3}}}>
                            <div className="card-portfolio-details">
                                {/*<h2 className="card-portfolio-details-header">Team</h2>*/}
                                {/*<p className="card-detail">Project Manager</p>*/}
                                {/*<p className="card-detail">UI/UX Designer</p>*/}
                                {/*<p className="card-detail">Frontend Developer</p>*/}
                                {/*<p className="card-detail">Backend Developer</p>*/}
                                {/*<p className="card-detail">Quality Assurance Engineer</p>*/}
                            </div>
                        </Grid>
                        <Grid item xs={12} lg={3} md={6} sx={{ml: '', mb: {xs: 0, lg: 3}}}>
                            <div className="card-portfolio-details">
                                {/*<h2 className="card-portfolio-details-header">Tech Stack:</h2>*/}
                                {/*<p className="card-detail">React</p>*/}
                                {/*<p className="card-detail">Node.js</p>*/}
                                {/*<p className="card-detail">PostgreSQL</p>*/}
                            </div>
                        </Grid>
                        <Grid item xs={12} lg={3} md={6} sx={{ml: '', mb: {xs: 0, lg: 3}}}>
                            <div className="card-portfolio-details">
                                {/*<h2 className="card-portfolio-details-header">Target Audience</h2>*/}
                                {/*<p className="card-detail"> Beauty Enthusiasts </p>*/}
                                {/*<p className="card-detail">Online Shoppers</p>*/}
                            </div>
                        </Grid>
                        <Grid item xs={12} lg={3} md={6} sx={{ml: '', mb: {xs: 0, lg: 3}}}>
                            <div className="card-portfolio-details">
                                {/*<h2 className="card-portfolio-details-header">Services</h2>*/}
                                {/*<p className="card-detail">User-Friendly Interface</p>*/}
                                {/*<p className="card-detail">Product Catalog</p>*/}
                                {/*<p className="card-detail">Ordering and Checkout</p>*/}
                                {/*<p className="card-detail">Customer Support</p>*/}
                                {/*<p className="card-detail">Customer Reviews and Ratings</p>*/}
                                {/*<p className="card-detail">Order Tracking</p>*/}
                            </div>
                        </Grid>
                    </Grid>
                </Container>
            </MKBox>
            <MKBox component="section" pb={5}>
                <Container>
                    <Grid container spacing={5} alignItems="center">
                        <Grid item xs={12} lg={5} sx={{ml: '', mt: {xs: 10, lg: 10}}}>
                            <img src={ecommerce_industry_6} className="image-portfolio-details"/>
                        </Grid>
                        <Grid item xs={12} lg={7}>
                            <Grid container justifyContent="flex-start">
                                <Grid item xs={12} md={12}>
                                    <MKBox>
                                        <DefaultInfoCard
                                            type="text"
                                            title="Opportunities"
                                            description="A growing beauty supply store, aimed to expand its business and take it to the next level by creating an innovative e-commerce application. They wanted to provide customers with a seamless online shopping experience, offer a wide range of beauty products, and enhance customer engagement. The application needed to be visually appealing, user-friendly, and capable of handling a large inventory of products."
                                        />
                                    </MKBox>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Container>
            </MKBox>
            <MKBox component="section" pb={5} style={{background: '#f0f2f5'}}>
                <Container>
                    <Grid container spacing={5} alignItems="center">
                        <Grid item xs={12} lg={12} sx={{ml: '', mt: {xs: 3, lg: 10}}}>
                            <DefaultInfoCard
                                type="text"
                                title="Our Solution"
                                description="By partnering with us, together we developed an exceptional e-commerce application, BeautyConnect. Our team conducted thorough market research and worked closely with the client to understand their business goals and requirements."
                            />
                        </Grid>
                    </Grid>
                    <Grid container spacing={5} alignItems="center">
                        <Grid item xs={12} lg={12} md={12} sx={{ml: '', mb: {xs: 0, lg: 3}}}>
                            <h1 className="portfolio-details-text-header">
                                The key outcomes of the project included
                            </h1>
                            <ul className="portfolio-details-text">
                                <li className="card-detail">
                  <span>
                    <b>Increased Online Sales:</b>
                  </span>
                                    The application has user-friendly interface, personalized recommendations, and
                                    convenient ordering process contributed to a boost in online sales and revenue.
                                </li>
                                <li className="card-detail">
                  <span>
                    <b>Enhanced Customer Engagement: </b>
                  </span>
                                    The application&apos;s interactive features, customer reviews, and social media
                                    integration fostered customer engagement, loyalty, and brand advocacy.
                                </li>
                                <li className="card-detail">
                  <span>
                    <b>Improved Customer Experience: </b>
                  </span>
                                    The application provides a seamless and enjoyable shopping experience, allowing
                                    customers to explore a wide range of beauty products, access personalized
                                    recommendations, and benefit from promotions and discounts.
                                </li>
                                <li className="card-detail">
                  <span>
                    <b>Streamlined Operations: : </b>
                  </span>
                                    The application&apos;s order tracking, inventory management, and customer support
                                    features streamlined the store&apos;s operations, resulting in improved efficiency
                                    and customer service.
                                </li>
                                <li className="card-detail">
                  <span>
                    <b>Expanded Customer Reach:</b>
                  </span>
                                    The application&apos;s online presence expanded the store&apos;s customer reach
                                    beyond its physical location, enabling the business to tap into a broader market
                                    of beauty enthusiasts.
                                </li>
                            </ul>
                        </Grid>
                    </Grid>

                    <Grid container spacing={5} alignItems="center">
                        <Grid item xs={12} lg={12} sx={{ml: '', mt: {xs: 3, lg: 3}}}>
                            <DefaultInfoCard
                                type="text"
                                title="Conclusion"
                                description="The collaboration resulted in the successful development of an innovative e-commerce application for the beauty supply industry. Leveraging a customized tech stack, the application provided customers with an engaging and seamless online shopping experience. It's user-friendly interface, extensive product catalog, personalized recommendations, and convenient ordering process positioned the beauty supply store for growth and success in the competitive e-commerce landscape. By embracing digital transformation, Our client achieved increased sales, improved customer engagement, and expanded market reach, establishing itself as a trusted destination for beauty enthusiasts."
                            />
                        </Grid>
                    </Grid>
                </Container>
            </MKBox>
        </>
    );
}

export default BeautySupplyCaseStudyDetails;
