import React, { createContext, useContext, useState, useEffect } from 'react';
import PropTypes from "prop-types";
import { useMsal } from "@azure/msal-react";
import { fetchProfileAndFiles, getManagerInfo, fetchManagerPhoto } from "../utils/fetchProfileAndFiles";

// Create the context
const UserContext = createContext();

// Custom hook to use the context
export const useUser = () => useContext(UserContext);

// Create a provider component
export const UserProvider = ({ children }) => {
    const { instance, accounts } = useMsal();
    const [profile, setProfile] = useState(null);
    const [profileImage, setProfileImage] = useState(null);
    const [oneDriveFiles, setOneDriveFiles] = useState([]);
    const [BAFiles, setBaFiles] = useState([]);
    const [PMFiles, setPmFiles] = useState([]);
    const [sharePointTemplateFiles, setSharePointTemplateFiles] = useState([]);
    const [manager, setManager] = useState(null);
    const [managerPhoto, setManagerPhoto] = useState(null);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const loadUserData = async () => {
            if (accounts && accounts.length > 0) {
                const userProfile = accounts[0];
                try {
                    // Fetch profile and related data
                    const { profileImage, oneDriveFiles, baFiles, pmFiles, cmnFiles, sharePointTemplateFiles } = await fetchProfileAndFiles(instance, userProfile);

                    setProfile(userProfile);
                    setProfileImage(profileImage);
                    setOneDriveFiles(oneDriveFiles);
                    setBaFiles([...baFiles, ...cmnFiles]);
                    setPmFiles([...pmFiles, ...cmnFiles]);
                    setSharePointTemplateFiles(sharePointTemplateFiles);

                    // Fetch manager information
                    const managerInfo = await getManagerInfo(instance, accounts);
                    setManager(managerInfo);

                    if (managerInfo?.id) {
                        const managerPhotoUrl = await fetchManagerPhoto(instance, managerInfo.id);
                        setManagerPhoto(managerPhotoUrl);
                    }
                } catch (error) {
                    console.error("Error loading profile and files:", error);
                }
            }
            setLoading(false);
        };

        loadUserData();
    }, [accounts, instance]);

    return (
        <UserContext.Provider
            value={{
                profile,
                profileImage,
                oneDriveFiles,
                BAFiles,
                PMFiles,
                sharePointTemplateFiles,
                manager,
                managerPhoto,
                loading
            }}
        >
            {children}
        </UserContext.Provider>
    );
};

UserProvider.propTypes = {
    children: PropTypes.node.isRequired,
};