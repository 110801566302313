import React, { useEffect, useState, useCallback } from 'react';
import { Navigate, Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import { ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import '@fortawesome/fontawesome-free/css/all.min.css';
import theme from 'assets/theme';
import Presentation from 'layouts/pages/presentation';
import routes, { buttonRoutes, premRoutes } from 'routes';
import { loginRequest } from './auth-config';
import { useIsAuthenticated, useMsal } from '@azure/msal-react';
//import {UserProvider} from "./utils/UserContext";
import {modifyRoutesForAuthentication} from "./utils/modifyRoutes";
//import { QueryClient, QueryClientProvider } from 'react-query'

//const queryClient = new QueryClient()

const App = () => {
    const [routeType, setRouteType] = useState('prem');
    const { pathname } = useLocation();
    const { instance, inProgress } = useMsal();
    const isAuthenticated = useIsAuthenticated();
    const navigate = useNavigate();

    useEffect(() => {
        console.log('Scroll to top effect triggered');
        document.documentElement.scrollTop = 0;
        document.scrollingElement.scrollTop = 0;
    }, [pathname]);

    useEffect(() => {
        const handleAuthRedirect = async () => {
            try {
                if (inProgress === 'none') {
                    const response = await instance.handleRedirectPromise();
                    if (response) {
                        console.log("Redirect response:", response);
                    }
                } else {
                    console.log('MSAL operation in progress:', inProgress);
                }
            } catch (error) {
                console.error("Redirect error:", error);
            }
        };

        handleAuthRedirect();

        if (isAuthenticated) {
            console.log('User is authenticated, navigating to splash page');
            setRouteType('prem');
            if (pathname !== 'undefined') {
                if (pathname === '/login' || pathname === '/pages/user-profile' || pathname === '/presentation') {
                    navigate('/pages/user-profile');
                } else {
                    navigate(pathname.toString());
                }
            }

        }


    }, [isAuthenticated, navigate, instance, inProgress]);

    // Handle route changes (e.g., login/logout and route type settings)
    useEffect(() => {
        if (pathname === '/login' && !isAuthenticated && inProgress === 'none') {
            handleLogin();
        }

        if (pathname === '/logout') {
            handleLogout();
        }

        if (['/pages/user/ba-template', '/pages/user/pm-template', '/pages/user/pa-template', '/pages/user/user-profile', '/pages/SplashScreen', '/pages/user/gap-analysis'].includes(pathname)) {
            setRouteType('prem');
        }
    }, [pathname, isAuthenticated]);

    const handleLogin = async () => {
        console.log('handleLogin called');
        setRouteType('prem');
        if (inProgress !== 'none') {
            console.log('Login is already in progress');
            return;
        }
        try {
            // Initialize the MSAL instance
            await instance.initialize();

            await instance.loginRedirect({
                ...loginRequest,
                redirectUri: "/pages/user-profile"
            });
            console.log('Login redirect initiated');
        } catch (error) {
            setRouteType('nav');
            console.error('Login error:', error);
        }
    };

    const handleLogout = async () => {
        console.log('handleLogout called');
        setRouteType('nav');
        if (inProgress !== 'none') {
            console.log('Logout is already in progress');
            return;
        }
        try {
            if(isAuthenticated){
                await instance.logoutRedirect({
                    postLogoutRedirectUri: "/presentation"
                });
                console.log('Logout redirect initiated');
            } else {
                setRouteType('nav');
                navigate('/presentation');
            }

        } catch (error) {
            console.error('Logout error:', error);
        }
    };

    const filterRoutes = (allRoutes) =>
        allRoutes.filter(route =>
            !(route.route === '/login' && isAuthenticated) &&
            !(route.route === '/logout' && !isAuthenticated)
        );

    const getRoutes = useCallback((allRoutes) => {
        return filterRoutes(allRoutes).flatMap((route, index) => {
            if (route.collapse) {
                return getRoutes(route.collapse);
            }
            if (route.route) {
                return (
                    <Route
                        exact
                        path={route.route}
                        element={route.component}
                        key={route.key || `${route.route}-${index}`}
                    />
                );
            }
            return [];
        });
    }, [isAuthenticated]);

    // Modify routes based on authentication status
    const filteredRoutes = modifyRoutesForAuthentication(routes, isAuthenticated);
    const filteredButtonRoutes = modifyRoutesForAuthentication(buttonRoutes, isAuthenticated);
    const filteredPremRoutes = modifyRoutesForAuthentication(premRoutes, isAuthenticated);


    const routeSets = {
        nav: [...getRoutes(filteredRoutes), ...getRoutes(filteredButtonRoutes )],
        prem: [...getRoutes(filteredRoutes), ...getRoutes(filteredPremRoutes ), ...getRoutes(filteredButtonRoutes )],
        default: [...getRoutes(filteredRoutes), ...getRoutes(filteredButtonRoutes )],
    };

    return (
        // <QueryClientProvider client={queryClient}>
        //     <UserProvider>
                <ThemeProvider theme={theme}>
                    <CssBaseline />
                    <Routes>
                        {routeSets[routeType] || routeSets.default}
                        <Route path="/presentation" element={<Presentation />} />
                        <Route path="*" element={<Navigate to="/presentation" />} />
                    </Routes>
                </ThemeProvider>
        //     </UserProvider>
        // </QueryClientProvider>
    );
};

export default App;
