import React from 'react';
import {
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Checkbox,
  Table,
  TableBody,
  TableRow,
  TableCell,
  IconButton,
  Tooltip,
  Typography,
  Avatar,
  Divider,
  Box,
} from '@mui/material';
import { Edit, Close, Refresh } from '@mui/icons-material';
import defaultProfImg from '../../assets/images/user-profile/default-progile-image.jpeg';

const tasks = [
  {
    checked: true,
    img: defaultProfImg,
    text: 'Sign contract for "What are conference organizers afraid of?"',
  },
  {
    checked: false,
    img: defaultProfImg,
    text: 'Lines From Great Russian Literature? Or E-mails From My Boss?',
  },
  {
    checked: true,
    img: defaultProfImg,
    text: 'Using dummy content or fake information in the Web design process can result in products with unrealistic',
  },
  {
    checked: false,
    img: defaultProfImg,
    text: 'But I must explain to you how all this mistaken idea of denouncing pleasure',
  },
];

const TemplateListComp = () => {
  return (
    <Card sx={{ marginTop: '50px' }}>
      <CardHeader
        title="Tasks"
        // subheader="Backend development"
        titleTypographyProps={{ style: { fontWeight: 'bold', fontSize: '18px' } }}
        // subheaderTypographyProps={{ style: { fontSize: '14px' } }}
      />
      <CardContent>
        <Box sx={{ overflowX: 'auto' }}>
          <Table>
            <TableBody>
              {tasks.map((task, index) => (
                <TableRow key={index}>
                  <TableCell padding="checkbox">
                    <Checkbox defaultChecked={task.checked} />
                  </TableCell>
                  <TableCell>
                    <Avatar src={task.img} />
                  </TableCell>
                  <TableCell>
                    <Typography variant="body2">{task.text}</Typography>
                  </TableCell>
                  <TableCell align="right">
                    <Tooltip title="Edit Task">
                      <IconButton color="info">
                        <Edit />
                      </IconButton>
                    </Tooltip>
                    <Tooltip title="Remove">
                      <IconButton color="error">
                        <Close />
                      </IconButton>
                    </Tooltip>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Box>
      </CardContent>
      <Divider />
      <CardActions>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <Refresh sx={{ mr: 1 }} />
          <Typography variant="body2">Updated 3 minutes ago</Typography>
        </Box>
      </CardActions>
    </Card>
  );
};

export default TemplateListComp;
