export const modifyRoutesForAuthentication = (routes, isAuthenticated) => {
    return routes
        .map(route => {

            if (route.collapse) {
                route.collapse = modifyRoutesForAuthentication(route.collapse, isAuthenticated);
            }

            if (route.name === 'Sign In' && route.clicker === 'onWeb' && isAuthenticated) {
                return {
                    ...route,
                    name: 'Go Back to PReM',
                };
            }

            if (route.name === 'Sign Out' && route.clicker === 'onWeb' && !isAuthenticated) {
                return {
                    ...route,
                    name: '',
                    route: ''
                };
            }

        return route;
    }).filter(Boolean);
};