/*
=========================================================
* Material Kit 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';

// Material Kit 2 React components
import MKBox from 'components/box';

//Images
import ecommerce_industry_3 from '../../../../../assets/images/industry/ecommerce_industry_3.jpeg';
import ecommerce_industry_4 from '../../../../../assets/images/industry/ecommerce_industry_4.jpeg';
import portfolio_details_4 from '../../../../../assets/images/software-development/portfolio_details_4.png';
import portfolio_details_3 from '../../../../../assets/images/software-development/portfolio_details_3.png';
import portfolio_details_1 from '../../../../../assets/images/software-development/portfolio_details_1.png';
import portfolio_details_5 from '../../../../../assets/images/software-development/portfolio_details_2.png';

// Css
import './OnlineGroceryCaseStudyDetails.css';
import DefaultInfoCard from '../../../../../examples/Cards/InfoCards/DefaultInfoCard';

function OnlineGroceryCaseStudyDetails() {
    return (
        <>
            <MKBox component="section" pt={12} pb={5}>
                <Container>
                    <Grid container spacing={5} alignItems="center">
                        <Grid item xs={12} lg={5} sx={{ml: '', mt: {xs: 3, lg: 0}}}>
                            <img
                                src={ecommerce_industry_3}
                                className="image-portfolio-details"
                                alt="ecommerce image"
                            />
                        </Grid>
                        <Grid item xs={12} lg={7}>
                            <Grid container justifyContent="flex-start">
                                <Grid item xs={12} md={12}>
                                    <MKBox>
                                        <DefaultInfoCard
                                            type="text"
                                            title="Online Grocery"
                                            description="This use case delves into a cutting-edge application aimed at transforming the online shopping experience. The focus is on developing a user-friendly and intuitive platform that goes beyond simply offering a diverse product selection. By incorporating personalized recommendations, secure payment options, and efficient order management, this solution strives to cater to the distinct needs and preferences of contemporary consumers."
                                        />
                                        <div className="container">
                                            <div className="box-portfolio-details">
                                                <img src={portfolio_details_4} alt="Country"/>
                                                <p>
                                                    <b>Country:</b> Jamaica
                                                </p>
                                            </div>
                                            <div className="box-portfolio-details">
                                                <img src={portfolio_details_3} alt="platform"/>
                                                <p>
                                                    <b>Platform:</b> iOS , Web
                                                </p>
                                            </div>
                                            <div className="box-portfolio-details">
                                                <img src={portfolio_details_1} alt="industry"/>
                                                <p>
                                                    <b>Industry:</b> E-Commerce
                                                </p>
                                            </div>
                                            <div className="box-portfolio-details">
                                                <img src={portfolio_details_5} alt="duration"/>
                                                <p>
                                                    <b>Duration:</b> 2003-Present
                                                </p>
                                            </div>
                                        </div>
                                    </MKBox>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Container>
            </MKBox>
            <MKBox component="section" pb={5} style={{background: '#f0f2f5'}}>
                <Container>
                    <Grid container spacing={5} alignItems="center">
                        <Grid item xs={12} lg={12} sx={{ml: '', mt: {xs: 3, lg: 10}, mb: {xs: 3, lg: 5}}}>
                            <DefaultInfoCard
                                type="text" title="Lorem" description="Lorem ipsom"/>
                        </Grid>
                    </Grid>

                    <Grid container spacing={5} alignItems="center">
                        <Grid item xs={12} lg={3} md={6} sx={{ml: '', mb: {xs: 0, lg: 3}}}>
                            <div className="card-portfolio-details">
                                {/*<h2 className="card-portfolio-details-header">Team</h2>*/}
                                {/*<p className="card-detail">Project Manager</p>*/}
                                {/*<p className="card-detail">UI/UX Designer</p>*/}
                                {/*<p className="card-detail">Frontend Developer</p>*/}
                                {/*<p className="card-detail">Backend Developer</p>*/}
                                {/*<p className="card-detail">Quality Assurance Engineer</p>*/}
                            </div>
                        </Grid>
                        <Grid item xs={12} lg={3} md={6} sx={{ml: '', mb: {xs: 0, lg: 3}}}>
                            <div className="card-portfolio-details">
                                {/*<h2 className="card-portfolio-details-header">Tech Stack:</h2>*/}
                                {/*<p className="card-detail">React</p>*/}
                                {/*<p className="card-detail">Node.js</p>*/}
                                {/*<p className="card-detail">PostgreSQL</p>*/}
                            </div>
                        </Grid>
                        <Grid item xs={12} lg={3} md={6} sx={{ml: '', mb: {xs: 0, lg: 3}}}>
                            <div className="card-portfolio-details">
                                {/*<h2 className="card-portfolio-details-header">Target Audience</h2>*/}
                                {/*<p className="card-detail"> Modern shoppers with diverse needs and preferences</p>*/}
                                {/*<p className="card-detail">Busy individuals and families</p>*/}
                            </div>
                        </Grid>
                        <Grid item xs={12} lg={3} md={6} sx={{ml: '', mb: {xs: 0, lg: 3}}}>
                            <div className="card-portfolio-details">
                                {/*<h2 className="card-portfolio-details-header">Services</h2>*/}
                                {/*<p className="card-detail">Intuitive User Interface</p>*/}
                                {/*<p className="card-detail">Seamless Checkout Process</p>*/}
                                {/*<p className="card-detail">Customized Shopping Lists</p>*/}
                                {/*<p className="card-detail">Efficient Order Fulfillment</p>*/}
                                {/*<p className="card-detail">Delivery Tracking</p>*/}
                            </div>
                        </Grid>
                    </Grid>
                </Container>
            </MKBox>
            <MKBox component="section" pb={5}>
                <Container>
                    <Grid container spacing={5} alignItems="center">
                        <Grid item xs={12} lg={5} sx={{ml: '', mt: {xs: 10, lg: 10}}}>
                            <img
                                src={ecommerce_industry_4}
                                className="image-portfolio-details"
                                alt="ecommerce image"
                            />
                        </Grid>
                        <Grid item xs={12} lg={7}>
                            <Grid container justifyContent="flex-start">
                                <Grid item xs={12} md={12}>
                                    <MKBox>
                                        <DefaultInfoCard
                                            type="text"
                                            title="Opportunities"
                                            description="A leading retail shop, sought to expand its business and offer online grocery shopping services to meet the growing demand for convenience and accessibility. They aimed to develop a user-friendly e-commerce application that enables customers to browse and purchase groceries online, ensuring a seamless shopping experience and efficient order fulfillment."
                                        />
                                    </MKBox>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Container>
            </MKBox>
            <MKBox component="section" pb={5} style={{background: '#f0f2f5'}}>
                <Container>
                    <Grid container spacing={5} alignItems="center">
                        <Grid item xs={12} lg={12} sx={{ml: '', mt: {xs: 3, lg: 10}}}>
                            <DefaultInfoCard
                                type="text"
                                title="Our Solution"
                                description="By partnering with us, together we developed an innovative e-commerce application that brings the grocery shopping experience online. Our team conducted thorough consultations with the client to understand their specific requirements and objectives. Leveraging our expertise in e-commerce development, we implemented the following tech stack to build the application."
                            />
                        </Grid>
                    </Grid>
                    <Grid container spacing={5} alignItems="center">
                        <Grid item xs={12} lg={12} md={12} sx={{ml: '', mb: {xs: 0, lg: 3}}}>
                            <h1 className="portfolio-details-text-header">
                                The key outcomes of the project included
                            </h1>
                            <ul className="portfolio-details-text">
                                <li className="card-detail">
                  <span>
                    <b>Increased Customer Reach:</b>
                  </span>
                                    The application has enabled our to tap into the growing market of online grocery
                                    shoppers, expanding their customer base beyond physical store visitors and
                                    reaching a wider audience.
                                </li>
                                <li className="card-detail">
                  <span>
                    <b>Improved Customer Engagement and Satisfaction: </b>
                  </span>
                                    The intuitive user interface, personalized shopping lists, and smart
                                    recommendations provided a tailored and convenient shopping experience for
                                    customers. This led to increased engagement, repeat purchases, and enhanced
                                    customer satisfaction.
                                </li>
                                <li className="card-detail">
                  <span>
                    <b>Streamlined Operations: </b>
                  </span>
                                    The application&apos;s integration with inventory and order management systems
                                    streamlined the fulfillment process, reducing manual errors, improving operational
                                    efficiency, and enabling timely deliveries.
                                </li>
                                <li className="card-detail">
                  <span>
                    <b>Business Growth and Adaptability: </b>
                  </span>
                                    By offering online grocery services, ABC Supermarkets positioned themselves for
                                    business growth and adaptation to changing consumer preferences. GrocerEase
                                    provided a flexible platform to scale their operations and cater to evolving
                                    market demands.
                                </li>
                            </ul>
                        </Grid>
                    </Grid>

                    <Grid container spacing={5} alignItems="center">
                        <Grid item xs={12} lg={12} sx={{ml: '', mt: {xs: 3, lg: 3}}}>
                            <DefaultInfoCard
                                type="text"
                                title="Conclusion"
                                description="The collaboration resulted in the successful development an innovative e-commerce application that revolutionized online shopping. By leveraging the latest technologies and implementing user-centric design principles, the application delivered an exceptional user experience, personalized recommendations, and secure transactions. The application's seamless navigation, efficient order management, and personalized features contributed to increased customer engagement, sales growth, and improved customer satisfaction. The application empowered our client to stay ahead in the competitive e-commerce industry and deliver exceptional value to their customers."
                            />
                        </Grid>
                    </Grid>
                </Container>
            </MKBox>
        </>
    );
}

export default OnlineGroceryCaseStudyDetails;
