import React from 'react';
import Container from '@mui/material/Container';
import {
    Avatar,
    Box,
    Card,
    CardContent,
    CardHeader,
    Divider,
    Grid,
    IconButton,
    List,
    ListItem,
    ListItemAvatar,
    ListItemText,
    Typography,
} from '@mui/material';
import {Email as EmailIcon} from '@mui/icons-material';
import MKBox from '../../../components/box';
import PropTypes from "prop-types";
//import MKButton from "../../../components/button";

function UProfile({profileImage, profileInfo, manager, managerPhoto}) {
    const getInitials = (name) => {
        if (!name) return 'U'; // Default initial
        const initials = name.split(' ').map(n => n[0]).join('');
        return initials.substring(0, 2).toUpperCase();
    };

    return (
        <MKBox component="section" py={{xs: 0, lg: 6}} >
            <Container>
                <Grid container spacing={3} alignItems="center">
                    {/*<Grid item lg={4} md={12} sm={12}>*/}
                        <Grid item xs={12}>
                        {/*<MKBox mb={5}>*/}
                            <Card>
                                <Box
                                    component="img"
                                    src={require('assets/images/user-profile/data_science.gif')}
                                    alt="..."
                                    width="100%"
                                    height="150px"
                                    style={{objectFit: 'cover'}}
                                />
                                <CardContent>
                                    <Grid container spacing={3} alignItems="center">

                                        {/* User Profile Info */}
                                        <Grid item xs={12} md={6}>
                                            <Box
                                                display="flex"
                                                justifyContent="center"
                                                alignItems="center"
                                                mb={2}
                                                mt={-9}
                                                position="relative"
                                                zIndex={1}
                                            >
                                                {profileImage ? (
                                                    <img
                                                        src={profileImage}
                                                        alt="Profile"
                                                        style={{ width: '100px', height: '100px', borderRadius: '50%' }}
                                                    />
                                                ) : (
                                                    <Avatar
                                                        sx={{
                                                            width: 100,
                                                            height: 100,
                                                            bgcolor: 'gray',
                                                            fontSize: 24,
                                                        }}
                                                    >
                                                        {getInitials(profileInfo?.username)}
                                                    </Avatar>
                                                )}
                                            </Box>
                                            <Box textAlign="center" mt={4}>
                                                <Typography variant="h4">{profileInfo?.name || 'N/A'}</Typography>
                                                <Typography variant="body2" color="textSecondary">
                                                    {profileInfo?.username || 'N/A'}
                                                </Typography>
                                                <Typography variant="body2" mt={2}>
                                                    &ldquo;Don&lsquo;t let yesterday take up too much of today&ldquo;.
                                                </Typography>
                                            </Box>
                                        </Grid>

                                        {/* Manager Profile Info */}
                                        <Grid item xs={12} md={6}>
                                            <CardHeader
                                                title="Manager"
                                                titleTypographyProps={{ style: { fontWeight: 'bold', fontSize: '15px' } }}
                                            />
                                            <Divider style={{ height: '4px', backgroundColor: '#000', margin: '16px 0' }} />
                                            <CardContent>
                                                <List>
                                                    <ListItem>
                                                        <ListItemAvatar>
                                                            {managerPhoto ? (
                                                                <img
                                                                    src={managerPhoto}
                                                                    alt="Manager Profile"
                                                                    style={{ width: '100px', height: '100px', borderRadius: '50%' }}
                                                                />
                                                            ) : (
                                                                <Avatar
                                                                    sx={{
                                                                        width: 100,
                                                                        height: 100,
                                                                        bgcolor: 'gray',
                                                                        fontSize: 24,
                                                                    }}
                                                                >
                                                                    {getInitials(manager?.displayName)}
                                                                </Avatar>
                                                            )}
                                                        </ListItemAvatar>
                                                        <ListItemText
                                                            primary={manager?.displayName}
                                                            primaryTypographyProps={{
                                                                color: 'textSecondary',
                                                                fontSize: '22px',
                                                                fontWeight: 'bold',
                                                            }}
                                                            secondary={manager?.mail}
                                                            secondaryTypographyProps={{ color: 'textSecondary', fontSize: '15px' }}
                                                        />
                                                        <IconButton color="success">
                                                            <EmailIcon />
                                                        </IconButton>
                                                    </ListItem>
                                                </List>
                                            </CardContent>
                                        </Grid>

                                    </Grid>

                                    {/* Button Row */}
                                    {/*<Box mt={4}>*/}
                                    {/*    <Grid container spacing={2} justifyContent="space-around">*/}
                                    {/*        <Grid item>*/}
                                    {/*            <MKButton*/}
                                    {/*                variant="contained"*/}
                                    {/*                color="success"*/}
                                    {/*                startIcon={<i className="fas fa-book"></i>}*/}
                                    {/*                onClick={() => window.location.href = '/pages/user/ba-template'}*/}
                                    {/*            >*/}
                                    {/*                B.A. Template*/}
                                    {/*            </MKButton>*/}
                                    {/*            <Typography variant="body2" color="textSecondary" align="center">View list of BA Template</Typography>*/}
                                    {/*        </Grid>*/}

                                    {/*        <Grid item>*/}
                                    {/*            <MKButton*/}
                                    {/*                variant="contained"*/}
                                    {/*                color="success"*/}
                                    {/*                startIcon={<i className="fas fa-file-alt"></i>}*/}
                                    {/*                onClick={() => window.location.href = '/pages/user/pm-template'}*/}
                                    {/*            >*/}
                                    {/*                P.M. Template*/}
                                    {/*            </MKButton>*/}
                                    {/*            <Typography variant="body2" color="textSecondary" align="center">View list of PM Template</Typography>*/}
                                    {/*        </Grid>*/}

                                    {/*        <Grid item>*/}
                                    {/*            <MKButton*/}
                                    {/*                variant="contained"*/}
                                    {/*                color="success"*/}
                                    {/*                startIcon={<i className="fas fa-clipboard-list"></i>}*/}
                                    {/*                onClick={() => window.location.href = '/pages/service/need-more-work'}*/}
                                    {/*            >*/}
                                    {/*                Process Analyst Template*/}
                                    {/*            </MKButton>*/}
                                    {/*            <Typography variant="body2" color="textSecondary" align="center">Other templates</Typography>*/}
                                    {/*        </Grid>*/}

                                    {/*        <Grid item>*/}
                                    {/*            <MKButton*/}
                                    {/*                variant="contained"*/}
                                    {/*                color="success"*/}
                                    {/*                startIcon={<i className="fas fa-star"></i>}*/}
                                    {/*                onClick={() => window.location.href = '/pages/service/special-template'}*/}
                                    {/*            >*/}
                                    {/*                Create Your Own Template*/}
                                    {/*            </MKButton>*/}
                                    {/*            <Typography variant="body2" color="textSecondary" align="center">Special Templates List</Typography>*/}
                                    {/*        </Grid>*/}

                                    {/*        <Grid item>*/}
                                    {/*            <MKButton*/}
                                    {/*                variant="contained"*/}
                                    {/*                color="success"*/}
                                    {/*                startIcon={<i className="fas fa-folder-plus"></i>}*/}
                                    {/*                onClick={() => window.location.href = '/pages/user/gap-analysis'}*/}
                                    {/*            >*/}
                                    {/*                Gap Analysis*/}
                                    {/*            </MKButton>*/}
                                    {/*            <Typography variant="body2" color="textSecondary" align="center">Additional Templates</Typography>*/}
                                    {/*        </Grid>*/}
                                    {/*    </Grid>*/}
                                    {/*</Box>*/}
                                </CardContent>
                            </Card>
                    </Grid>

                </Grid>
            </Container>
        </MKBox>
    );
}

UProfile.propTypes = {
    profileImage: PropTypes.string, // Profile image should be a string (URL)
    profileInfo: PropTypes.shape({  // Profile info should be an object
        username: PropTypes.string,
        name: PropTypes.string,
    }),
    managerPhoto: PropTypes.string, // Profile image should be a string (URL)
    manager: PropTypes.shape({  // Profile info should be an object
        mail: PropTypes.string,
        displayName: PropTypes.string,
    }),
};

export default UProfile;
