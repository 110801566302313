// @mui material components
import Icon from '@mui/material/Icon';

// @mui icons
import AboutUs from 'layouts/pages/landing-pages/about-us';
import Support from 'layouts/pages/support';
import ContactUs from 'layouts/pages/contact-us';
import Presentation from 'layouts/pages/presentation';
import DataAnalyticsMorePage from 'layouts/pages/dataAnalyticsMorePage';
import DataProtectionMorePage from 'layouts/pages/dataProtectionMorePage';
import EnterpriseDataManagementMorePage from 'layouts/pages/enterpriseDataManagementMorePage';
import ManagedServicesAndStaffAugmentationMorePage from 'layouts/pages/managedServicesAndStaffAugmentationMorePage';
import TrainingMorePage from 'layouts/pages/trainingMorePage';
import SoftwareDevelopmentMorePage from 'layouts/pages/softwareDevelopmentMorePage';
import EnhancedLearningCaseStudy from './layouts/pages/portfolioIndustry/EducationCases/enhancedLearningCaseStudy';
import LearningCenterCaseStudy from './layouts/pages/portfolioIndustry/EducationCases/learningCenterCaseStudy';
import SmartSchoolCaseStudy from './layouts/pages/portfolioIndustry/EducationCases/smartSchoolCaseStudy';
import RetailSolutionCaseStudy from './layouts/pages/portfolioIndustry/EcommerceCases/retailSolutionCaseStudy';
import OnlineGroceryCaseStudy from './layouts/pages/portfolioIndustry/EcommerceCases/onlineGroceryCaseStudy';
import BeautySupplyCaseStudy from './layouts/pages/portfolioIndustry/EcommerceCases/beautySupplyCaseStudy';
import LoanServiceCaseStudy from './layouts/pages/portfolioIndustry/FinanceCases/loanServiceCaseStudy';
import FinancialManagementCaseStudy from './layouts/pages/portfolioIndustry/FinanceCases/financialManagementCaseStudy';
import PartnerDrawCaseStudy from './layouts/pages/portfolioIndustry/FinanceCases/partnerDrawCaseStudy';
import AppointmentBookingCaseStudy from './layouts/pages/portfolioIndustry/HealthCareCases/appointmentBookingCaseStudy';
import HealthMonitoringCaseStudy from './layouts/pages/portfolioIndustry/HealthCareCases/healthMonitoringCaseStudy';
import ConnectingPatientsandHealthcareProvidersCaseStudy
    from './layouts/pages/portfolioIndustry/HealthCareCases/connectingPatientsandHealthcareProvidersCaseStudy';
import TermsOfService from './layouts/pages/termsOfService';
//import Prem from './pages/Prem';
import Template from './pages/Template';
import BATemplate from './layouts/pages/baTemplate';
import PMTemplate from './layouts/pages/pmTemplate';
import PATemplate from './layouts/pages/paTemplate';
import CreateTemplate from './layouts/pages/createWorkDoc';
import UserProfile from "./layouts/pages/userProfile";
import websiteNameByBrand from './utils/websiteNameByBrand';
// import GapAnalysisReport from "./layouts/pages/GapAnalysisReport";
// import CreateGapAnalysis from "./layouts/pages/GapAnalysisCreate";

const brand = websiteNameByBrand();
const routes = [
    {
        type: 'nav',
        name: 'home',
        icon: <Icon>home</Icon>,
        route: '/pages/presentation',
        component: <Presentation/>,
    },
    {
        type: 'nav',
        name: 'services',
        icon: <Icon>build</Icon>,
        collapse: [
            {
                type: 'nav',
                name: 'Data Analytics',
                description: 'See all data services',
                dropdown: true,
                collapse: [
                    {
                        type: 'nav',
                        name: 'data analytics (more)',
                        route: '/pages/service/data-analytics-more',
                        component: <DataAnalyticsMorePage/>,
                    },
                ],
            },
            brand.title === 'SHPRIVACY' ?
                {
                    type: 'nav',
                    name: 'Data Protection',
                    description: 'See all data protection services',
                    dropdown: true,
                    collapse: [
                        {
                            type: 'nav',
                            name: 'Data Protection (more)',
                            route: '/pages/service/data-protection',
                            component: <DataProtectionMorePage />,
                        },
                    ]
                } : ''
            ,
            {
                type: 'nav',
                name: 'Enterprise Data Management',
                description: 'See all EDM services',
                dropdown: true,
                collapse: [
                    {
                        type: 'nav',
                        name: 'Enterprise Data Management (more)',
                        route: '/pages/service/enterprise-data-management-more',
                        component: <EnterpriseDataManagementMorePage/>,
                    },
                ],
            },
            {
                type: 'nav',
                name: 'Managed Services and Staff Augmentation',
                description: 'See all MSSA services',
                dropdown: true,
                collapse: [
                    {
                        type: 'nav',
                        name: 'Managed Services and Staff Augmentation (more)',
                        route: '/pages/service/managed-services-and-staff-augmentation',
                        component: <ManagedServicesAndStaffAugmentationMorePage/>,
                    },
                ],
            },
            {
                type: 'nav',
                name: 'Software Development',
                description: 'See all software development services',
                dropdown: true,
                collapse: [
                    {
                        type: 'nav',
                        name: 'Software Development',
                        route: '/pages/service/software-development-more',
                        component: <SoftwareDevelopmentMorePage/>,
                    },
                ],
            },
            {
                type: 'nav',
                name: 'Training',
                description: 'See all training services',
                dropdown: true,
                collapse: [
                    {
                        type: 'nav',
                        name: 'Training',
                        route: '/pages/service/training',
                        component: <TrainingMorePage/>,
                    },
                ],
            },
        ],
    },
    {
        type: 'nav',
        name: 'Portfolio Demo',
        icon: <Icon>work</Icon>,
        collapse: [
            {
                type: 'nav',
                name: 'Education',
                description: '',
                dropdown: true,
                collapse: [
                    {
                        type: 'nav',
                        name: 'Enhanced Learning',
                        route: '/pages/portfolio_industry/education/enhanced_learning_use_case',
                        component: <EnhancedLearningCaseStudy/>,
                    },
                    {
                        type: 'nav',
                        name: 'Learning Center',
                        route: '/pages/portfolio_industry/education/learning_center_use_case',
                        component: <LearningCenterCaseStudy/>,
                    },
                    {
                        type: 'nav',
                        name: 'Smart School',
                        route: '/pages/portfolio_industry/education/smart_school_use_case',
                        component: <SmartSchoolCaseStudy/>,
                    },
                ],
            },
            {
                type: 'nav',
                name: 'Ecommerce',
                description: '',
                dropdown: true,
                collapse: [
                    {
                        type: 'nav',
                        name: 'Retail Solution',
                        route: '/pages/portfolio_industry/ecommerce/retail_solution_use_case',
                        component: <RetailSolutionCaseStudy/>,
                    },
                    {
                        type: 'nav',
                        name: 'Online Grocery',
                        route: '/pages/portfolio_industry/ecommerce/online_grocery_use_case',
                        component: <OnlineGroceryCaseStudy/>,
                    },
                    {
                        type: 'nav',
                        name: 'Beauty Supply',
                        route: '/pages/portfolio_industry/ecommerce/Beauty_Supply_use_case',
                        component: <BeautySupplyCaseStudy/>,
                    },
                ],
            },
            {
                type: 'nav',
                name: 'Health Care',
                description: '',
                dropdown: true,
                collapse: [
                    {
                        type: 'nav',
                        name: 'Appointment Booking',
                        route: '/pages/portfolio_industry/health_care/appointment_booking_use_case',
                        component: <AppointmentBookingCaseStudy/>,
                    },
                    {
                        type: 'nav',
                        name: 'Health Monitoring',
                        route: '/pages/portfolio_industry/health_care/health_monitoring_use_case',
                        component: <HealthMonitoringCaseStudy/>,
                    },
                    {
                        type: 'nav',
                        name: 'Connecting Patients and Healthcare',
                        route:
                            '/pages/portfolio_industry/health_care/Connecting_patients_and_health_care_providers_use_case',
                        component: <ConnectingPatientsandHealthcareProvidersCaseStudy/>,
                    },
                ],
            },
            {
                type: 'nav',
                name: 'Finance',
                description: '',
                dropdown: true,
                collapse: [
                    {
                        type: 'nav',
                        name: 'loan service',
                        route: '/pages/portfolio_industry/finance/loan_service_use_case',
                        component: <LoanServiceCaseStudy/>,
                    },
                    {
                        type: 'nav',
                        name: 'financial management service',
                        route: '/pages/portfolio_industry/finance/financial_management_use_case',
                        component: <FinancialManagementCaseStudy/>,
                    },
                    {
                        type: 'nav',
                        name: 'partner Draw',
                        route: '/pages/portfolio_industry/finance/partner_draw_use_case',
                        component: <PartnerDrawCaseStudy/>,
                    },
                ],
            },
        ],
    },
    {
        type: 'nav',
        name: 'Support',
        icon: <Icon>support</Icon>,
        route: '/pages/support',
        component: <Support/>,
    },
    {
        type: 'nav',
        name: 'Contact Us',
        icon: <Icon>contacts</Icon>,
        route: '/pages/contact-us',
        component: <ContactUs/>,
    },
    {
        type: 'nav',
        name: 'About Us',
        icon: <Icon>info</Icon>,
        route: '/pages/landing-pages/about-us',
        component: <AboutUs/>,
    },
    //brand.title === 'SHPRIVACY' &&
    // {
    //     type: 'nav',
    //     name: 'PReM',
    //     icon: <Icon>settings</Icon>,
    //     collapse: [
    //         {
    //             type: 'nav',
    //             clicker: 'onWeb',
    //             name: 'Sign In',
    //             route: '/login',
    //         },
    //         {
    //             type: 'nav',
    //             clicker: 'onWeb',
    //             name: 'Sign Out',
    //             route: '/logout',
    //         },
    //     ],
    // },
];

export const buttonRoutes = [
    {
        type: 'but',
        name: 'enhanceLearning',
        route: '/pages/portfolio_industry/education/enhanced_learning_use_case',
        component: <EnhancedLearningCaseStudy/>,
    },
    {
        type: 'but',
        name: 'learningCentre',
        route: '/pages/portfolio_industry/education/learning_center_use_case',
        component: <LearningCenterCaseStudy/>,
    },
    {
        type: 'but',
        name: 'smartSchool',
        route: '/pages/portfolio_industry/education/smart_school_use_case',
        component: <SmartSchoolCaseStudy/>,
    },
    {
        type: 'but',
        name: 'retailSolution',
        route: '/pages/portfolio_industry/ecommerce/retail_solution_use_case',
        component: <RetailSolutionCaseStudy/>,
    },
    {
        type: 'but',
        name: 'onlineGrocery',
        route: '/pages/portfolio_industry/ecommerce/online_grocery_use_case',
        component: <OnlineGroceryCaseStudy/>,
    },
    {
        type: 'but',
        name: 'beautySupply',
        route: '/pages/portfolio_industry/ecommerce/beauty_supply_use_case',
        component: <BeautySupplyCaseStudy/>,
    },
    {
        type: 'but',
        name: 'appointmentBooking',
        route: '/pages/portfolio_industry/health_care/appointment_booking_use_case',
        component: <AppointmentBookingCaseStudy/>,
    },
    {
        type: 'but',
        name: 'healthMonitoring',
        route: '/pages/portfolio_industry/health_care/health_monitoring_use_case',
        component: <HealthMonitoringCaseStudy/>,
    },
    {
        type: 'but',
        name: 'ConnectingPatientsandHealthcareProviders',
        route:
            '/pages/portfolio_industry/health_care/Connecting_patients_and_health_care_providers_use_case',
        component: <ConnectingPatientsandHealthcareProvidersCaseStudy/>,
    },
    {
        type: 'but',
        name: 'loanService',
        route: '/pages/portfolio_industry/finance/loan_service_use_case',
        component: <LoanServiceCaseStudy/>,
    },
    {
        type: 'but',
        name: 'financialManagementService',
        route: '/pages/portfolio_industry/finance/financial_management_use_case',
        component: <FinancialManagementCaseStudy/>,
    },
    {
        type: 'but',
        name: 'partnerDraw',
        route: '/pages/portfolio_industry/finance/partner_draw_use_case',
        component: <PartnerDrawCaseStudy/>,
    },
    {
        type: 'but',
        name: 'termsOfService',
        route: '/pages/terms_of_service',
        component: <TermsOfService/>,
    },
];

export const premRoutes = [
    {
        type: 'nav',
        name: 'home',
        icon: <Icon>home</Icon>,
        route: '/pages/presentation',
        component: <Presentation/>,
    },
    {
        type: 'nav',
        name: 'Support',
        icon: <Icon>support</Icon>,
        route: '/pages/support',
        component: <Support/>,
    },
    {
        type: 'nav',
        name: 'PReM User Profile',
        icon: <Icon>menu_book</Icon>,
        route: '/pages/user-profile',
        component: <UserProfile/>,
    },
    // brand.title !== 'SHPRIVACY' &&
    // {
    //     type: 'nav',
    //     name: 'PReM Gap Analysis',
    //     icon: <Icon>bar_chart</Icon>,
    //     collapse: [
    //         {
    //             type: 'nav',
    //             name: 'All Gap Analysis',
    //             icon: <Icon>menu_book</Icon>,
    //             route: '/pages/user/gap-analysis',
    //             component: <GapAnalysisReport/>,
    //         },
    //         {
    //             type: 'nav',
    //             name: 'Create Gap Analysis',
    //             icon: <Icon>account_balance</Icon>,
    //             route: '/pages/user/create-gap-analysis',
    //             component: <CreateGapAnalysis/>,
    //         }
    //     ]
    // },
    {
        type: 'nav',
        name: 'PReM Templates',
        icon: <Icon>dashboard</Icon>,
        collapse: [
            {
                type: 'prem',
                name: 'Create Template',
                icon: <Icon>dashboard</Icon>,
                route: '/pages/user/create-template',
                component: <CreateTemplate/>,
            },
            {
                type: 'prem',
                name: 'Task List',
                icon: <Icon>dashboard</Icon>,
                route: '/pages/user/template',
                component: <Template/>,
            },
            {
                type: 'prem',
                name: 'BA Template',
                icon: <Icon>dashboard</Icon>,
                route: '/pages/user/ba-template',
                component: <BATemplate/>,
            },
            {
                type: 'prem',
                name: 'PM Template',
                icon: <Icon>dashboard</Icon>,
                route: '/pages/user/pm-template',
                component: <PMTemplate/>,
            },
            {
                type: 'prem',
                name: 'PA Template',
                icon: <Icon>dashboard</Icon>,
                route: '/pages/user/pa-template',
                component: <PATemplate/>,
            }
        ],
    },
    {
        type: 'nav',
        name: 'Sign',
        icon: <Icon>logout</Icon>,
        collapse: [
            {
                type: 'prem',
                clicker: 'onPrem',
                name: 'Sign Out',
                route: '/logout',
            },
        ],
    },
];

export default routes;
