/*
=========================================================
* Material Kit 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';

// Material Kit 2 React components
import MKBox from '../../components/box';
import MKTypography from '../../components/typography';

// Material Kit 2 React examples
import DefaultNavbar from '../../examples/Navbars/DefaultNavbar';
import DefaultFooter from '../../examples/Footers/DefaultFooter';

// Data Analytics page
import DataAnalytics from './sections/DataAnalytics';
import WhyUs from './sections/WhyUs';

// Routes
import routes from '../../routes';
import footerRoutes from '../../footer.routes';

// Images
import data_analytics_header_image from '../../assets/images/data-analytics-page/data_analytics_gif.gif';
import AdvantagesAndDisadvantages from './sections/AdvantagesAndDisadvantages';
import websiteNameByBrand from './../../utils/websiteNameByBrand';
function DataAnalyticsInfo() {
    const brand = websiteNameByBrand();
    return (
        <>
            <DefaultNavbar
                routes={routes.map((r) => r.type === 'nav' && r)}
                action={{
                    type: 'internal',
                    route: '/pages/authentication/sign-in',
                    label: 'Sign In',
                    color: 'default',
                }}
                transparent
                light
            />
            <MKBox
                minHeight="75vh"
                width="100%"
                sx={{
                    backgroundImage: ({functions: {linearGradient, rgba}, palette: {gradients}}) =>
                        `${linearGradient(
                            rgba(gradients.dark.main, 0.6),
                            rgba(gradients.dark.state, 0.6),
                        )}, url(${data_analytics_header_image})`,
                    backgroundSize: 'cover',
                    backgroundPosition: 'center',
                    display: 'grid',
                    placeItems: 'center',
                }}
            >
                <Container>
                    <Grid
                        container
                        item
                        xs={12}
                        lg={8}
                        justifyContent="center"
                        alignItems="center"
                        flexDirection="column"
                        sx={{mx: 'auto', textAlign: 'center'}}
                    >
                        <MKTypography
                            variant="h1"
                            color="white"
                            sx={({breakpoints, typography: {size}}) => ({
                                [breakpoints.down('md')]: {
                                    fontSize: size['3xl'],
                                },
                            })}
                        >
                            Unlock the Power of {brand.title === "IDMAPS" ? "Data Analytics" : "Data Analytics and Analysis"}
                        </MKTypography>
                        <MKTypography variant="body1" color="white" opacity={0.8} mt={1} mb={3}>
                            Welcome to {brand.title}, your partner in comprehensive {brand.title === "IDMAPS" ? "data analytics" : "data analytics and analysis"} services. We help you
                            harness the power of your data to make informed business decisions and drive growth.
                        </MKTypography>
                    </Grid>
                </Container>
            </MKBox>
            <Card
                sx={{
                    p: 2,
                    mx: {xs: 2, lg: 3},
                    mt: -8,
                    mb: 4,
                    boxShadow: ({boxShadows: {xxl}}) => xxl,
                }}
            >
                <DataAnalytics/>
                <WhyUs/>
                <AdvantagesAndDisadvantages/>
            </Card>
            <MKBox pt={6} px={1} mt={6}>
                <DefaultFooter content={footerRoutes}/>
            </MKBox>
        </>
    );
}

export default DataAnalyticsInfo;
