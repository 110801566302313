import React from 'react';
import TemplatePage from './TemplatePage';
import PropTypes from "prop-types";

export default function PMTemp({fetchData, profile}) {
    return (
        <TemplatePage
            title="PM Templates"
            fetchData={fetchData}
            profile={profile}
            buttonLabel="Create work document from Templates"
        />
    );
};

PMTemp.propTypes = {
    fetchData: PropTypes.array.isRequired,
    profile: PropTypes.object,
    oneDriveFiles: PropTypes.array
};

PMTemp.defaultProps = {
    profile: null,
    oneDriveFiles: []
};