/*
=========================================================
* Material Kit 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import React from 'react';

// @mui material components
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';

// Material Kit 2 React components
import MKBox from 'components/box';
import MKTypography from '../../../components/typography';
import RotatingCard from '../../../examples/Cards/RotatingCard';
import RotatingCardFront from '../../../examples/Cards/RotatingCard/RotatingCardFront';
import RotatingCardBack from '../../../examples/Cards/RotatingCard/RotatingCardBack';

//Image
import Qubole from './../../../assets/images/partners/partners_1.png';
import Microsoft from './../../../assets/images/partners/partners_2.png';
import Snowflake from './../../../assets/images/partners/partners_3.png';
import bgFront from '../../../assets/images/rotating-card-bg-front.jpeg';
import bgBack from '../../../assets/images/rotating-card-bg-back.jpeg';

function Partners() {
  return (
    <MKBox
      component="section"
      pt={5}
      pb={4}
      style={{ background: '#f0f2f5', borderRadius: '0.75' }}
    >
      <Container>
        <Grid
          container
          item
          xs={12}
          lg={8}
          justifyContent="center"
          alignItems="center"
          flexDirection="column"
          sx={{ mx: 'auto', textAlign: 'center', pt: '100px', pb: '50px' }}
        >
          <MKTypography
            variant="h1"
              //color="#344667"
            color="dark"
            sx={({ breakpoints, typography: { size } }) => ({
              [breakpoints.down('md')]: {
                fontSize: size['3xl'],
              },
            })}
          >
            Some Of Our Partners
          </MKTypography>
        </Grid>
        <Grid container item xs={12} spacing={3} alignItems="center">
          <Grid item xs={6} lg={3} md={3}>
            <RotatingCard>
              <RotatingCardFront image={bgFront} partnerImage={Qubole} color="dark" />
              <RotatingCardBack
                  title=""
                image={bgBack}
                description="Qubole is a simple, open, and secure Data Lake Platform for machine learning, streaming, and ad-hoc analytics."
                action={{
                  type: 'internal',
                  route: 'https://www.qubole.com/',
                  label: 'Learn More',
                }}
              />
            </RotatingCard>
          </Grid>
          <Grid item xs={6} lg={3} md={3}>
            <RotatingCard>
              <RotatingCardFront image={bgFront} partnerImage={Microsoft} color="dark" />
              <RotatingCardBack
                  title=""
                image={bgBack}
                description="Microsoft develop and market software, services, and hardware that deliver new opportunities and enhanced value to people's lives."
                action={{
                  type: 'internal',
                  route: 'https://www.microsoft.com/',
                  label: 'Learn More',
                }}
              />
            </RotatingCard>
          </Grid>
          <Grid item xs={6} lg={3} md={3}>
            <RotatingCard>
              <RotatingCardFront image={bgFront} partnerImage={Snowflake} color="dark" />
              <RotatingCardBack
                  title=""
                image={bgBack}
                description="Snowflake delivers the Data Cloud — a global network where thousands of organizations mobilize data with near-unlimited scale and performance."
                action={{
                  type: 'internal',
                  route: 'https://www.snowflake.com/',
                  label: 'Learn More',
                }}
              />
            </RotatingCard>
          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default Partners;
