import React from 'react';
import './PulsingBtn.css';
import PropTypes from "prop-types";
import TemplatePage from "../../PMTemplate/sections/TemplatePage";

export default function PATemp({fetchData, profile}) {
    return (
        <TemplatePage
            title="PA Templates"
            fetchData={fetchData}
            profile={profile}
            buttonLabel="Create work document from Templates"
        />
    );
};

PATemp.propTypes = {
    fetchData: PropTypes.array.isRequired,
    profile: PropTypes.object
};

PATemp.defaultProps = {
    profile: null,
    oneDriveFiles: []
};
