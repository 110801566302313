import { useState } from 'react';
import Container from '@mui/material/Container';
import Modal from '@mui/material/Modal';
import Divider from '@mui/material/Divider';
import Fade from '@mui/material/Fade';
import MKBox from 'components/box';
import MKButton from 'components/button';
import MKTypography from 'components/typography';
import PropTypes from 'prop-types';
import Backdrop from '@mui/material/Backdrop';
import websiteNameByBrand from 'utils/websiteNameByBrand';

function DataProtectionModal({ onAgree }) {
  const brand = websiteNameByBrand();
  const [showDataProtectionModal, setShowDataProtectionModal] = useState(true);
  const [isDataProtectionAgreed, setIsDataProtectionAgreed] = useState(0);

  const handleDataProtectionAgree = () => {
    setIsDataProtectionAgreed(1);
    setShowDataProtectionModal(false);
    console.log('user agree');
    onAgree(true);
  };

  const handleDataProtectionNotAgreed = () => {
    setIsDataProtectionAgreed(2);
    console.log('user disagrees');
    setShowDataProtectionModal(false);
    onAgree(false);
  };

  const handleDataProtectionCancel = () => {
    setIsDataProtectionAgreed(3);
    console.log('user cancel');
  };

  const handleCloseModal = () => {
    setShowDataProtectionModal(false);
    setIsDataProtectionAgreed(0); // Reset agreement state if modal is closed
  };
  return (
      <MKBox component="section">
        <Container>
          <Modal
              onClose={handleCloseModal}
              BackdropComponent={Backdrop}
              BackdropProps={{
                timeout: 500,
              }}
              open={showDataProtectionModal}
              sx={{ display: 'grid', placeItems: 'center' }}
          >
            <Fade in={showDataProtectionModal} timeout={500}>
              <MKBox
                  position="relative"
                  width="80%"
                  maxWidth="800px"
                  display="flex"
                  flexDirection="column"
                  borderRadius="lg"
                  bgColor="white"
                  p={3}
                  sx={{ overflowY: 'auto', maxHeight: '80vh', boxShadow: 'none'  }}
              >
                <MKBox display="flex" alignItems="center" justifyContent="space-between" mb={2}>
                  <MKTypography variant="h5" fontWeight="bold">
                    Data Protection Policy
                  </MKTypography>
                </MKBox>
                <Divider />
                <MKBox mt={2} px={2} py={1}>
                  {isDataProtectionAgreed === 0 ? (
                      <>
                        <MKBox mb={3}>
                          <MKTypography variant="h6" gutterBottom>
                            Introduction
                          </MKTypography>
                          <MKTypography variant="body2" color="textSecondary">
                            {brand.title} is committed to protecting the privacy and security of personal information in accordance with applicable data protection laws, including the Jamaican Data Protection Act [Insert any other relevant international regulations, such as GDPR]. This Data Protection Policy outlines our commitment to the responsible handling, processing, and protection of personal data collected by {brand.title} in the course of its business operations.
                          </MKTypography>
                        </MKBox>

                        <MKBox mb={3}>
                          <MKTypography variant="h6" gutterBottom>
                            Scope
                          </MKTypography>
                          <MKTypography variant="body2" color="textSecondary">
                            This policy applies to all employees, contractors, and third-party service providers who have access to personal data collected and processed by {brand.title}.
                          </MKTypography>
                        </MKBox>

                        <MKBox mb={3}>
                          <MKTypography variant="h6" gutterBottom>
                            Principles of Data Protection
                          </MKTypography>
                          <MKTypography variant="body2" color="textSecondary">
                            {brand.title} adheres to the following principles in its data protection practices:
                          </MKTypography>
                          <ul>
                            <li>
                              <MKTypography variant="body2" color="textSecondary">
                                <b>Lawfulness, Fairness, and Transparency:</b> Personal data is processed lawfully, fairly, and transparently, and individuals are informed of the purposes for which their data is collected and processed.
                              </MKTypography>
                            </li>
                            <li>
                              <MKTypography variant="body2" color="textSecondary">
                                <b>Purpose Limitation:</b> Personal data is collected for specified, explicit, and legitimate purposes and is not further processed in a manner incompatible with those purposes.
                              </MKTypography>
                            </li>
                            <li>
                              <MKTypography variant="body2" color="textSecondary">
                                <b>Data Minimization:</b> {brand.title} ensures that personal data processed is adequate, relevant, and limited to what is necessary for the purposes for which it is processed.
                              </MKTypography>
                            </li>
                            <li>
                              <MKTypography variant="body2" color="textSecondary">
                                <b>Accuracy:</b> {brand.title} takes reasonable steps to ensure that personal data is accurate, up-to-date, and corrected when necessary.
                              </MKTypography>
                            </li>
                            <li>
                              <MKTypography variant="body2" color="textSecondary">
                                <b>Storage Limitation:</b> Personal data is kept in a form that permits identification for no longer than is necessary for the purposes for which it is processed.
                              </MKTypography>
                            </li>
                            <li>
                              <MKTypography variant="body2" color="textSecondary">
                                <b>Integrity and Confidentiality:</b> {brand.title} ensures the security, integrity, and confidentiality of personal data through appropriate technical and organizational measures.
                              </MKTypography>
                            </li>
                          </ul>
                        </MKBox>

                        <MKBox mb={3}>
                          <MKTypography variant="h6" gutterBottom>
                            Data Collection and Processing
                          </MKTypography>
                          <MKTypography variant="body2" color="textSecondary">
                            {brand.title} collects and processes personal data for the following purposes:
                          </MKTypography>
                        </MKBox>

                        <MKBox mb={3}>
                          <MKTypography variant="h6" gutterBottom>
                            Data Subject Rights
                          </MKTypography>
                          <MKTypography variant="body2" color="textSecondary">
                            Individuals have the following rights regarding their personal data:
                          </MKTypography>
                          <ul>
                            <li>
                              <MKTypography variant="body2" color="textSecondary">
                                Right to access
                              </MKTypography>
                            </li>
                            <li>
                              <MKTypography variant="body2" color="textSecondary">
                                Right to rectification
                              </MKTypography>
                            </li>
                            <li>
                              <MKTypography variant="body2" color="textSecondary">
                                Right to erasure
                              </MKTypography>
                            </li>
                            <li>
                              <MKTypography variant="body2" color="textSecondary">
                                Right to restrict processing
                              </MKTypography>
                            </li>
                            <li>
                              <MKTypography variant="body2" color="textSecondary">
                                Right to data portability
                              </MKTypography>
                            </li>
                            <li>
                              <MKTypography variant="body2" color="textSecondary">
                                Right to object
                              </MKTypography>
                            </li>
                          </ul>
                          <MKTypography variant="body2" color="textSecondary">
                            Requests to exercise these rights should be submitted to [Specify Contact Information].
                          </MKTypography>
                        </MKBox>

                        <MKBox mb={3}>
                          <MKTypography variant="h6" gutterBottom>
                            Data Security
                          </MKTypography>
                          <MKTypography variant="body2" color="textSecondary">
                            {brand.title} implements appropriate technical and organizational measures to ensure the security of personal data, including but not limited to encryption, access controls, and regular security assessments.
                          </MKTypography>
                        </MKBox>

                        <MKBox mb={3}>
                          <MKTypography variant="h6" gutterBottom>
                            Data Breach Response
                          </MKTypography>
                          <MKTypography variant="body2" color="textSecondary">
                            In the event of a data breach, {brand.title} will promptly assess the situation, take appropriate measures to mitigate the breach, and comply with any legal obligations to notify affected individuals and authorities.
                          </MKTypography>
                        </MKBox>

                        <MKBox mb={3}>
                          <MKTypography variant="h6" gutterBottom>
                            International Data Transfers
                          </MKTypography>
                          <MKTypography variant="body2" color="textSecondary">
                            {brand.title} may transfer personal data internationally in compliance with applicable data protection laws. Adequate safeguards, such as standard contractual clauses or binding corporate rules, will be implemented to protect the data.
                          </MKTypography>
                        </MKBox>

                        <MKBox mb={3}>
                          <MKTypography variant="h6" gutterBottom>
                            Modifications
                          </MKTypography>
                          <MKTypography variant="body2" color="textSecondary">
                            This Policy constitutes the entire agreement between the parties with respect to your use of the Tools.
                          </MKTypography>
                        </MKBox>

                        <MKBox mb={3}>
                          <MKTypography variant="h6" gutterBottom>
                            Training and Awareness
                          </MKTypography>
                          <MKTypography variant="body2" color="textSecondary">
                            {brand.title} provides training to employees and contractors on data protection principles and practices to ensure awareness and compliance.
                          </MKTypography>
                        </MKBox>

                        <MKBox mb={3}>
                          <MKTypography variant="h6" gutterBottom>
                            Data Protection Officer
                          </MKTypography>
                          <MKTypography variant="body2" color="textSecondary">
                            {brand.title} has appointed a Data Protection Officer (DPO) responsible for overseeing data protection efforts. The DPO can be contacted at [Specify Contact Information].
                          </MKTypography>
                        </MKBox>

                        <MKBox mb={3}>
                          <MKTypography variant="h6" gutterBottom>
                            Review and Update
                          </MKTypography>
                          <MKTypography variant="body2" color="textSecondary">
                            This Data Protection Policy will be reviewed and updated regularly to ensure its continued relevance and compliance with applicable laws and regulations.
                          </MKTypography>
                        </MKBox>

                        <MKBox mb={3}>
                          <MKTypography variant="h6" gutterBottom>
                            Contact Information
                          </MKTypography>
                          <MKTypography variant="body2" color="textSecondary">
                            For inquiries or concerns related to this Data Protection Policy, please contact: [Your Company Contact Information]
                          </MKTypography>
                        </MKBox>
                      </>
                  ) : (
                      isDataProtectionAgreed === 3 && (
                          <MKBox mb={3}>
                            <MKTypography variant="h6" gutterBottom>
                              Notice
                            </MKTypography>
                            <MKTypography variant="body2" color="textSecondary">
                              I understand that you disagree with our policy, but in order to have access to the internal tools, you must agree to all of our terms and conditions.
                            </MKTypography>
                            <MKTypography variant="h6" mt={2}>
                              Are you sure you want to cancel?
                            </MKTypography>
                          </MKBox>
                      )
                  )}
                </MKBox>
                <Divider />
                <MKBox display="flex" justifyContent="space-between" p={1.5}>
                  {isDataProtectionAgreed === 0 ? (
                      <MKButton variant="contained" color="success" size="medium" onClick={handleDataProtectionAgree}
                      >
                        Yes, I have read, understand, and agree
                      </MKButton>
                  ) : (
                      <MKButton variant="contained" color="success" onClick={handleDataProtectionAgree}>
                        No, I want to accept the policy.
                      </MKButton>
                  )}

                  {isDataProtectionAgreed === 0 ? (
                      <MKButton variant="contained" size="small" color="primary" onClick={handleDataProtectionCancel}>
                        I do not agree
                      </MKButton>
                  ) : (
                      <MKButton variant="contained" color="primary" onClick={handleDataProtectionNotAgreed}>
                        Yes, I am sure
                      </MKButton>
                  )}
                </MKBox>
              </MKBox>
            </Fade>
          </Modal>
        </Container>
      </MKBox>
  );
}

DataProtectionModal.propTypes = {
  onAgree: PropTypes.func.isRequired,
};

export default DataProtectionModal;
