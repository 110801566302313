/*
=========================================================
* Material Kit 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';

// Material Kit 2 React components
import MKBox from 'components/box';

// CSS
import './AugmentedProficiencies.css';
import MKTypography from '../../../components/typography';
import websiteNameByBrand from './../../../utils/websiteNameByBrand';

function AugmentedProficiencies() {

  const brand = websiteNameByBrand();

  return (
    <MKBox component="section" pt={5} style={{ background: '#f0f2f5', borderRadius: '0.75' }}>
      <Container>
        <Grid
          container
          item
          xs={12}
          lg={8}
          justifyContent="center"
          alignItems="center"
          flexDirection="column"
          sx={{ mx: 'auto', textAlign: 'center', pt: '100px', pb: '50px' }}
        >
          <MKTypography
            variant="h1"
              //color="#344667"
            color="dark"
            sx={({ breakpoints, typography: { size } }) => ({
              [breakpoints.down('md')]: {
                fontSize: size['3xl'],
              },
            })}
          >
            Augmented Proficiencies
          </MKTypography>
          <MKTypography variant="body1"
              //color="#344667"
                        color="dark" opacity={0.8} mt={1} mb={1}>
            We excel in enhancing your team&apos;s capabilities with augmented proficiencies in top
            technologies.
          </MKTypography>
        </Grid>
        <Grid container spacing={5} alignItems="center">
          <Grid item xs={12} lg={12}>
            <Grid container justifyContent="flex-start">
              <Grid item xs={12} md={12}>
                <MKBox>
                  <div className="container-augmented-prof">
                    <div className="box-augmented-prof">
                      <div className="box-augmented-prof-img-container">
                        <img className="img" src={brand.logo} alt="IDMAPS logo"></img>
                      </div>
                    </div>
                    <div className="box-augmented-prof">
                      <i className="fas fa-user-tie"></i>
                      <p className="box-text-augmented-prof">Project Manager</p>
                    </div>
                    <div className="box-augmented-prof">
                      <i className="fas fa-user"></i>
                      <p className="box-text-augmented-prof">Product Owner</p>
                    </div>
                    <div className="box-augmented-prof">
                      <i className="fas fa-chart-line"></i>
                      <p className="box-text-augmented-prof">Business Analyst</p>
                    </div>
                    <div className="box-augmented-prof">
                      <i className="fas fa-chart-line"></i>
                      <p className="box-text-augmented-prof">Business Data Analyst</p>
                    </div>
                    <div className="box-augmented-prof">
                      <i className="fas fa-chart-bar"></i>
                      <p className="box-text-augmented-prof">Process Analyst</p>
                    </div>
                    <div className="box-augmented-prof">
                      <i className="fas fa-chart-bar"></i>
                      <p className="box-text-augmented-prof">Software QA Analyst</p>
                    </div>
                    <div className="box-augmented-prof">
                      <i className="fas fa-chart-bar"></i>
                      <p className="box-text-augmented-prof">Data Analyst</p>
                    </div>
                    <div className="box-augmented-prof">
                      <i className="fas fa-server"></i>
                      <p className="box-text-augmented-prof">Data Scientist</p>
                    </div>
                    <div className="box-augmented-prof">
                      <i className="fas fa-infinity"></i>
                      <p className="box-text-augmented-prof">Scrum Master</p>
                    </div>
                    <div className="box-augmented-prof">
                      <i className="fas fa-hammer"></i>
                      <p className="box-text-augmented-prof"> Solution Architect</p>
                    </div>
                    <div className="box-augmented-prof">
                      <i className="fas fa-code"></i>
                      <p className="box-text-augmented-prof"> Full Stack Developer</p>
                    </div>
                    <div className="box-augmented-prof">
                      <i className="fab fa-html5"></i>
                      <p className="box-text-augmented-prof">Front End Developer</p>
                    </div>
                    <div className="box-augmented-prof">
                      <i className="fas fa-database"></i>
                      <p className="box-text-augmented-prof">Back End Developer</p>
                    </div>
                    <div className="box-augmented-prof">
                      <i className="fas fa-cogs"></i>
                      <p className="box-text-augmented-prof">DevOps</p>
                    </div>
                    <div className="box-augmented-prof">
                      <i className="fas fa-server"></i>
                      <p className="box-text-augmented-prof">System Administrator</p>
                    </div>
                    <div className="box-augmented-prof">
                      <i className="fas fa-cogs"></i>
                      <p className="box-text-augmented-prof">Data Engineer</p>
                    </div>
                    <div className="box-augmented-prof">
                      <i className="fas fa-database"></i>
                      <p className="box-text-augmented-prof">Database Administrator</p>
                    </div>
                    <div className="box-augmented-prof">
                      <i className="fas fa-user-shield"></i>
                      <p className="box-text-augmented-prof">Data Protection Officer</p>
                    </div>
                    <div className="box-augmented-prof">
                      <i className="fas fa-chart-bar"></i>
                      <p className="box-text-augmented-prof">Business Intelligence Analyst</p>
                    </div>
                  </div>
                </MKBox>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default AugmentedProficiencies;
