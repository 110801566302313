import idmaps_logo from '../assets/images/logos/idmaps-logo-new.png';
import shppi_logo from '../assets/images/logos/safeharbour-logo.png';
import idmaps_favicon from '../assets/favicon/favicon.png';
import shppi_favicon from '../assets/images/logos/safeharbour-logo.png';

function websiteNameByBrand() {
    const hostName = window.location.origin;

    const brandMap = {
        IDMAPS: {
            logo: idmaps_logo,
            favicon: idmaps_favicon,
            title: 'IDMAPS',
            name: 'Innovative Data Mining Analytics Partners',
            tagline: 'Data Made Simple',
            styling: ({ breakpoints, typography: { size } }) => ({
                [breakpoints.down('md')]: {
                    fontSize: size['4xl'],
                },
                [breakpoints.down('sm')]: {
                    fontSize: size['3xl'],
                },
            }),
        },
        SHPRIVACY: {
            logo: shppi_logo,
            favicon: shppi_favicon,
            title: 'SHPRIVACY',
            name: 'Safe Harbour Privacy Professionals Inc.',
            tagline: 'Privacy Made Simple',
            styling: ({breakpoints}) => ({
                fontSize: '3rem',
                maxWidth: '100%',
                [breakpoints.down('md')]: {
                    fontSize: '1.5rem',
                    maxWidth: '100%',
                },
                [breakpoints.down('sm')]: {
                    fontSize: '1rem',
                    maxWidth: '100%',
                },
            }),
        }
    };

    const brandKey = hostName.includes('idmaps') || hostName.includes('localhost:3000')
        ? 'IDMAPS'
        : hostName.includes('shprivacy') || hostName.includes('localhost:3001')
            ? 'SHPRIVACY'
            : "";

    return brandMap[brandKey];
}

export default websiteNameByBrand;
