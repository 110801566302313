// @mui icons
import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
import YouTubeIcon from '@mui/icons-material/YouTube';
import LinkedInIcon from '@mui/icons-material/LinkedIn';

// Material Kit 2 React components
import MKTypography from 'components/typography';

// Images
import websiteNameByBrand from './utils/websiteNameByBrand';

const date = new Date().getFullYear();
const brand = websiteNameByBrand();

export default {
    brand: {
        name: brand.name,
        image: brand.logo,
        route: '/',
    },
    socials:
        brand.title === "IDMAPS" ?
            [
                {
                    icon: <FacebookIcon/>,
                    link: 'https://www.facebook.com/pages/category/Information-Technology-Company/Idmaps-102405328308455/',
                },
                {
                    icon: <InstagramIcon/>,
                    link: 'https://www.instagram.com/id_maps/?hl=en',
                },
                {
                    icon: <YouTubeIcon/>,
                    link: 'https://www.youtube.com/@IDMAPSInternational',
                },
                {
                    icon: <LinkedInIcon/>,
                    link: 'https://www.linkedin.com/company/idmaps-intl/?viewAsMember=true',
                }]
            :
            []
    ,
    menus: [
        {
            name: 'company',
            items: [
                {name: 'about us', href: '/pages/landing-pages/about-us'},
            ],
        },
        {
            name: 'help & support',
            items: [
                {name: 'contact us', href: '/pages/contact-us'},
            ],
        },
        {
            name: 'legal',
            items: [
                {name: 'terms of service', href: '/pages/terms_of_service'},
                {name: 'privacy policy', href: '/pages/privacy_policy'},
                {name: 'licenses (EULA)', href: ''},
            ],
        },
        {
            name: '',
            items: [],
        },
    ],
    copyright: (
        <MKTypography variant="button" fontWeight="regular">
            All rights reserved. Copyright &copy; {date} {brand.name}
            Partners ({brand.title})
        </MKTypography>
    ),
};
