import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
    Box,
    Grid,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TextField,
    TablePagination,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
} from '@mui/material';
import { useFilters, usePagination, useSortBy, useTable } from 'react-table';
import { matchSorter } from 'match-sorter';
import classnames from 'classnames';
import MKButton from "../../../components/button";
import {handleDownloadURL} from "../../../auth-config";

function DefaultColumnFilter({ column: { filterValue, preFilteredRows, setFilter } }) {
    const count = preFilteredRows.length;
    return (
        <TextField
            fullWidth
            size="small"
            placeholder={`Search ${count} records...`}
            value={filterValue || ''}
            onChange={(e) => setFilter(e.target.value || undefined)}
        />
    );
}

DefaultColumnFilter.propTypes = {
    column: PropTypes.shape({
        filterValue: PropTypes.string,
        preFilteredRows: PropTypes.array.isRequired,
        setFilter: PropTypes.func.isRequired,
    }).isRequired,
};

function fuzzyTextFilterFn(rows, id, filterValue) {
    return matchSorter(rows, filterValue, {
        keys: [
            (row) => {
                const value = row.values[id];
                return typeof value === 'object' ? (value.user ? value.user.displayName : '') : value;
            },
        ],
    });
}

fuzzyTextFilterFn.autoRemove = (val) => !val;

const customSort = (rowA, rowB, columnId) => {
    const valueA = rowA.values[columnId];
    const valueB = rowB.values[columnId];
    if (!valueA || !valueB) {
        return valueA ? 1 : -1;
    }
    return String(valueA).localeCompare(String(valueB));
};

function TemplateTable({ columns, data }) {
    const [openModal, setOpenModal] = useState(false);
    const [selectedFile, setSelectedFile] = useState(null);
    const [fileUrl, setFileUrl] = useState(null);

    const handleDownloadClick = (fileName, fileUrl) => {
        setSelectedFile(fileName);
        setFileUrl(fileUrl);
        setOpenModal(true);
    };

    const confirmDownload = async () => {
        console.log(`Downloading file: ${selectedFile}`);

        try {
            const response = await fetch(handleDownloadURL.downloadURL);
            const blob = await response.blob();
            const url = window.URL.createObjectURL(blob);

            const link = document.createElement('a');
            link.href = url;
            link.download = selectedFile;
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link); // Cleanup
        } catch (error) {
            console.error('Error downloading the file:', error);
        }

        setOpenModal(false);
    };

    const filterTypes = React.useMemo(
        () => ({
            fuzzyText: fuzzyTextFilterFn,
            text: (rows, id, filterValue) => rows.filter((row) => {
                const rowValue = row.values[id];
                return rowValue !== undefined
                    ? String(rowValue).toLowerCase().startsWith(String(filterValue).toLowerCase())
                    : true;
            }),
        }),
        []
    );

    const defaultColumn = React.useMemo(() => ({ Filter: DefaultColumnFilter }), []);
    const sortTypes = React.useMemo(() => ({ alphanumeric: customSort }), []);

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        page,
        prepareRow,
        state: { pageIndex, pageSize },
        setPageSize,
        gotoPage,
    } = useTable(
        {
            columns,
            data,
            defaultColumn,
            filterTypes,
            sortTypes,
            initialState: { pageSize: 10, pageIndex: 0 },
        },
        useFilters,
        useSortBy,
        usePagination
    );

    const handleChangePage = (event, newPage) => {
        if (newPage >= 0 && newPage < Math.ceil(data.length / pageSize)) {
            gotoPage(newPage);
        }
    };

    const handleChangeRowsPerPage = (event) => {
        setPageSize(Number(event.target.value));
        gotoPage(0);
    };

    return (
        <TableContainer sx={{ marginTop: '15px', padding: '5px' }} className="ReactTable -striped -highlight primary-pagination">
            <Table {...getTableProps()} className="rt-table">
                <TableHead className="rt-thead -header">
                    {headerGroups.map((headerGroup, index) => (
                        <TableRow key={index} {...headerGroup.getHeaderGroupProps()} className="rt-tr">
                            {headerGroup.headers.map((column, key) => (
                                <TableCell
                                    key={key}
                                    {...column.getHeaderProps(column.getSortByToggleProps())}
                                    className={classnames('rt-th rt-resizable-header', {
                                        '-cursor-pointer': headerGroup.headers.length - 1 !== key,
                                        '-sort-asc': column.isSorted && !column.isSortedDesc,
                                        '-sort-desc': column.isSorted && column.isSortedDesc,
                                    })}
                                    style={{
                                        textAlign: 'center',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                    }}
                                >
                                    <Grid className="rt-resizable-header-content">{column.render('Header')}</Grid>
                                    <Grid>
                                        {headerGroup.headers.length - 1 === key ? null : column.canFilter ? column.render('Filter') : null}
                                    </Grid>
                                </TableCell>
                            ))}
                        </TableRow>
                    ))}
                </TableHead>
                <TableBody {...getTableBodyProps()} className="rt-tbody">
                    {page.map((row, i) => {
                        prepareRow(row);
                        return (
                            <TableRow key={i} {...row.getRowProps()} className={classnames('rt-tr')}>
                                {row.cells.map((cell, cellIndex) => (
                                    <TableCell key={cellIndex} {...cell.getCellProps()} className="rt-td">
                                        {cell.column.id.toUpperCase() === 'ACTIONS' ? (
                                            <MKButton
                                                size="small"
                                                variant="contained"
                                                color="success"
                                                startIcon={<i className="fas fa-cloud-download-alt"></i>}
                                                onClick={() => handleDownloadClick(row.original.name || 'No name available', row.original.webUrl || '#')}
                                            >
                                                Download
                                            </MKButton>
                                        ) : (
                                            cell.render('Cell') || 'N/A'
                                        )}
                                    </TableCell>
                                ))}
                            </TableRow>
                        );
                    })}
                </TableBody>
            </Table>
            <Box mt={2}>
                <Grid container justifyContent="center">
                    <Grid item>
                        <TablePagination
                            component="div"
                            count={data.length}
                            page={pageIndex}
                            onPageChange={handleChangePage}
                            rowsPerPage={pageSize}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                            rowsPerPageOptions={[5, 10, 20, 50, 100]}
                        />
                    </Grid>
                </Grid>
            </Box>
            <Dialog open={openModal} onClose={() => setOpenModal(false)}>
                <DialogTitle>Confirm Download</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Are you sure you want to download the file: {selectedFile}?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setOpenModal(false)} color="primary">Cancel</Button>
                    <Button onClick={confirmDownload} color="primary">Download</Button>
                    {fileUrl && (
                        <a href={fileUrl} target="_blank" rel="noopener noreferrer" >
                            <Button color="primary">Open in Browser</Button>
                        </a>
                    )}
                </DialogActions>
            </Dialog>
        </TableContainer>
    );
}

TemplateTable.propTypes = {
    columns: PropTypes.array.isRequired,
    data: PropTypes.array.isRequired,
};

export default TemplateTable;
