/*
=========================================================
* Material Kit 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';

// Material Kit 2 React components
import MKBox from 'components/box';

// Material Kit 2 React examples
//Image
import software_development_10 from '../../../assets/images/software-development/software_development_10.png';
import software_development_11 from '../../../assets/images/software-development/software_development_11.png';
import software_development_12 from '../../../assets/images/software-development/software_development_12.png';
import software_development_13 from '../../../assets/images/software-development/software_development_13.png';
import software_development_14 from '../../../assets/images/software-development/software_development_14.png';
import software_development_15 from '../../../assets/images/software-development/software_development_15.png';
import software_development_16 from '../../../assets/images/software-development/software_development_16.png';
import software_development_17 from '../../../assets/images/software-development/software_development_17.jpeg';
import software_development_5 from '../../../assets/images/software-development/software_development_5.png';

// CSS
import './MobileApplication.css';
import DefaultInfoCard from '../../../examples/Cards/InfoCards/DefaultInfoCard';

function WebApplication() {
    return (
        <MKBox component="section" pt={12} pb={5} style={{background: '#f0f2f5'}}>
            <Container>
                <Grid container spacing={5} alignItems="center">
                    <Grid item xs={12} lg={5} sx={{ml: '', mt: {xs: 3, lg: 2}}}>
                        <img
                            src={software_development_17}
                            className="web-app-image"
                            alt="software development"
                        />
                    </Grid>
                    <Grid item xs={12} lg={7}>
                        <Grid container justifyContent="flex-start">
                            <Grid item xs={12} md={12}>
                                <MKBox>
                                    <DefaultInfoCard
                                        type="text"
                                        title="Web Application Develoment"
                                        description="We specializes in web application development, offering a comprehensive range
                    of services to create custom software applications that run on web browsers. Their process involves
                    gathering requirements, designing intuitive UI/UX, developing front-end and back-end components,
                    implementing secure databases, conducting thorough testing, deploying to production environments,
                    and providing ongoing maintenance and support. We ensure that our web applications are
                    scalable, responsive, visually appealing, and optimized for performance, while meeting the specific
                    needs and goals of their clients."
                                    />
                                    <div className="box-container-software-dev">
                                        <div className="box-bg-software-dev">
                                            <img className="box-img" src={software_development_16} alt="Angular"/>
                                            <p className="box-text-software-dev">Angular</p>
                                        </div>
                                        <div className="box-bg-software-dev">
                                            <img className="box-img" src={software_development_10} alt="Codeigniter"/>
                                            <p className="box-text-software-dev">Codeigniter</p>
                                        </div>
                                        <div className="box-bg-software-dev">
                                            <img className="box-img" src={software_development_12} alt="Laravel"/>
                                            <p className="box-text-software-dev">Laravel</p>
                                        </div>
                                        <div className="box-bg-software-dev">
                                            <img className="box-img" src={software_development_15} alt="Magento"/>
                                            <p className="box-text-software-dev">Magento</p>
                                        </div>
                                    </div>

                                    <div className="box-container-software-dev">
                                        <div className="box-bg-software-dev">
                                            <img src={software_development_11} alt="NodeJs"/>
                                            <p className="box-text-software-dev">NodeJs</p>
                                        </div>
                                        <div className="box-bg-software-dev">
                                            <img src={software_development_5} alt="React"/>
                                            <p className="box-text-software-dev">React</p>
                                        </div>
                                        <div className="box-bg-software-dev">
                                            <img src={software_development_13} alt="Shopify"/>
                                            <p className="box-text-software-dev">Shopify</p>
                                        </div>
                                        <div className="box-bg-software-dev">
                                            <img src={software_development_14} alt="Wordress"/>
                                            <p className="box-text-software-dev">Wordress</p>
                                        </div>
                                    </div>
                                </MKBox>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Container>
        </MKBox>
    );
}

export default WebApplication;
