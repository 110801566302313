/*
=========================================================
* Material Kit 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';

// Material Kit 2 React components
import MKBox from 'components/box';

//Images
import education_industry_3 from '../../../../../assets/images/industry/education_industry_3.png';
import education_industry_4 from '../../../../../assets/images/industry/education_industry_4.jpeg';
import portfolio_details_4 from '../../../../../assets/images/software-development/portfolio_details_4.png';
import software_development_3 from '../../../../../assets/images/software-development/portfolio_details_3.png';
import software_development_4 from '../../../../../assets/images/software-development/portfolio_details_1.png';
import software_development_5 from '../../../../../assets/images/software-development/portfolio_details_2.png';

// Css
import './LearningCenterCaseStudyDetails.css';
import DefaultInfoCard from '../../../../../examples/Cards/InfoCards/DefaultInfoCard';

function LearningCenterCaseStudyDetails() {
    return (
        <>
            <MKBox component="section" pt={12} pb={5}>
                <Container>
                    <Grid container spacing={5} alignItems="center">
                        <Grid item xs={12} lg={5} sx={{ml: '', mt: {xs: 3, lg: 0}}}>
                            <img src={education_industry_3} className="image-portfolio-details"/>
                        </Grid>
                        <Grid item xs={12} lg={7}>
                            <Grid container justifyContent="flex-start">
                                <Grid item xs={12} md={12}>
                                    <MKBox>
                                        <DefaultInfoCard
                                            type="text"
                                            title="Learning Center"
                                            description="In this use case, we explore the development of a cutting-edge mobile application. This application provides an enhanced learning experience for children through a customized educational application"
                                        />
                                        <div className="container">
                                            <div className="box-portfolio-details">
                                                <img src={portfolio_details_4} alt="Country"/>
                                                <p>
                                                    <b>Country:</b> Jamaica
                                                </p>
                                            </div>
                                            <div className="box-portfolio-details">
                                                <img src={software_development_3} alt="Android"/>
                                                <p>
                                                    <b>Platform:</b> iOS , Web
                                                </p>
                                            </div>
                                            <div className="box-portfolio-details">
                                                <img src={software_development_4} alt="Windows"/>
                                                <p>
                                                    <b>Industry:</b> Education
                                                </p>
                                            </div>
                                            <div className="box-portfolio-details">
                                                <img src={software_development_5} alt="React"/>
                                                <p>
                                                    <b>Duration:</b> 2014-Present
                                                </p>
                                            </div>
                                        </div>
                                    </MKBox>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Container>
            </MKBox>
            <MKBox component="section" pb={5} style={{background: '#f0f2f5'}}>
                <Container>
                    <Grid container spacing={5} alignItems="center">
                        <Grid item xs={12} lg={12} sx={{ml: '', mt: {xs: 3, lg: 10}, mb: {xs: 3, lg: 5}}}>
                            <DefaultInfoCard
                                type="text" title="Lorem" description="Lorem ipsom"/>
                        </Grid>
                    </Grid>

                    <Grid container spacing={5} alignItems="center">
                        <Grid item xs={12} lg={3} md={6} sx={{ml: '', mb: {xs: 0, lg: 3}}}>
                            <div className="card-portfolio-details">
                                {/*<h2 className="card-portfolio-details-header">Team</h2>*/}
                                {/*<p className="card-detail">Project Manager</p>*/}
                                {/*<p className="card-detail">UI/UX Designer</p>*/}
                                {/*<p className="card-detail">Frontend Developer</p>*/}
                                {/*<p className="card-detail">Backend Developer</p>*/}
                                {/*<p className="card-detail">Quality Assurance Engineer</p>*/}
                            </div>
                        </Grid>
                        <Grid item xs={12} lg={3} md={6} sx={{ml: '', mb: {xs: 0, lg: 3}}}>
                            <div className="card-portfolio-details">
                                {/*<h2 className="card-portfolio-details-header">Tech Stack:</h2>*/}
                                {/*<p className="card-detail">React</p>*/}
                                {/*<p className="card-detail">Node.js</p>*/}
                                {/*<p className="card-detail">MongoDB</p>*/}
                            </div>
                        </Grid>
                        <Grid item xs={12} lg={3} md={6} sx={{ml: '', mb: {xs: 0, lg: 3}}}>
                            <div className="card-portfolio-details">
                                {/*<h2 className="card-portfolio-details-header">Target Audience</h2>*/}
                                {/*<p className="card-detail">Children aged 3 to 8 years old </p>*/}
                            </div>
                        </Grid>
                        <Grid item xs={12} lg={3} md={6} sx={{ml: '', mb: {xs: 0, lg: 3}}}>
                            <div className="card-portfolio-details">
                                {/*<h2 className="card-portfolio-details-header">Services</h2>*/}
                                {/*<p className="card-detail">Application Development</p>*/}
                                {/*<p className="card-detail">Quality Assurance</p>*/}
                                {/*<p className="card-detail">Parent Communication Features</p>*/}
                                {/*<p className="card-detail">User Experience (UX) Design</p>*/}
                            </div>
                        </Grid>
                    </Grid>
                </Container>
            </MKBox>
            <MKBox component="section" pb={5}>
                <Container>
                    <Grid container spacing={5} alignItems="center">
                        <Grid item xs={12} lg={5} sx={{ml: '', mt: {xs: 10, lg: 10}}}>
                            <img src={education_industry_4} className="image-portfolio-details"/>
                        </Grid>
                        <Grid item xs={12} lg={7}>
                            <Grid container justifyContent="flex-start">
                                <Grid item xs={12} md={12}>
                                    <MKBox>
                                        <DefaultInfoCard
                                            type="text"
                                            title="Opportunities"
                                            description=" The Institute sought to provide an enhanced learning experience for children through a customized educational application. They aimed to create a platform that would offer interactive lessons, educational games, and progress tracking features. The challenge was to develop an application that engaged young learners, supported their educational development, and facilitated easy communication with parents."
                                        />
                                    </MKBox>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Container>
            </MKBox>
            <MKBox component="section" pb={5} style={{background: '#f0f2f5'}}>
                <Container>
                    <Grid container spacing={5} alignItems="center">
                        <Grid item xs={12} lg={12} sx={{ml: '', mt: {xs: 3, lg: 10}}}>
                            <DefaultInfoCard
                                type="text"
                                title="Our Solution"
                                description="We partnered with the institution, to design and develop an innovative educational application. Our team collaborated closely with the client to understand their specific requirements and create a tailored solution. We utilized cutting-edge technologies, including React and Node.js, to build a robust and user-friendly application."
                            />
                        </Grid>
                    </Grid>
                    <Grid container spacing={5} alignItems="center">
                        <Grid item xs={12} lg={12} md={12} sx={{ml: '', mb: {xs: 0, lg: 3}}}>
                            <h1 className="portfolio-details-text-header">
                                The key outcomes of the project included
                            </h1>
                            <ul className="portfolio-details-text">
                                <li className="card-detail">
                  <span>
                    <b>Enhanced Engagement: </b>
                  </span>
                                    The interactive lessons, educational games, and activities captivated
                                    children&apos;s interest, resulting in higher engagement and motivation to learn.
                                </li>
                                <li className="card-detail">
                  <span>
                    <b>Improved Learning Outcomes: </b>
                  </span>
                                    The personalized and adaptive learning features helped children develop essential
                                    skills and knowledge in a fun and interactive manner.
                                </li>
                                <li className="card-detail">
                  <span>
                    <b>Seamless Parent-Teacher Communication:</b>
                  </span>
                                    The application&apos;s communication features fostered effective collaboration
                                    between parents and teachers, allowing for timely updates and continuous support.
                                </li>
                                <li className="card-detail">
                  <span>
                    <b>Data-Driven Insights: </b>
                  </span>
                                    The application provided valuable data and analytics on each child&apos;s
                                    progress, enabling teachers to personalize instruction and address individual
                                    learning needs.
                                </li>
                            </ul>
                        </Grid>
                    </Grid>

                    <Grid container spacing={5} alignItems="center">
                        <Grid item xs={12} lg={12} sx={{ml: '', mt: {xs: 3, lg: 3}}}>
                            <DefaultInfoCard
                                type="text"
                                title="Conclusion"
                                description="The collaboration resulted in the successful development of an engaging educational application. By leveraging our expertise in application development, UX design, and quality assurance, we delivered a solution that enriched the learning experience for young children. The application's interactive features, educational content, and seamless parent communication contributed to improved engagement, enhanced learning outcomes, and strengthened the relationship between parents and teachers."
                            />
                        </Grid>
                    </Grid>
                </Container>
            </MKBox>
        </>
    );
}

export default LearningCenterCaseStudyDetails;
