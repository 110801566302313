import React from "react";
import {
    Card,
    CardContent,
    CardHeader,
    Checkbox,
    FormControl,
    FormControlLabel,
    Grid,
    Radio,
    RadioGroup,
    TextField,
    Typography,
} from '@mui/material';

// import MKBox from '../../../components/box';

function CreateTemplateBand() {
    return (
        <>
            {/*<MKBox component="section" py={{ xs: 0, lg: 6 }}>*/}
            {/*    <Container>*/}
            <Grid container spacing={3}>
                <Grid item xs={12} md={12}>
                    {/*<MKBox mb={5}>*/}
                    <Card sx={{marginTop: '50px'}}>
                        <CardHeader
                            title="Create Work Document"
                            subheader="Documents"
                            titleTypographyProps={{style: {fontWeight: 'bold', fontSize: '18px'}}}
                            subheaderTypographyProps={{style: {fontSize: '14px'}}}>
                        </CardHeader>
                        <CardContent>
                            <form action="/" method="get">
                                <Grid container spacing={2}>
                                    <Grid item sm={2}>
                                        <Typography variant="caption">Custom Checkboxes &amp; radios</Typography>
                                    </Grid>
                                    <Grid item sm={4}>
                                        <FormControl>
                                            <FormControlLabel
                                                control={<Checkbox/>}
                                                label="Unchecked"
                                            />
                                            <FormControlLabel
                                                control={<Checkbox defaultChecked/>}
                                                label="Checked"
                                            />
                                            <FormControlLabel
                                                control={<Checkbox disabled/>}
                                                label="Disabled Unchecked"
                                            />
                                            <FormControlLabel
                                                control={<Checkbox defaultChecked disabled/>}
                                                label="Disabled Checked"
                                            />
                                        </FormControl>
                                    </Grid>
                                    <Grid item lg={3} sm={6}>
                                        <FormControl component="fieldset">
                                            <RadioGroup defaultValue="option1">
                                                <FormControlLabel
                                                    value="option1"
                                                    control={<Radio/>}
                                                    label="Radio is off"
                                                />
                                                <FormControlLabel
                                                    value="option2"
                                                    control={<Radio defaultChecked/>}
                                                    label="Radio is on"
                                                />
                                                <FormControlLabel
                                                    value="option3"
                                                    control={<Radio disabled/>}
                                                    label="Disabled radio is off"
                                                />
                                                <FormControlLabel
                                                    value="option4"
                                                    control={<Radio defaultChecked disabled/>}
                                                    label="Disabled radio is on"
                                                />
                                            </RadioGroup>
                                        </FormControl>
                                    </Grid>
                                </Grid>
                                <Grid container spacing={2}>
                                    <Grid item sm={2}>
                                        <Typography variant="caption">Input with success</Typography>
                                    </Grid>
                                    <Grid item sm={10}>
                                        <TextField
                                            fullWidth
                                            defaultValue="Success"
                                            variant="outlined"
                                            color="success"
                                        />
                                    </Grid>
                                </Grid>
                                <Grid container spacing={2}>
                                    <Grid item sm={2}>
                                        <Typography variant="caption">Input with error</Typography>
                                    </Grid>
                                    <Grid item sm={10}>
                                        <TextField
                                            fullWidth
                                            defaultValue="Error"
                                            variant="outlined"
                                            error
                                        />
                                    </Grid>
                                </Grid>
                                <Grid container spacing={2}>
                                    <Grid item sm={2}>
                                        <Typography variant="caption">Column sizing</Typography>
                                    </Grid>
                                    <Grid item sm={10}>
                                        <Grid container spacing={2}>
                                            <Grid item md={3}>
                                                <TextField
                                                    fullWidth
                                                    placeholder=".col-md-3"
                                                    variant="outlined"
                                                />
                                            </Grid>
                                            <Grid item md={4}>
                                                <TextField
                                                    fullWidth
                                                    placeholder=".col-md-4"
                                                    variant="outlined"
                                                />
                                            </Grid>
                                            <Grid item md={5}>
                                                <TextField
                                                    fullWidth
                                                    placeholder=".col-md-5"
                                                    variant="outlined"
                                                />
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </form>
                        </CardContent>
                    </Card>
                    {/*</MKBox>*/}
                </Grid>
            </Grid>
            {/*</Container>*/}
            {/*</MKBox>*/}
        </>
    );
}

export default CreateTemplateBand;
