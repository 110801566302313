/*
=========================================================
* Material Kit 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// prop-types is a library for typechecking of props
import PropTypes from 'prop-types';

// @mui material components
import Icon from '@mui/material/Icon';

// Material Kit 2 React components
import MKBox from 'components/box';
import MKTypography from 'components/typography';

function DefaultInfoCard({color, icon, title, description, direction, small, type}) {
    return (
        <MKBox lineHeight={1} p={direction === 'center' ? 2 : 0} textAlign={direction}>
            {typeof icon === 'string' ? (
                <MKTypography
                    display="block"
                    variant={direction === 'center' ? 'h2' : 'h3'}
                    color={color}
                    textGradient
                >
                    <Icon>{icon}</Icon>
                </MKTypography>
            ) : (
                icon
            )}
            <MKTypography
                display="block"
                variant="5"
                fontWeight="bold"
                fontSize={type === 'text' ? "25px" : ""}
                mt={direction === 'center' ? 1 : 2}
                mb={1.5}
            >
                {title}
            </MKTypography>
            <MKTypography
                display="block"
                variant={small ? 'button' : 'body2'}
                color="text"
                fontSize={type === 'text' ? "18px" : ""}
                pr={type === 'text' && direction === 'left' ? 6 : 0}
                pl={type === 'text' && direction === 'right' ? 6 : 0}
            >
                {description}
            </MKTypography>
        </MKBox>
    );
}

// Setting default props for the DefaultInfoCard
DefaultInfoCard.defaultProps = {
    color: 'info',
    direction: 'left',
    small: false,
};

// Typechecking props for the DefaultInfoCard
DefaultInfoCard.propTypes = {
    color: PropTypes.oneOf([
        'primary',
        'secondary',
        'info',
        'success',
        'warning',
        'error',
        'light',
        'dark',
    ]),
    icon: PropTypes.node,
    title: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    direction: PropTypes.oneOf(['left', 'right', 'center']),
    small: PropTypes.bool,
    type: PropTypes.string.isRequired,
};

export default DefaultInfoCard;
