/*
=========================================================
* Material Kit 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';

// Material Kit 2 React components
import MKBox from 'components/box';

// Material Kit 2 React examples
//Image
import managed_services_and_staff_augmentation_1
    from '../../../assets/images/managed-staff-and-augmentation/managed_services_and_staff_augmentation_1.jpeg';

// CSS
import '../../Training/sections/Training.css';
import DefaultInfoCard from '../../../examples/Cards/InfoCards/DefaultInfoCard';

function ManagedServicesAndStaffAugmentation() {
    return (
        <MKBox component="section" pt={2} pb={5}>
            <Container>
                <Grid container spacing={5} alignItems="center">
                    <Grid item xs={12} lg={5} sx={{ml: '', mt: {xs: 3, lg: 16}}}>
                        <img src={managed_services_and_staff_augmentation_1} className="training-img"/>
                    </Grid>
                    <Grid item xs={12} lg={7}>
                        <Grid container justifyContent="flex-start">
                            <Grid item xs={12} md={12}>
                                <MKBox>
                                    <DefaultInfoCard
                                        type="text"
                                        title="What is Managed Services and Staff Augmentation?"
                                        description="Managed Services and Staff Augmentation provide organizations with the flexibility,
              expertise, and cost advantages required to address specific business needs
              effectively. Whether through outsourcing specific functions or augmenting the
              workforce, organizations can leverage external resources to enhance capabilities,
              focus on core competencies, and achieve their business goals efficiently."
                                    />
                                </MKBox>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Container>
        </MKBox>
    );
}

export default ManagedServicesAndStaffAugmentation;
