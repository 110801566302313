/*
=========================================================
* Material Kit 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';

// Material Kit 2 React components
import MKBox from '../../../components/box';

//Image

// CSS
import './Certifications.css';
import certification_1 from '../../../assets/images/certification/certification_1.png';
import certification_2 from '../../../assets/images/certification/certification_2.png';
import certification_3 from '../../../assets/images/certification/certification_3.jpg';
import certification_4 from '../../../assets/images/certification/certification_4.jpg';
import certification_5 from '../../../assets/images/certification/certification_7.png';
import certification_6 from '../../../assets/images/certification/certification_6.png';
import certification_7 from '../../../assets/images/certification/certification_8.jpg';
import certification_8 from '../../../assets/images/certification/certification_5.png';
// import certification_12 from '../../../assets/images/certification/certification_12.png';
import MKTypography from '../../../components/typography';

function Certifications() {
  return (
    <MKBox component="section" pt={5} style={{ background: '#f0f2f5' }}>
      <Container>
        <Grid
          container
          item
          xs={12}
          lg={8}
          justifyContent="center"
          alignItems="center"
          flexDirection="column"
          sx={{ mx: 'auto', textAlign: 'center', pt: '100px', pb: '50px' }}
        >
          <MKTypography
            variant="h1"
            //color="#344667"
            color="dark"
            sx={({ breakpoints, typography: { size } }) => ({
              [breakpoints.down('md')]: {
                fontSize: size['3xl'],
              },
            })}
          >
            Certification
          </MKTypography>
        </Grid>
        <Grid container spacing={5} alignItems="center">
          <Grid item xs={12} lg={12}>
            <Grid container justifyContent="flex-start">
              <Grid item xs={12} md={12}>
                <MKBox>
                  <div className="certification-container">
                    <a
                      className="certification-box"
                      href="https://iapp.org/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <div>
                        <img className="certification-box-image" src={certification_1} alt="CIPP" />
                      </div>
                    </a>
                    <a
                      className="certification-box"
                      href="https://www.pmi.org/certifications/project-management-pmp"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <div>
                        <img className="certification-box-image" src={certification_2} alt="PMP" />
                      </div>
                    </a>
                    <a
                      className="certification-box"
                      href="https://www.bridging-the-gap.com/acba-guidelines/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <div>
                        <img className="certification-box-image" src={certification_3} alt="ACBA" />
                      </div>
                    </a>
                    <a
                      className="certification-box"
                      href="https://www.axelos.com/certifications/itil-service-management"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <div>
                        <img className="certification-box-img" src={certification_4} alt="ITIL" />
                      </div>
                    </a>
                    <a
                      className="certification-box"
                      href="https://www.knowledgehut.com/agile-management/cspo-certification-training"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <div>
                        <img className="certification-box-image" src={certification_5} alt="CSPO" />
                      </div>
                    </a>
                    <a
                      className="certification-box"
                      href="https://www.scrumalliance.org/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <div>
                        <img className="certification-box-image" src={certification_6} alt="CSM" />
                      </div>
                    </a>
                    <a
                      className="certification-box"
                      href="https://www.iiba.org/business-analysis-certifications/cbap/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <div>
                        <img className="certification-box-img" src={certification_7} alt="CBAP" />
                      </div>
                    </a>
                    <a
                      className="certification-box"
                      href="https://www.isaca.org/credentialing/cisa"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <div>
                        <img className="certification-box-img" src={certification_8} alt="CISA" />
                      </div>
                    </a>
                  </div>
                </MKBox>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default Certifications;
