/*
=========================================================
* Material Kit 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';

// Material Kit 2 React components
import MKBox from 'components/box';

// Material Kit 2 React examples
import CenteredImageCard from 'examples/Cards/BlogCards/CenteredImageCard';

//Image
import logo from '../../../../assets/images/logos/IDMAP LOGO.png';
import websiteNameByBrand from '../../../../utils/websiteNameByBrand';
import * as Proptypes from 'prop-types';
// eslint-disable-next-line react/prop-types

export default function IDMAPSBand({ disType }) {

  const brand = websiteNameByBrand();

  const dataPage = (
    <MKBox component="section" py={12}>
      <Container>
        <Grid container spacing={3} alignItems="center">
          <Grid item xs={12} lg={6} sx={{ mr: 'auto', mt: { xs: 3, lg: 0 } }}>
            <CenteredImageCard
              image={logo}
              title={brand.title}
              description="We are eager to serve you better."
            />
          </Grid>
          <Grid item xs={12} lg={5}>
            <Grid container justifyContent="flex-start">
              <Grid item xs={12} md={12}>
                <MKBox mb={5}>
                  <CenteredImageCard
                    image={logo}
                    icon="public"
                    title="Software"
                    description="We are eager to serve you better."
                  />
                </MKBox>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );

  const servicePageInfo = (
    <MKBox component="section" py={6}>
      <Container>
        <Grid container spacing={3} alignItems="center">
          <Grid item>
            <CenteredImageCard
              image={brand.logo}
              title="We offer exceptional service"
              description="Our expertise spans data analytics, Enterprise Data Management, managed services, staff augmentation, software development, training, and more."
              // As a prominent provider of outstanding services designed to cater to various business needs. Our expertise spans data analytics, EDM & analytics, managed services, training, and more. This listing will explore the exceptional services provided by our company, highlighting their capabilities in empowering businesses for success. Let's dive into the offerings that sets us apart in the industry."
            />
          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );

  return <div>{disType !== '' && disType === 'band' ? servicePageInfo : dataPage}</div>;
}

IDMAPSBand.propTypes = {
  disType : Proptypes.string.isRequired
}
