import React from 'react';
import TemplatePage from "../../PMTemplate/sections/TemplatePage";
import PropTypes from "prop-types";

export default function UserTemp({fetchData, profile}) {

    return (
        <TemplatePage
            title="Your OneDrive Files"
            fetchData={fetchData}
            profile={profile}
            buttonLabel=""
            uploadButton="false"
        />
    );
};

UserTemp.propTypes = {
    fetchData: PropTypes.array.isRequired,
    profile: PropTypes.object,
};

UserTemp.defaultProps = {
    profile: null,
    oneDriveFiles: []
};