/*
=========================================================
* Material Kit 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';

// Material Kit 2 React components
import MKBox from '../../../components/box';

//CSS
import '../../Training/sections/Training.css';
import websiteNameByBrand from 'utils/websiteNameByBrand';

function WhyUs() {
    const brand = websiteNameByBrand();

    return (
        <MKBox component="section" style={{background: '#f0f2f5'}}>
            <Container>
                <Grid lg={12} md={12} container spacing={2} pt={12}>
                <Grid
                    item
                    xs={12}
                    md={12}
                    lg={brand.title === "IDMAPS" ? 12 : 6}
                    alignItems="center">
                        <Grid justifyContent="flex-start" >
                                <MKBox>
                                    <div className="training-benefit">
                                        <span>Why Choose Us for Data Analytics?</span>
                                    </div>
                                </MKBox>
                            <Grid lg={brand.title === "IDMAPS" ? 12 : ""} container>
                            <Grid lg={brand.title === "IDMAPS" ? 6 : ""}>
                                <MKBox>
                                    <div className="training-benefit">
                                    <span className="training-benefit-list">
                                      <i className="fas fa-lightbulb icon"></i> Gain Deeper Insights
                                    </span>
                                    </div>
                                    <div className="training-description">
                                        <p>
                                            With our data analytics services, you gain a deeper understanding of your
                                            business operations, customer behavior, market trends, and competitive
                                            landscape. We uncover hidden patterns and correlations within your data,
                                            enabling you to make data-driven decisions.
                                        </p>
                                    </div>
                                </MKBox>
                                <MKBox>
                                    <div className="training-benefit">
                                    <span className="training-benefit-list">
                                      <i className="fas fa-puzzle-piece icon"></i> Tailored Solutions for Your Needs
                                    </span>
                                    </div>
                                    <div className="training-description">
                                        <p>
                                            Whether you require descriptive analytics to understand past trends,
                                            diagnostic analytics to uncover reasons behind outcomes, predictive
                                            analytics to forecast future events, or prescriptive analytics to
                                            optimize decision-making, our team delivers tailored solutions to
                                            meet your specific needs.
                                        </p>
                                    </div>
                                </MKBox>
                            </Grid>
                            <Grid lg={brand.title === "IDMAPS" ? 6 : ""}>
                                <MKBox>
                                    <div className="training-benefit">
                                    <span className="training-benefit-list">
                                      <i className="fas fa-bullseye icon"></i>Quality Data
                                    </span>
                                    </div>
                                    <div className="training-description">
                                        <p>
                                            Our data analytics process begins with meticulous data collection and
                                            cleansing to ensure accuracy and reliability. Through interactive dashboards
                                            and reports, we provide clear and concise representations of your data,
                                            making
                                            it easier to identify trends, spot opportunities, and address challenges.
                                        </p>
                                    </div>
                                </MKBox>
                                <MKBox>
                                    <div className="training-benefit">
                                    <span className="training-benefit-list">
                                      <i className="fas fa-unlock-alt icon"></i> Unlock Your Business Potential
                                    </span>
                                    </div>
                                    <div className="training-description">
                                        <p>
                                            Partnering with us for data analytics unlocks improved operational
                                            efficiency,
                                            enhanced customer experiences, better risk management, and increased
                                            profitability. Our focus is on delivering actionable insights that drive
                                            measurable results for your business.
                                        </p>
                                    </div>
                                </MKBox>
                            </Grid>
                            </Grid>

                        </Grid>
                </Grid>
                    { brand.title === "SHPRIVACY" ?

                    <Grid item xs={12} md={12} lg={6}  >
                        <Grid justifyContent="flex-start" >
                                <MKBox>
                                    <div className="training-benefit">
                                        <span>Why Choose Us for Data Analysis?</span>
                                    </div>
                                </MKBox>
                                <MKBox>
                                    <div className="training-benefit">
                                    <span className="training-benefit-list">
                                     <i className="fas fa-chart-line icon"></i> Informed Decision-Making
                                    </span>
                                    </div>
                                    <div className="training-description">
                                        <p>
                                            Data analysis provides detailed insights from historical and real-time data,
                                            enabling you to make decisions based on evidence rather than intuition.
                                            This can lead to better strategic planning, improved operational efficiency,
                                            and the ability to quickly respond to market changes.
                                        </p>
                                    </div>
                                </MKBox>
                                <MKBox>
                                    <div className="training-benefit">
                                    <span className="training-benefit-list">
                                      <i className="fas fa-piggy-bank icon"></i> Cost Savings
                                    </span>
                                    </div>
                                    <div className="training-description">
                                        <p>
                                            Through data analysis, you can identify cost-saving opportunities by
                                            optimizing procurement, reducing waste, and improving operational
                                            efficiency. Additionally, predictive maintenance analytics can help
                                            you avoid costly equipment failures by scheduling timely maintenance
                                            based on usage patterns.
                                        </p>
                                    </div>
                                </MKBox>
                                <MKBox>
                                    <div className="training-benefit">
                                    <span className="training-benefit-list">
                                      <i className="fas fa-bullhorn icon"></i> Improved Marketing Effectiveness
                                    </span>
                                    </div>
                                    <div className="training-description">
                                        <p>
                                            Data analysis helps you measure the effectiveness of marketing campaigns by
                                            tracking key metrics such as conversion rates, customer acquisition costs,
                                            and return on investment. By understanding which campaigns perform
                                            best, you can better allocate marketing budgets and target the
                                            right audience.
                                        </p>
                                    </div>
                                </MKBox>
                                <MKBox>
                                    <div className="training-benefit">
                                    <span className="training-benefit-list">
                                     <i className="fas fa-shield-alt icon"></i>Risk Management
                                    </span>
                                    </div>
                                    <div className="training-description">
                                        <p>
                                            By analyzing data, you can identify potential risks and vulnerabilities
                                            in your operations, supply chain, or financial practices. Predictive
                                            analytics can forecast potential issues, allowing you to implement
                                            proactive measures to mitigate risks, such as diversifying suppliers,
                                            strengthening security protocols, or adjusting financial strategies.
                                        </p>
                                    </div>
                                </MKBox>
                            </Grid>
                </Grid>
                        : ""
                    }
                </Grid>
            </Container>
        </MKBox>
    );
}

export default WhyUs;
